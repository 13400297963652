import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from '@partssourceinc/react-ui-core';
import _ from 'lodash';
import styled from 'styled-components';

import { getCookie, logEvent } from 'utility';
import CalendarCarousel from './../dashboard/CalendarCarousel';

import DashboardPanel from './../dashboard/DashboardPanel';
import DashboardCards from './../../data/DashboardCards';

const TitleContainer = styled.div`
    max-width: 1630px;
    margin: 0 auto;
`;

const Title = styled.div`
    color: #000;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
`;

const switchToggleItems = [
    { text: 'All', value: false, selection: 'Left' },
    { text: '5 DAY', value: true, selection: 'Right' },
];

const OperationalActivity = (props) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [orders, setOrders] = useState([]);
    const settings = useSelector(state => state.user.settings);
    const activeSection = useSelector(state => state.dashboard.activeSection);
    const [selectedStatsKey, setSelectedStatsKey] = useState('');
    const selectedCategory = useSelector(state => state.dashboard.selectedCategory);
    const selectedCategories = useSelector(state => state.user.dashboardViewSettings.selectedCategories);
    const selectedModalities = useSelector(state => state.user.dashboardViewSettings.selectedModalities);
    const selectedFacilities = useSelector(state => state.user.dashboardViewSettings.selectedFacilities);
    const selectedRequesters = useSelector(state => state.user.dashboardViewSettings.selectedRequesters);
    const stats = useSelector(state => state.dashboard.stats);
    const removedLineItems = useSelector(state => state.dashboard.removedLineItems);
    const dashboardStatsContainer = `px-4 col-md-12 dashboard-margin-container d-flex flex-wrap justify-content-center float-none ${!showCalendar ? 'stats-default' : ''}`;

    const onActiveFlagChange = (e, result) => {
        const show = result.value.value;
        setShowCalendar(show);
        logEvent('DASHBOARD', {
            'Operational Activity': 'Toggle Switch',
            'View': result.value.text,
        });
    };

    const mapOrders = () => {
        const results = _.mapValues(stats, (v, k) => {
            if (_.isArray(v)) {
                return v.filter(o =>
                    (selectedCategories.length === 0 || selectedCategories['-1'] || selectedCategories[o.modality_name] || (selectedCategories['-2'] && !o.modality_name))
                    && (selectedRequesters.length === 0 || selectedRequesters['-1'] || selectedRequesters[o.requestor_id])
                    && (selectedFacilities.length === 0 || selectedFacilities['-1'] || selectedFacilities[o.company_id])
                    && (selectedModalities.length === 0 || selectedModalities['-1'] || (selectedModalities['-2'] && !o.category_path) || Object.keys(selectedModalities).some(key => o.category_path && o.category_path.includes(key)))
                    && (!removedLineItems.includes(k.line_item_id)));
            } else if (_.isNumber(v)) {
                return !stats[`${k}_data`]
                    ? v
                    : stats[`${k}_data`].filter(o =>
                        (selectedCategories.length === 0 || selectedCategories['-1'] || selectedCategories[o.modality_name] || (selectedCategories['-2'] && !o.modality_name))
                        && (selectedRequesters.length === 0 || selectedRequesters['-1'] || selectedRequesters[o.requestor_id])
                        && (selectedFacilities.length === 0 || selectedFacilities['-1'] || selectedFacilities[o.company_id])
                        && (selectedModalities.length === 0 || selectedModalities['-1'] || (selectedModalities['-2'] && !o.category_path) || Object.keys(selectedModalities).some(key => o.category_path && o.category_path.includes(key)))
                        && (!removedLineItems.includes(o.line_item_id))
                    ).length;
            } else {
                return v;
            }
        });

        return results;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const carousel = document.querySelector('.calendar-carousel');
            if (carousel) {
                carousel.classList.toggle('calendar-carousel-view');
                clearInterval(interval)
            }
        }, 100);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setOrders(mapOrders());
    }, [stats]);

    useEffect(() => {
        const results = mapOrders();
        setOrders(results);
        if (selectedCategory && selectedStatsKey) {
            const { onClickItem } = props;
            onClickItem({
                items: results[selectedStatsKey],
                selectedCategory: activeSection === 'operationalActivity' ? selectedCategory : '',
                isCardView: false
            });
        }
    }, [selectedModalities, selectedCategories, selectedRequesters, selectedFacilities]);

    const setLineItems = async (selLineItems) => {
        const { onClickItem } = props;
        onClickItem({
            items: selLineItems,
            selectedCategory: '',
            isCardView: true
        });
    }

    const showOrders = ({ lineItemsIds, selectedLabel, index, gridKeys, gridName, gridColumnsMapping, cardAction, title, categories, statsKey }) => {
        const { onClickItem } = props;

        logEvent('DASHBOARD', {
            id_ins: getCookie('id_ins'),
            'Type': 'Category Selected',
            'Category': title,
            'SubCategory': index > -1 ? categories[index].label : '',
        });

        onClickItem({
            items: lineItemsIds,
            selectedCategory: selectedLabel,
            isCardView: false,
            gridName,
            gridColumnsMapping,
        });
        setSelectedStatsKey(index > -1 ? categories[index].statsKey : statsKey);
    }

    return (
        <>
            <div className="col-md-12 multi-grid-page" style={{ paddingTop: '20px', display: 'contents' }}>
                <TitleContainer className="col-12 d-flex flex-wrap">
                    <Title>Operational Activity</Title>
                    <Switch id="active" items={switchToggleItems} style={{ marginLeft: '20px' }} selected={showCalendar ? 'Right' : 'Left'} onChange={onActiveFlagChange} />
                </TitleContainer>
                <div style={{ display: !showCalendar ? 'none' : '' }}><CalendarCarousel setLineItems={async (selLineItems, selCategory) => await setLineItems(selLineItems, selCategory)} /> </div>
                {!showCalendar &&
                    <div className={dashboardStatsContainer}>
                        {DashboardCards.map((card, i) => {
                            const {
                                title,
                                categories,
                                statsKey,
                                gridKeys = [],
                                gridName = [],
                                gridColumnsMapping = [],
                                cardAction,
                            } = card;

                            const allData = orders[statsKey] || [];
                            const categoriesWithData = categories.map(c => ({ ...c, orders: orders[c.statsKey] }));

                            return (!card.hideWithoutSetting || settings[card.hideWithoutSetting]) && (
                                <DashboardPanel
                                    key={i}
                                    title={title}
                                    allData={allData}
                                    categories={categoriesWithData}
                                    onClick={(lineItemsIds, selectedLabel = '', index = -1) => showOrders({ lineItemsIds, selectedLabel, index, gridKeys, gridName, gridColumnsMapping, cardAction, title, categories, statsKey })}
                                    selectedLabel={activeSection === 'operationalActivity' ? selectedCategory : ''}
                                />
                            );
                        })}
                    </div>
                }
            </div>
        </>
    );
};

OperationalActivity.propTypes = {
}

export default OperationalActivity;
