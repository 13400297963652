import React from 'react';
import styled from 'styled-components';
import {Popup} from '@partssourceinc/react-ui-core';

const Container = styled.div`
    @media(max-width: 549px){
        height: calc(100vh - 200px);
        overflow: auto;
        padding-top: 24px;
    }
`;

const Title = styled.div`
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 300;
`;

const Paragraph1 = styled.div`
    font-size: 16px;
    line-height: 24px;    
`;

const Paragraph2 = styled.div`
    font-size: 16px;
    line-height: 24px;    
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Paragraph3 = styled.div`
    font-size: 16px;
    line-height: 24px;    
    margin-top: 20px;
    margin-bottom: 24px;
`;

const PrecisionMetricWrapper = styled.div`
    display: inline-block;    
    position: relative;
    vertical-align: middle;
    margin-right: 24px;
    vertical-align: top;

    img {
        height: 138px;
    }

    .score-desc {
        position: absolute;
        top: 58px;
        right: 36px;
        text-align: center;

        .score {
            font-size: 40px;
            font-weight: 600;
        }

        .desc {
            font-size: 16px;
            margin-top: 14px;
            font-weight: 600;
        }
    }
`;

const ScoreWrapper = styled.div`
    display: inline-block;
    vertical-align: top;
`;

const Score = styled.div`
    font-weight: 600;
    padding-bottom: 10px;
`;

const ScoreImage = styled.img`
  width: 15px;
  position: relative;
  top: -1px;
  margin-left: 5px;
`;

const ScoreDescription = styled.span`
  padding-left: 6px;
  padding-right: 2px;
  font-size: 14px;
  font-weight: 400 !important;
`;

export function FormularyMetricDefinitionModal({onClose}) {  
    
    return (
        <Popup 
            className="formulary-modal"
            confirmText="Done"         
            show={true} 
            hideButtons={false}
            hideCancel={true} 
            onConfirm={onClose}
            onCancel={onClose}>
            <Container>
                <Title>Precision Procurement Formulary Score</Title>
                <Paragraph1>PartsSource assesses each Formulary Rule across key metrics derived from our Precision Procurement engine. 
                This proprietary analysis provides an objective view to consider when making purchase decision tradeoffs.</Paragraph1>
                <Paragraph2>Precision Procurement Formulary Score based on Secondary Option vs OEM Original</Paragraph2>
                <Paragraph3>The Precision Procurement Formulary Score provides a relative comparison displayed in 1 of  5 categories to inform your Formulary Rule decision:</Paragraph3>                
                <PrecisionMetricWrapper>
                    <img src={`/images/formulary/score-graphic_moderate.png`} />
                    <div className="score-desc">
                        <div className="score">X.XX</div>
                        <div className="desc">RATING</div>
                    </div>                                
                </PrecisionMetricWrapper>
                <ScoreWrapper>
                    <Score><ScoreImage src="/images/formulary/icn_best.png" /><ScoreDescription>BEST:</ScoreDescription><span>60 and above</span></Score>            
                    <Score><ScoreImage src="/images/formulary/icn_very-good.png" /><ScoreDescription>VERY GOOD:</ScoreDescription><span>38 to 59.9</span></Score>            
                    <Score><ScoreImage src="/images/formulary/icn_moderate.png" /><ScoreDescription>MODERATE:</ScoreDescription><span>30.2 to 37.9</span></Score>            
                    <Score><ScoreImage src="/images/formulary/icn_fair.png" /><ScoreDescription>FAIR:</ScoreDescription><span>30.1 to 10.1</span></Score>            
                    <Score><ScoreImage src="/images/formulary/Poor.png" /><ScoreDescription>POOR:</ScoreDescription><span>10.0 or below</span></Score>                          
                </ScoreWrapper>
            </Container>
        </Popup>
    );
}
