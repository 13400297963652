import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons/faArrowToBottom';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import moment from 'moment';
import { getCookie, logEvent, classNames } from 'utility';
import styled, { css } from 'styled-components';

import Filters from 'components/CommandCenter/Filters';
import TodayEventSummary from 'components/CommandCenter/TodayEventSummary';
import OperationalActivity from 'components/CommandCenter/OperationalActivity';
import KeyActions from 'components/CommandCenter/KeyActions';
import UpdatesPanel from 'components/CommandCenter/UpdatesPanel';
import PageMetaData from 'components/PageMetaData';
import { Lg, Sm, Md, Xs, PhoneLandscape } from 'responsive';
import { GridPaginationMobile } from 'components/GridPaginationMobile';

import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as DashboardStore from 'stores/Dashboard';
import * as SettingsStore from 'stores/Settings';
import * as UserStore from 'stores/User';
import { debounce } from 'utility';
import { CustomServerGrid, GridPagination, Popup, Checkbox, Loader } from '@partssourceinc/react-ui-core';
import { CustomServerCards } from 'components/CustomServerCards';
import './command-center.less';
import '../../less/grid/grid.less';
import { Drawer } from '@partssourceinc/react-ui-core/components';
import { downloadCreditMemos, formatMoney, getFSRDocument, getPODLetter, getTrackingNumbersUrlsData } from '../../utility';

const Close = styled.div`
    display: flex;
    justify-content: flex-end;

    button {
        font-size: 32px;
        opacity: 1;
        color: #949494;
        padding: 0;
        border: 0;
        font-weight: 700;
        line-height: 1;
        text-shadow: 0 1px 0 #fff;
        background-color: transparent;
        cursor: pointer;
        outline: none;
    
        &:hover {
            color: #777777;
            outline: none;
        }
    
        &:focus {
            color: #777777;
            outline: none;
        }
    }
`;

const DashboardContent = styled.div`
    min-height: 25rem;
    max-width: 1630px;
    margin: 0 auto;

    @media (max-width: 819px) {
        padding-left: 0;
        padding-right: 0;
    }
`;

const LeftColumn = styled.div`
    display: inline-block;
    width: calc(100% - 440px);
    padding-right: 20px;
    padding-top: 20px;

    @media screen and (max-width: 820px) {
        padding-right: 0px;
    }

    @media screen and (max-width: 1149px) {
        width: 100%
    }
`;

const RightColumn = styled.div`
    display: inline-block;
    width: 440px;
    vertical-align: top;
    background-color: #f1f1f1;
    overflow: hidden;
    position: relative;
    margin-top: 3px
    padding-left: 20px;
    padding-right: 20px;
`;

const NoResults = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 20px 10px;
`;

const StyledPopup = styled(Popup)`
    align-items: unset;
    button {
        float: right;
    }

    .modal-header {
        border-bottom: none;
        padding: 10px 10px 0px 0px;
        margin: 0px;
    }

    :last-child {
        height: initial !important;
    }
`;

const MobileGridPaginationContainer = styled.div`
    > div {
        margin-top: 0px !important;
        margin-bottom: 20px;
    }
`;

const LoaderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 450px;
`;

const SelectionToolbarDiv = styled.div`
    display: flex;
    align-content: end;
    justify-content: end;
    flex-direction: row;
    flex-wrap: wrap;
`;

const ViewToolbar = styled.div`
`;

const GridSearchWrapper = styled.div`
    width: 300px;
    float: right;
    margin-bottom: 20px;
`;

const GridHiddenSearchBox = styled.input`
    position: relative;
    top: 30px;
    border: none !important;
    box-shadow: none !important;
    font-size: 14px !important;
    height: 40px !important;

    ${props => props.$isVisible && css`
        border: 1px solid #ced4da !important;
        background-color: #f1f1f1 !important;
        outline: 0;

        &:focus {
            border: 1px solid #ced4da !important;
            box-shadow: none !important;
        }
    `}
`;

const SearchIcon = styled(FontAwesomeIcon)`
    position:relative;
    width: 20px;
    height: 20px;
    float: right;
    right: 10px;
    pointer-events: none;
    top: 1px;
    font-size: 18px;
`;

const packetTimingProperties = ['start_time', 'end_time', 'data_load_time'];
const viewType = 'CurrentUser';
const ViewType = {
    Grid: 'Grid',
    Card: 'Card',
};

@withRouter
@connect(
    (state) => ({
        settings: state.settings,
        user: state.user,
        dashboard: state.dashboard,
    }),
    _.merge(
        SettingsStore.actionCreators,
        DashboardStore.actionCreators,
    )
)
export default class CommandCenter extends React.Component {
    static propTypes = {
        ...SettingsStore.ActionShape,
        ...DashboardStore.ActionShape,
        settings: SettingsStore.StateShape,
        user: UserStore.StateShape,
        dashboard: DashboardStore.StateShape,
    };

    constructor(props) {
        super(props);
        this.dashboardContentRef = React.createRef();

        const {
            dashboard: { gridSettings },
        } = props;

        this.state = {
            requesters: [],
            showCalendar: true,
            loading: false,
            showScheduler: false,
            columns: [],
            view: ViewType.Grid,
            originalIds: [],
            items: [],
            itemsInPage: [],
            totalItems: 0,
            gridSettings,
            lastPageQueried: 1,
            showSearchBox: true,
            searchTerm: gridSettings.searchTerm,
            subLabel: 'Command Center',
            gridName: 'opsDashboardDefaultColumns',
            currentGridColumns: [],
            showColumnSelection: false,
            columnSelectionColumns: [],
            tooltipLineItemId: -1,
            tooltipLocation: {},
            showAllFsrDocuments: {},
        };

        axios
            .get('/ShoppingService/api/v1/company/contacts/')
            .then(x => this.setState({ requesters: x.data.map(r => ({ value: r.contactId, label: r.fullName })) }));
    }

    componentDidUpdate(prevProps, prevState) {
        const { dashboard: { selectedLineItems } } = this.props;
        const { subLabel, searchTerm } = this.state;
        if (!_.isEqual(prevProps.dashboard.selectedLineItems, selectedLineItems)) {
            this.setState({ items: selectedLineItems.map(i => i.lineItemId), itemsInPage: selectedLineItems });
            sessionStorage.prevGrid = subLabel;

            if (selectedLineItems.length > 0 && prevState.searchTerm === searchTerm) {
                setTimeout(() => this.dashboardContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 50);
            }
        }
    }

    onSelect = (data) => {
        const { history } = this.props;
        if (['quoted', 'equoted', 'requested', 'initiated'].includes((data.status || '').toLowerCase())) {
            sessionStorage.prevGrid = location.pathname.toLowerCase() === '/command-center' ? 'Command Center' : 'Dashboard';
            if (data.isServiceItem)
                history.push(`/service/${data.lineItemId}`);
            else
                history.push(`/quotes/${data.lineItemId}`);
        } else {
            history.push(`/order/${data.lineItemId}`, data);
        }
    }

    toggleScheduler = () => {
        const { showScheduler } = this.state;

        logEvent('DASHBOARD', {
            id_ins: getCookie('id_ins'),
            'Type': 'Schedule open/close',
            'Event': showScheduler ? 'close' : 'open',
        });

        document.querySelector('.calendar-carousel').classList.toggle('calendar-carousel-view');
        this.setState({ showScheduler: !showScheduler });
    }

    onActiveFlagChange(e, result) {
        let { showCalendar } = this.state;
        showCalendar = result.selection === 'Left' ? true : false;
        this.setState({ showCalendar });
    }

    async onSelectKeyActionsItem(item) {
        const { setActiveSection, setGridSettings } = this.props;
        const { gridSettings } = this.state;

        setActiveSection('keyAction');

        const ids = [];
        for (let i = 0; i < item.length; i++) {
            ids.push(item[i].line_item_id);
        }

        this.state.originalIds = ids;

        const settings = {
            ...gridSettings,
            searchTerm: '',
            page: 1,
            sort: 'lineItemId',
            sortDir: 'desc',
        };

        let gridName = 'opsDashboardDefaultColumns'
        let currentGridColumns = await this.getGridColumns(gridName, []);
        this.setState({
            gridSettings: settings,
            searchTerm: '',
            gridName,
            currentGridColumns,
        });
        setGridSettings(settings);
        this.getLines(ids, 1, gridSettings.pageSize, settings.sort, settings.sortDir, '');
    }

    async getGridColumns(gridName, gridColumnsMapping) {
        const { user } = this.props;
        try {
            const response = await axios.get(`/ShoppingService/api/v1/account/gridSetting/${gridName}`);
            const serverColumns = response.data.columns;

            const serverColumnsMap = {};
            serverColumns.forEach(column => {
                serverColumnsMap[column.field] = column;
            });

            const columnsMappingMap = {};
            (gridColumnsMapping || []).forEach(mapping => {
                columnsMappingMap[mapping.fromColumn] = mapping.toColumn;
            });

            const mergedColumns = user[gridName].map(storeColumn => {
                let mergedColumn = { ...storeColumn };

                // Check if the column exists in the server columns
                if (serverColumnsMap[storeColumn.field]) {
                    mergedColumn = {
                        ...mergedColumn,
                        ...serverColumnsMap[storeColumn.field]
                    };
                }

                // Check if the column exists in the columnsMappingMap
                if (columnsMappingMap[storeColumn.field]) {
                    mergedColumn.tempField = columnsMappingMap[storeColumn.field];
                }

                return mergedColumn;
            });
            const sortedMergedColumns = mergedColumns.sort((a, b) => (a.order || 0) - (b.order || 0));
            const currentGridColumns = sortedMergedColumns.map(column => ({
                ...column,
                displayName: column.header,
                fieldName: column.tempField ?? column.field,
                canSort: column.sortable
            }));

            this.setState({
                view: response.data.view ?? ViewType.Grid,
                currentGridColumns
            });

            return currentGridColumns;
        } catch (error) {
            console.error("Error fetching grid columns:", error);
        }
    }

    updateGridSettingsAndFetchLines(ids, gridName, currentGridColumns, selectedCategory) {
        const { setGridSettings } = this.props;
        const { gridSettings } = this.state;

        const settings = {
            ...gridSettings,
            searchTerm: '',
            page: 1,
            sort: 'lineItemId',
            sortDir: 'desc',
        };

        const newState = {
            gridSettings: settings,
            searchTerm: '',
            gridName,
            currentGridColumns,
        };

        if (selectedCategory) {
            newState.selectedCategory = selectedCategory;
        }

        this.setState(newState);
        setGridSettings(settings);
        this.getLines(ids, 1, gridSettings.pageSize, settings.sort, settings.sortDir, '');
    }

    async onSelectOperationalActivityItem({ items, selectedCategory, isCardView, gridName = 'opsDashboardDefaultColumns', gridColumnsMapping }) {
        const { setActiveSection, setSelectedCategory } = this.props;

        setActiveSection('operationalActivity');
        setSelectedCategory(selectedCategory);

        const ids = isCardView ? items.map(i => i.id) : items.map(i => i.line_item_id);
        this.state.originalIds = ids;

        const currentGridColumns = await this.getGridColumns(gridName, gridColumnsMapping);
        this.updateGridSettingsAndFetchLines(ids, gridName, currentGridColumns, selectedCategory);
    }

    async onSelectActiveItemOnTodayEvents({ items, isCardView, gridName = 'opsDashboardDefaultColumns', gridColumnsMapping }) {
        const { setActiveSection } = this.props;

        setActiveSection('todayEvents');

        const ids = isCardView ? items.map(i => i.id) : items.map(i => i.line_item_id);
        this.state.originalIds = ids;

        const currentGridColumns = await this.getGridColumns(gridName, gridColumnsMapping);
        this.updateGridSettingsAndFetchLines(ids, gridName, currentGridColumns);
    }

    async processDefaultGridSelection(items, section) {
        const { setActiveSection } = this.props;

        section && setActiveSection(section);

        const ids = items.map(i => i.line_item_id);
        this.state.originalIds = ids;

        const gridName = 'opsDashboardDefaultColumns';
        const currentGridColumns = await this.getGridColumns(gridName, []);
        this.updateGridSettingsAndFetchLines(ids, gridName, currentGridColumns);
    }

    async onSelectKeyActionsItem(item) {
        await this.processDefaultGridSelection(item, 'keyAction');
    }

    async onUpdatePanelItemSelected(line_item_id) {
        const {setShowUpdatesPanel} = this.props;
        setShowUpdatesPanel(false)
        await this.processDefaultGridSelection([{ line_item_id }], null);
    }

    onSort = (columnName, direction) => {
        const { setGridSettings } = this.props;
        const { originalIds, gridSettings } = this.state;
        const settings = {
            ...gridSettings,
            sort: columnName,
            sortDir: direction,
        };
        this.setState({
            gridSettings: settings,
        });
        setGridSettings(settings);
        this.getLines(originalIds, gridSettings.page, gridSettings.pageSize, columnName, direction, gridSettings.searchTerm);
    };

    searchTermChanged = debounce((st) => {
        const { setGridSettings } = this.props;
        const { originalIds, gridSettings } = this.state;
        const settings = {
            ...gridSettings,
            searchTerm: st,
        };
        this.setState({
            gridSettings: settings,
            searchTerm: st,
        });
        setGridSettings(settings);
        this.getLines(originalIds, gridSettings.page, gridSettings.pageSize, gridSettings.sort, gridSettings.sortDir, st);
    }, 1000);

    onSearch = (st) => {
        this.setState({
            searchTerm: st,
        });
        this.searchTermChanged(st);
    };

    onPageSelected = ({ selectedPage }) => {
        const { setGridSettings } = this.props;
        const { originalIds, gridSettings } = this.state;
        const settings = {
            ...gridSettings,
            page: selectedPage,
        };
        this.setState({
            gridSettings: settings,
            lastPageQueried: settings.page,
        });
        setGridSettings(settings);
        this.getLines(originalIds, selectedPage, gridSettings.pageSize, gridSettings.sort, gridSettings.sortDir, gridSettings.searchTerm);
    };

    onCardPageSelected = (selectedPage) => {
        const { setGridSettings } = this.props;
        const { originalIds, gridSettings } = this.state;
        const settings = {
            ...gridSettings,
            page: selectedPage,
        };
        this.setState({
            gridSettings: settings,
            lastPageQueried: settings.page,
        });
        setGridSettings(settings);
        this.getLines(originalIds, selectedPage, gridSettings.pageSize, gridSettings.sort, gridSettings.sortDir, gridSettings.searchTerm);
    };

    onPageSizeChanged = (pageSize) => {
        const { setGridSettings } = this.props;
        const { originalIds, gridSettings } = this.state;
        const settings = {
            ...gridSettings,
            page: 1,
            pageSize,
        };
        this.setState({
            gridSettings: settings,
        });
        setGridSettings(settings);
        this.getLines(originalIds, 1, pageSize, gridSettings.sort, gridSettings.sortDir, gridSettings.searchTerm);
    };

    onCardPageSizeChanged = (pageSize) => {
        const { setGridSettings } = this.props;
        const { originalIds, gridSettings } = this.state;
        this.dashboardContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

        const settings = {
            ...gridSettings,
            page: 1,
            pageSize,
        };
        this.setState({
            gridSettings: settings,
        });
        setGridSettings(settings);
        this.getLines(originalIds, 1, pageSize, gridSettings.sort, gridSettings.sortDir, gridSettings.searchTerm);
    };

    getLines = (ids, page, pageSize, sort, sortDir, searchTerm) => {
        const { setCardSelected, setSelectedLineItems } = this.props;

        if (ids && ids.length > 0) {
            this.setState({ loading: true });
            axios.post(`/ShoppingService/api/v1/lineItems/dashboard/pagedItemList?Page=${page}&PageSize=${pageSize}&Sort=${sort}&SortDir=${sortDir}&SearchTerm=${searchTerm}`, ids).then(response => {
                let data = response.data.results;

                // setSelectedLineItems(data);
                this.setState({
                    loading: false,
                    items: ids,
                    itemsInPage: data,
                    lastPageQueried: page,
                    totalItems: response.data.totalResults,
                });
                setCardSelected(true);
                setSelectedLineItems(data);
            });
        } else {
            this.setState({
                loading: false,
                items: [],
                gridName: 'opsDashboardDefaultColumns',
                currentGridColumns: [],
            });
            setSelectedLineItems([]);
        }
    }

    columnStyle = (column) => {
        if (column.type === 'date') return { width: '100px' };
        const header = column.header || column.displayName;
        switch (header) {
            case 'Description':
            case 'Facility':
            case 'Item #':
                return { minWidth: '250px' };
            case 'PO #':
                return { minWidth: '110px' };
            case 'Requester':
            case 'Last Approved By':
            case 'Manufacturer':
            case 'Carrier / Priority':
                return { minWidth: '150px' };
            default:
                return null;
        }
    }

    onShowTooltip = (e, msg, lineItemId) => {
        let container = e.currentTarget.closest('.grid-container-dashboard');
        let table = e.currentTarget.closest('table');
        let cell = e.currentTarget.closest('td');
        const loc = cell.getBoundingClientRect();
        const tbl = table.getBoundingClientRect();

        let top = loc.top - tbl.top + 30;
        let left = loc.left - tbl.left + 30;
        let bottom = 0;

        if (top + 140 > tbl.height) {
            bottom = tbl.bottom - loc.bottom + 50;
        }

        if (tbl.height < 120 && bottom < 100) {
            bottom = -20;
        }

        if (left + 250 > tbl.width) {
            left = loc.left - loc.width;

            if (container.scrollLeft > 0) {
                left = left + container.scrollLeft - 30;
            }
        }

        let tooltipLocation = {
            top: `${top}px`,
            left: `${left}px`,
            bottom: `${bottom}px`,
            useTop: bottom === 0 || (tbl.bottom - loc.bottom + 50) > 100,
        }

        this.setState({ tooltipLineItemId: lineItemId, tooltipLocation: tooltipLocation });
        e.stopPropagation();
    }

    onHideTooltip = (e) => {
        this.setState({ tooltipLineItemId: -1 });
        e.stopPropagation();
    }

    onShowPodLetter = (lineItemId) => {
        getPODLetter(lineItemId, this.props.network.tokenInfo, 'OpsDashboardGrid').then(x => {
            if (!x) alert('This document is not currently available.');
        });
    }

    onShowFSRDocument = (doc) => {
        getFSRDocument(doc).then(x => {
            if (!x) alert('This document is not currently available.');
        });
    }

    renderFsrDocumentsColumn = (rowData) => {
        if (!(rowData && rowData.fsrDocuments && rowData.fsrDocuments.length > 0)) return null;

        let { showAllFsrDocuments } = this.state;
        const qtyToDisplay = showAllFsrDocuments[rowData.lineItemId] ? rowData.fsrDocuments.length : 3;

        return (
            <div className="stop-propogation">
                {rowData.fsrDocuments.slice(0, qtyToDisplay).map((d, i) => (<div data-ea-download-link="FSR Document" key={`fsr-doc-${d.lineItemId}-${i}`} className="link" style={{ marginBottom: '5px' }} onClick={() => this.onShowFSRDocument(d)}><i className="fa fa-file-text-o" style={{ paddingRight: '5px' }} />{d.fileName}</div>))}
                {!showAllFsrDocuments[rowData.lineItemId] && rowData.fsrDocuments.length > 3 ?
                    <div style={{ marginTop: '15px' }} onClick={() => {
                        showAllFsrDocuments[rowData.lineItemId] = true;
                        this.setState({ showAllFsrDocuments });
                    }}>Show more ({rowData.fsrDocuments.length - 3})</div> : null}
            </div>
        )
    }

    showInvoice = (lineItemId, invoiceNumber) => {
        const pdfUrl = `/ShoppingService/api/v1/lineitems/invoice/${lineItemId}/${invoiceNumber}`;
        axios.get(pdfUrl, { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json', Accept: 'application/pdf' } }).then((x) => {
            const data = x.data;
            const file = new Blob([data], { type: 'application/pdf' });

            if (file.size === 0) {
                alert('This invoice is not currently available.');
                return;
            }

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file);
                return;
            }

            const fileURL = URL.createObjectURL(file);
            let link = document.createElement('a');
            link.type = 'hidden';
            link.href = fileURL;
            link.download = `invoice.pdf`;
            document.body.appendChild(link);
            link.click();

            setTimeout(function () {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(fileURL);
            }, 100);
        });
    }

    columnTemplate(rowData, c) {
        const { tooltipLineItemId, tooltipLocation } = this.state;
        const { user: { settings: { isShowBackorderToolTips, proofOfDeliveryLinks } } } = this.props;

        switch (c.template) {
            case 'checkMark':
                return (
                    <div className="checkMark-column" onClick={(e) => {
                        e.originalEvent.stopPropagation();
                        return false;
                    }}>
                        {rowData[c.field] ? <i className="fa fa-check" /> : null}
                    </div>
                );
            case 'trackingNumber':
                return rowData.trackingNumber ? (
                    <div className="link" onClick={(e) => {
                        const url = getTrackingNumbersUrlsData(rowData.trackingNumbers, rowData.lineItemId)?.[0]?.url;
                        if (url) window.open(url);
                        e.stopPropagation();
                    }}>
                        {rowData.trackingNumber}
                    </div>
                ) : null;
            case 'invoiceNumber':
                return rowData.invoiceNumber ? (
                    <div data-invoiceNumber={rowData.invoiceNumber} data-lineItemId={rowData.lineItemId} className="link" onClick={() => this.showInvoice(rowData.lineItemId, rowData.invoiceNumber)}>
                        {rowData.invoiceNumber}
                    </div>
                ) : null;
            case 'estimatedDelivery':
                if ((rowData.groupName === 'Delivered' || rowData.status === 'Delivered'))
                    return ''
                else
                    return rowData.estimatedDelivery
                        ? moment(rowData.estimatedDelivery).hours() === 0
                            ? moment(rowData.estimatedDelivery).format('MM/DD/YY') + ' by EOD'
                            : moment(rowData.estimatedDelivery).format('MM/DD/YY h:mm A')
                        : 'Pending';
            case 'date':
                if (c.field === 'estimatedShipDate') {
                    const dateText = rowData.estimatedShipDate;
                    const isBackOrdered = rowData.backordered === 'Yes';

                    let textDecoration = 'none';
                    let displayDate = '';
                    let tooltip = '';
                    let showTooltip = isShowBackorderToolTips;

                    const tooltipStyle = tooltipLocation.useTop ? { top: tooltipLocation.top, left: tooltipLocation.left } :
                        { bottom: tooltipLocation.bottom, left: tooltipLocation.left };

                    if (rowData.status === 'Shipped' || rowData.groupName === 'Shipped') {
                        displayDate = 'Shipped';
                        showTooltip = false;
                    } else if (rowData.status === 'Delivered' || rowData.groupName === 'Delivered') {
                        displayDate = 'Delivered';
                        showTooltip = false;
                    } else if (dateText) {
                        displayDate = moment(rowData[c.field]).format('MM/DD/YY');

                        if (moment().isAfter(moment(dateText))) {
                            textDecoration = 'line-through';
                            tooltip = 'The original ship date provided by the supplier has passed. We are actively following up to obtain a ship date from the supplier, updates will be provided as soon as available.';
                        } else {
                            tooltip = 'An estimated ship date has been provided by the supplier. We will continue to follow up for updates.  Alternative sources are being researched ongoing, to help avoid delays.';
                        }
                    } else {
                        if (isBackOrdered) {
                            displayDate = 'B/O, Date Unavailable';
                            tooltip = 'Your order has been confirmed; the supplier is unable to provide a ship date at this time.  We are actively following up and an update will be provided as soon as available.';
                        } else {
                            displayDate = 'N/A';
                            tooltip = 'We are actively working with the supplier on an update. A ship date will be provided as soon as available.';
                        }
                    }

                    return (<span className="help-icon-link">
                        <span style={{ textDecoration: textDecoration }}>{displayDate}</span> {showTooltip && <i className="fa fa-question-circle" onClick={(e) => this.onShowTooltip(e, 'tooltip here', rowData.lineItemId)} />}
                        {tooltipLineItemId === rowData.lineItemId && showTooltip &&
                            <React.Fragment>
                                <span className="tooltip-background-dashboard" onClick={(e) => this.onHideTooltip(e)} />
                                <span className="tooltip-content" style={tooltipStyle}>
                                    {tooltip}
                                </span>
                            </React.Fragment>}
                    </span>)
                } else {
                    return rowData[c.field] ? moment(rowData[c.field]).format('MM/DD/YY') : '';
                }
            case 'money':
                if (c.decimals === 0) {
                    return (rowData[c.tempField ?? c.field] || rowData[c.tempField ?? c.field] === 0) ? `$${rowData[c.tempField ?? c.field].toLocaleString()}` : '';
                } else {
                    return (rowData[c.tempField ?? c.field] || rowData[c.tempField ?? c.field] === 0) ? formatMoney(rowData[c.tempField ?? c.field]) : '';
                }
                
            case 'podLetter':
                return rowData.hasPODLetter && proofOfDeliveryLinks ? <div className="stop-propogation link" onClick={() => this.onShowPodLetter(rowData.lineItemId)}><i className="fa fa-file-text-o" style={{ paddingRight: '5px' }} />Proof of Delivery</div> : null
            case 'fsrDoc':
                return this.renderFsrDocumentsColumn(rowData);
            case 'creditMemoDoc':
                if (rowData.creditMemoNumber) {
                    return <a href='#' className="link" onClick={() => downloadCreditMemos(rowData.lineItemId)}>Print credit memo(s)</a>
                } else {
                    return '';
                }
            default:
                return null;
        }
    }

    renderGridLine = (item, gridColumns, gridName = []) => {
        const selectedOption = item.options?.filter(x => x.isSelected)[0];
        return (
            <tr key={`${item.lineItemId}}`}
                onClick={() => this.onSelect(item)} style={{ cursor: 'pointer' }}
                className={classNames({
                    gridhighlightedRow: item.priorityDesc === 'Stat' || item.priorityDesc === 'Expedited' || item.priorityDesc === 'Critical',
                    pastDueRow: item.isOverDue || item.isNextDayOvernight,
                    isDueRow: item.isDue || item.isSecondDay,
                    isFormularyRow: item.isFormularyApproval,
                    moqRow: selectedOption && selectedOption.moq && selectedOption.moq > item.quantity,
                })}>
                {gridColumns.map(column => (
                    <td
                        key={column.field}
                        style={this.columnStyle(column)}
                        className={column.template === 'checkMark' || column.template === 'switch' ? 'preformatted no-click-cell' : 'preformatted'}
                    >
                        {column.template && this.columnTemplate(item, column)}
                        {!column.template && this.renderItemValue(item, column)}
                    </td>
                ))}
            </tr>
        );
    }

    formatDate = (value) => {
        return value ? moment(value).format('MM/D/YYYY') : '';
    }

    renderItemValue = (item, column) => {
        const value = item[column.tempField ?? column.field];
        if (column.type === 'date') {
            return this.formatDate(value);
        }
        return value;
    }

    onViewChange = (view) => {
        this.setState({ view: view });
        logEvent('DASHBOARD', {
            'Type': 'Grid/Card View Toggle',
            'View': view,
        });
    }

    saveGridPreferences = (newColumns, newView) => {
        const { gridName } = this.state;
        const { currentGridColumns, view } = this.state;
        let request = {
            gridName,
            view: newView || view,
            columns: newColumns || currentGridColumns,
        };
        axios.post('/ShoppingService/api/v1/account/save/gridSetting', request);
        this.setState({ showColumnSelection: false, columnSelectionColumns: [] });
    }

    resetTableWidth = () => {
        $('#custom-grid').css('width', '');
        $('#custom-grid table').css('width', '');
        $('.ui-paginator-bottom').css('width', '');
    }

    onShowColumnSelection = () => {
        const { currentGridColumns } = this.state;
        let columnSelectionColumns = _.cloneDeep(currentGridColumns);
        this.setState({ columnSelectionColumns, showColumnSelection: true });
    }

    onSaveColumnSelection = () => {
        const { columnSelectionColumns } = this.state;
        this.saveGridPreferences(columnSelectionColumns, null);
        this.setState({ currentGridColumns: columnSelectionColumns });
        this.resetTableWidth();
    }

    onExport = () => {
        let {
            user: {
                settings: { hidePricing },
            }
        } = this.props;
        let { currentGridColumns, itemsInPage } = this.state;
        if (itemsInPage.length === 0) return;

        currentGridColumns = currentGridColumns || [];
        if (hidePricing) {
            currentGridColumns = currentGridColumns.filter((x) => x.field !== 'price' && x.field !== 'extendedPrice' && x.field !== 'unitPrice');
        }

        let Json2csvParser = require('json2csv').Parser;
        Json2csvParser.flatten = true;
        let fields = [];
        currentGridColumns.forEach(({ header, tempField, field }) => fields.push({ label: header, value: tempField ?? field }));
        const json2csvParser = new Json2csvParser({ fields });   
        itemsInPage.forEach((item) => {
            currentGridColumns.forEach((column) => {
                const fieldKey = column.tempField ?? column.field;
                if ((column.type === 'date' || column.template === 'date') && item[fieldKey]) {
                    item[fieldKey] = this.formatDate(item[fieldKey]);
                }
            });
        });

        let csv = json2csvParser.parse(itemsInPage);
        let csvFile = new Blob([csv], { type: 'text/csv' });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(csvFile, 'DataExport.csv');
            return;
        }

        let link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(csvFile));
        link.setAttribute('download', 'DataExport.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
    }

    renderViewSelectionToolbar = () => {
        const { view, searchTerm, showSearchBox } = this.state;
        let cardViewClass = 'icon-view-selector selected';
        return (
            <SelectionToolbarDiv>
                <ViewToolbar className="ps-hidden-xs ps-hidden-sm ps-hidden-md view-selector-wrapper">
                    <span>View:</span>

                    {view === ViewType.Grid && (
                        <img
                            src="/images/icon_card-view.svg"
                            title={`Switch to Card View`}
                            alt={`Switch to Card View`}
                            onClick={() => this.onViewChange(ViewType.Card)}
                            className={cardViewClass}
                            style={{ marginRight: '10px' }}
                        />
                    )}

                    {view === ViewType.Card && (
                        <img
                            src="/images/icon_grid-view.svg"
                            title={`Switch to Grid View`}
                            alt={`Switch to Grid View`}
                            onClick={() => this.onViewChange(ViewType.Grid)}
                            className={cardViewClass}
                            style={{ marginRight: '10px' }}
                        />
                    )}
                    <div className="ps-hidden-xs ps-hidden-sm ps-hidden-md context-menu-wrapper">
                        <i className="fa fa fa-cog" />
                        <div className="context-menu">
                            <div className="context-item" onClick={() => this.onShowColumnSelection()}>
                                <span>Show / Hide Columns</span>
                            </div>
                            <div className="context-item" onClick={() => this.onExport()}>
                                <span>Export To Excel</span>
                            </div>
                        </div>
                    </div>
                </ViewToolbar>
                <GridSearchWrapper>
                    <GridHiddenSearchBox $isVisible={showSearchBox} type="search"
                        className="form-control"
                        onFocus={(e) => this.onSearchFocus(e)}
                        onBlur={(e) => this.onSearchBlur(e)}
                        onChange={(e) => this.onSearchClick(e)}
                        autoComplete="grid-search"
                        value={searchTerm} />
                    <SearchIcon icon={faSearch} />
                </GridSearchWrapper>
            </SelectionToolbarDiv>
        );
    }

    onColumnCheckboxChanged = (e) => {
        let { columnSelectionColumns } = this.state;
        columnSelectionColumns.filter((x) => x.field === e.field)[0].visible = e.checked;
        this.setState({ columnSelectionColumns });
    }

    onSearchFocus = () => {
        this.setState({ showSearchBox: true });
    }

    onSearchBlur = (e) => {
        this.setState({ showSearchBox: e.target.value !== '' });
    }

    onSearchClick = (e) => {
        const { setGridSettings } = this.props;
        const { gridSettings } = this.state;

        const searchTerm = e.target.value;
        this.onSearch(searchTerm);
        this.setState({ currentPage: 1 });

        const settings = {
            ...gridSettings,
            searchTerm,
            page: 1,
        };
        this.setState({
            gridSettings: settings,
            searchTerm
        });
        setGridSettings(settings);
    }

    render() {
        const {
            dashboard: {
                stats,
                updatedStats,
                calendarView,
                updatedCalendarView,
                showUpdatesPanel
            },
            user,
            refresh,
            setShowUpdatesPanel,
        } = this.props;

        const { view, loading, items, itemsInPage, gridSettings, currentGridColumns, gridName, searchTerm, totalItems, showColumnSelection, columnSelectionColumns } = this.state;

        const newActivityAvailable =
            !_.isEqual(_.omit(stats, packetTimingProperties), _.omit(updatedStats, packetTimingProperties))
            || !_.isEqual(
                _.mapValues(calendarView, v => _.omit(v, packetTimingProperties)),
                _.mapValues(updatedCalendarView, v => _.omit(v, packetTimingProperties))
            );

        const showGrid = !loading && items && items.length > 0 && view === 'Grid' ||
            !loading && view === 'Grid' && searchTerm != '';
        const showCards = !loading && items && items.length > 0 && view === 'Card' ||
            view === 'Card' && searchTerm != '';

        const showNoDataFound = !loading && items && items.length == 0;
        const filteredColumns = (currentGridColumns || []).filter(column => column.visible !== false || typeof column.visible === 'undefined');

        return (<>
            <div className="row">
                <PageMetaData title="Command Center" />

                {newActivityAvailable && <span className="new-activity-pill" onClick={refresh}>
                    <FontAwesomeIcon icon={faArrowToBottom} />
                    See New Activity
                </span>}

                <Filters />

                <LeftColumn>
                    <TodayEventSummary
                        onSelect={async ({ items, gridName, gridColumnsMapping, isCardView }) => await this.onSelectActiveItemOnTodayEvents({ items, isCardView, gridName, gridColumnsMapping })}
                    />
                    <KeyActions onClickItem={async (item) => await this.onSelectKeyActionsItem(item)} />
                    <OperationalActivity onClickItem={async ({ items, selectedCategory, gridName, gridColumnsMapping, isCardView }) => await this.onSelectOperationalActivityItem({ items, selectedCategory, isCardView, gridName, gridColumnsMapping })} />
                    {showNoDataFound && (
                        <NoResults>
                            There are no available results.
                        </NoResults>
                    )}

                    <DashboardContent
                        className="col-12 card-view-wrapper"
                        ref={this.dashboardContentRef}
                    >
                        {loading && <LoaderContainer>
                            <Loader />
                        </LoaderContainer>}

                        {showGrid ? <Lg>
                            <div>
                                {this.renderViewSelectionToolbar()}
                                <div className="grid-wrapper">
                                    <div className="grid-container-dashboard">
                                        <CustomServerGrid
                                            headerColumns={filteredColumns}
                                            totalRecords={totalItems !== 0 ? totalItems : itemsInPage.length}
                                            showSearch={false}
                                            showPagination={false}
                                            defaultSortColumn={gridSettings.sort}
                                            defaultSortDirection={gridSettings.sortDir}
                                            page={gridSettings.page}
                                            defaultPageSize={gridSettings.pageSize}
                                            onSort={e => this.onSort(e.sortColumn, e.sortDirection)}
                                            subLabel="Command Center"
                                            tableClassName="edi-table"
                                        >
                                            {itemsInPage ? itemsInPage.map(i => this.renderGridLine(i, filteredColumns, gridName)) : null}
                                        </CustomServerGrid>
                                        <GridPagination
                                            displayPages={10}
                                            totalRecords={totalItems !== 0 ? totalItems : itemsInPage.length}
                                            pageSize={gridSettings.pageSize}
                                            onPageSizeChange={(data) => this.onPageSizeChanged(parseInt(data.value))}
                                            onSelect={(pageNumber) => this.onPageSelected({ selectedPage: parseInt(pageNumber.value) })}
                                            currentPage={gridSettings.page}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Lg> : showCards &&
                        <Lg>
                            <div>
                                {this.renderViewSelectionToolbar()}
                                <CustomServerCards onCardSearch={(term) => this.onSearch(term)}
                                    headerColumns={filteredColumns}
                                    totalRecords={totalItems !== 0 ? totalItems : itemsInPage.length}
                                    showSearch={false}
                                    page={gridSettings.page}
                                    defaultSortColumn={gridSettings.sort}
                                    defaultSortDirection={gridSettings.sortDir}
                                    showPagination={true}
                                    displayPages={10}
                                    loadingData={loading}
                                    defaultSearchTerm={searchTerm}
                                    defaultPageSize={gridSettings.pageSize}
                                    onPageSelected={(selectedPageProps) => this.onPageSelected(selectedPageProps)}
                                    onPageSizeChange={(data) => this.onPageSizeChanged(parseInt(data.value))}
                                    data={itemsInPage}
                                    subLabel="Command Center"
                                    onSelect={this.onSelect}
                                />
                                <GridPagination
                                    displayPages={10}
                                    totalRecords={totalItems !== 0 ? totalItems : itemsInPage.length}
                                    pageSize={gridSettings.pageSize}
                                    onPageSizeChange={(pageSizeChangedProps) => this.onCardPageSizeChanged(parseInt(pageSizeChangedProps.value))}
                                    onSelect={(selectedPageProps) => this.onCardPageSelected(selectedPageProps.value)}
                                    currentPage={gridSettings.page}
                                />
                            </div>
                        </Lg>}
                        <PhoneLandscape>
                            <div>
                                {this.renderViewSelectionToolbar()}
                                <CustomServerCards
                                    onCardSearch={(term) => this.onSearch(term)}
                                    loadingData={loading}
                                    defaultSearchTerm={searchTerm}
                                    data={itemsInPage}
                                    subLabel="Command Center"
                                    onSelect={this.onSelect}
                                />
                                {(itemsInPage.length >= gridSettings.pageSize && (totalItems > gridSettings.pageSize)) && <MobileGridPaginationContainer>
                                    <GridPaginationMobile
                                        totalRecords={totalItems !== 0 ? totalItems : itemsInPage.length}
                                        pageSize={gridSettings.pageSize}
                                        onPageSizeChange={(pageSizeChangedProps) => this.onCardPageSizeChanged(parseInt(pageSizeChangedProps))}
                                    />
                                </MobileGridPaginationContainer>}
                            </div>
                        </PhoneLandscape>
                    </DashboardContent>
                </LeftColumn>
                <Lg>
                    <RightColumn>
                        <UpdatesPanel onUpdatePanelItemSelected={async (lineItemId) => await this.onUpdatePanelItemSelected(lineItemId)} />
                    </RightColumn>
                </Lg>
            </div>
            {
                showUpdatesPanel && <>
                    <Xs>
                        <StyledPopup show={showUpdatesPanel} onCancel={() => setShowUpdatesPanel(false)} hideButtons={true} inlineHeader={true}>
                            <UpdatesPanel onUpdatePanelItemSelected={async (lineItemId) => await this.onUpdatePanelItemSelected(lineItemId)} />
                        </StyledPopup>
                    </Xs>
                    <Sm>
                        <Drawer show={showUpdatesPanel} onClose={() => setShowUpdatesPanel(false)} width={'457px'} style={{ paddingTop: '20px', paddingBottom: '20px', overflow: 'auto' }}>
                            <Close><button type="button" onClick={() => setShowUpdatesPanel(false)}>x</button></Close>
                            <UpdatesPanel onUpdatePanelItemSelected={async (lineItemId) => await this.onUpdatePanelItemSelected(lineItemId)} />
                        </Drawer>
                    </Sm>
                    <Md>
                        <Drawer show={showUpdatesPanel} onClose={() => setShowUpdatesPanel(false)} width={'457px'} style={{ paddingTop: '20px', paddingBottom: '20px', overflow: 'auto' }}>
                            <Close><button type="button" onClick={() => setShowUpdatesPanel(false)}>x</button></Close>
                            <UpdatesPanel onUpdatePanelItemSelected={async (lineItemId) => await this.onUpdatePanelItemSelected(lineItemId)} />
                        </Drawer>
                    </Md>
                </>
            }

            {
                showColumnSelection ? (
                    <Popup
                        confirmText="Save Columns"
                        cancelText="Cancel"
                        onCancel={() => this.setState({ showColumnSelection: false })}
                        show={true}
                        onConfirm={() => this.onSaveColumnSelection()}
                        className="company-selection-popup"
                    >
                        <div className="selection-title">Show / Hide Columns</div>
                        {columnSelectionColumns.map(({field, header, visible}, i) => (
                            <Checkbox
                                id={i}
                                key={`${i}-${field}-key`}
                                label={header}
                                field={field}
                                checked={visible}
                                tabIndex={i}
                                className="column-checkbox"
                                onChange={this.onColumnCheckboxChanged}
                            />
                        ))}
                    </Popup>
                ) : null
            }
        </>);
    }
}
