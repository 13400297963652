import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {connect, useSelector} from 'react-redux';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import {theme, Dropdown} from '@partssourceinc/react-ui-core';
import {logEvent} from 'utility';

import * as DashboardStore from 'stores/Dashboard';

import MobileFilters from './MobileFilters';

import './today-event-summary.less';
import DashboardCards from '../../data/DashboardCards';

const ItemContainer = styled.div`
    padding: 0;
    border: 1px solid black;
    height: 120px;
    margin: 20px 20px;
    
    background-color: white;
    position: relative;
    cursor: pointer;
    
    flex: 1 1 100%;
    
    @media (min-width: ${theme.screenSMMin}) {
        flex: 1 1 50%;
    }
    
    @media (min-width: ${theme.screenMDMin}) {
        flex: 1 1 20%;
    }
`;

const ItemLink = styled.div`
    display: flex;
    position: absolute;

    @media (max-width: ${theme.screenSMMin}) {
        width: 300px;
    }
`;

const MobileFilterButton = styled.div`
    @media (min-width: 820px) {
        display: none;    
    }
`;

const mapStateToProps = state => ({dashboard: state.dashboard});
const mapDispatchToProps = {...DashboardStore.actionCreators};

export function TodayEventSummary(props) {
    const {dashboard: {
        calendarView, 
        selectedDate, 
        updatedStats,
        removedLineItems,
    }, setLineItems, setSelectedDate} = props;

    const selectedCategories = useSelector(state => state.user.dashboardViewSettings.selectedCategories);
    const selectedModalities = useSelector(state => state.user.dashboardViewSettings.selectedModalities);
    const selectedFacilities = useSelector(state => state.user.dashboardViewSettings.selectedFacilities);
    const selectedRequesters = useSelector(state => state.user.dashboardViewSettings.selectedRequesters);
    
    let dateOpts = [
        {text: moment(new Date()).format('MMM DD, YYYY'), value: 0}, 
        {text: moment(new Date()).add(1, 'days').format('MMM DD, YYYY'), value: 1}, 
        {text: moment(new Date()).add(2, 'days').format('MMM DD, YYYY'), value: 2},
    ];
    const [dateSelected, setDateSelected] = useState('0');
    const [dataSelected, setDataSelected] = useState({
        parts: 0, 
        partsData: [], 
        service: 0, 
        serviceData: [],  
        repairs: 0,
        repairsData: [], 
        service_history: 0, 
        repairs_history: 0,
        loaners: 0,
        loanersData: []
    });
    const [activeItem, setActiveItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const activeSection = useSelector(state => state.dashboard.activeSection);
    useEffect(() => {
        setLoading(typeof updatedStats.parts_due_today !== 'undefined' ? false : true);
        setData(dateSelected);
    }, [updatedStats]);

    useEffect(() => {
        setData(dateSelected);
    }, [dateSelected, selectedCategories, selectedFacilities, selectedModalities, selectedRequesters, removedLineItems]);

    useEffect(() => {
        if (activeItem != null && activeSection != 'todayEvents')
            setActiveItem(null);
    }, [activeSection]);

    const handleChangeDefaultPage = (value) => {
        logEvent('DASHBOARD', {
            'Todays Events': 'Date Change',
            'Selection': 'Today +' + value,
        });
        setDateSelected(value);
        setData(value);
        setActive(null);
    }

    const setData = (value) => {
        let obj = {};
        const {dashboard: {updatedStats}} = props;  
        
        switch (value) {
            case '0':
                obj = { 
                    parts: updatedStats.parts_due_today ? updatedStats.parts_due_today : 0, 
                    partsData: updatedStats.parts_due_today_data, 
                    service: updatedStats.service_due_today ? updatedStats.service_due_today : 0, 
                    serviceData: updatedStats.service_due_today_data, 
                    repairs: updatedStats.repairs_due_today ? updatedStats.repairs_due_today : 0,
                    repairsData: updatedStats.repairs_due_today_data,
                    loaners: updatedStats.loaners_due_today ? updatedStats.loaners_due_today : 0, 
                    loanersData: updatedStats.loaners_due_today_data, 
                };   
                break; 
            case '1':
                obj = { 
                    parts: updatedStats.parts_due_today_and_1 ? updatedStats.parts_due_today_and_1 : 0, 
                    partsData: updatedStats.parts_due_today_and_1_data, 
                    service: updatedStats.service_due_today_and_1 ? updatedStats.service_due_today_and_1 : 0, 
                    serviceData: updatedStats.service_due_today_and_1_data,  
                    repairs: updatedStats.repairs_due_today_and_1 ? updatedStats.repairs_due_today_and_1 : 0,
                    repairsData: updatedStats.repairs_due_today_and_1_data,
                    loaners: updatedStats.loaners_due_today_and_1 ? updatedStats.loaners_due_today_and_1 : 0, 
                    loanersData: updatedStats.loaners_due_today_and_1_data, 
                };
                break;
            case '2':
                obj = { 
                    parts: updatedStats.parts_due_today_and_2 ? updatedStats.parts_due_today_and_2 : 0, 
                    partsData: updatedStats.parts_due_today_and_2_data, 
                    service: updatedStats.service_due_today_and_2 ? updatedStats.service_due_today_and_2 : 0, 
                    serviceData: updatedStats.service_due_today_and_2_data,  
                    repairs: updatedStats.repairs_due_today_and_2 ? updatedStats.repairs_due_today_and_2 : 0,
                    repairsData: updatedStats.repairs_due_today_and_2_data,
                    loaners: updatedStats.loaners_due_today_and_2 ? updatedStats.loaners_due_today_and_2 : 0, 
                    loanersData: updatedStats.loaners_due_today_and_2_data, 
                };
                break;
            default: 
                obj = {
                    parts: 0, 
                    partsData: [], 
                    service: 0, 
                    serviceData: [],  
                    repairs: 0,
                    repairsData: [], 
                    loaners: 0,
                    loanersData: []
                }
        }

        obj.serviceHistory = updatedStats.service_history;
        obj.repairsHistory = updatedStats.repairs_history;
        obj.partsData = applyFilters(obj.partsData);
        obj.serviceData = applyFilters(obj.serviceData);
        obj.repairsData = applyFilters(obj.repairsData);
        obj.loanersData = applyFilters(obj.loanersData);
        setDataSelected(obj);
    }

    /**
     * @param {*} partsDataArr {company_id: number; requester_id: number; modality_id: number; modality_name: string; } []  
     * @returns  array of partsData after applying filters against the selected filter values
     */
    const applyFilters = (partsDataArr) => {
        if (Array.isArray(partsDataArr) === false) {
            return partsDataArr;
        }
        const ALL_SELECTED_ID = '-1';

        const isAllCategorySelected = selectedCategories.length === 0 || selectedCategories[ALL_SELECTED_ID];
        const isAllRequesterSelected = selectedRequesters.length === 0 || selectedRequesters[ALL_SELECTED_ID];
        const isAllFacilitiesSelected = selectedFacilities.length === 0 || selectedFacilities[ALL_SELECTED_ID];
        const isAllModalitiesSelected = selectedModalities.length === 0 || selectedModalities[ALL_SELECTED_ID];

        return partsDataArr.filter((partItem) => {
            const isUncategorizedModalityItem = selectedModalities['-2'] && !partItem.category_path;

            return (isAllCategorySelected || selectedCategories[partItem.modality_name])
                && (isAllRequesterSelected || selectedRequesters[partItem.requestor_id])
                && (isAllFacilitiesSelected || selectedFacilities[partItem.company_id])
                && (isAllModalitiesSelected || isUncategorizedModalityItem || Object.keys(selectedModalities).some((modalityKey) => partItem.category_path && partItem.category_path.includes(modalityKey)))
                && (!removedLineItems.includes(partItem.line_item_id))
        });
    }
    const setActive = (item, data) => {
        const {onSelect} = props;  
        setActiveItem(item);
        let obj = {};

        if (item === null) {
            onSelect({
                items: [],
            });

            return;
        }
        
        switch (item) {
            case 0:
                obj = { 
                    type: 'parts', 
                    items: dataSelected.partsData,
                    ...DashboardCards.find(({statsKey}) => statsKey === 'orders_data')
                };
                logEvent('DASHBOARD', {
                    'Todays Events': 'Focus Section',
                    'Selection': 'Parts',
                });
                break; 
            case 1:
                obj = { 
                    type: 'service', 
                    items: dataSelected.serviceData,
                    ...DashboardCards.find(({statsKey}) => statsKey === 'services_all_data')
                };
                logEvent('DASHBOARD', {
                    'Todays Events': 'Focus Section',
                    'Selection': 'Service',
                });
                break;
            case 2:
                obj = { 
                    type: 'repairs', 
                    items: dataSelected.repairsData,
                    ...DashboardCards.find(({statsKey}) => statsKey === 'repairs_all_data')
                };
                logEvent('DASHBOARD', {
                    'Todays Events': 'Focus Section',
                    'Selection': 'Depot Repairs',
                });
                break;
            case 3:
                obj = { 
                    type: 'loaners', 
                    items: dataSelected.loanersData,
                    ...DashboardCards.find(({statsKey}) => statsKey === 'loaners_all_data')
                };
                logEvent('DASHBOARD', {
                    'Todays Events': 'Focus Section',
                    'Selection': 'Loaners',
                });
                break;

        }

        onSelect(obj);
    }

    return (
        <div className="event-summary-container">
            <div style={{display: 'flex', justifyContent: 'space-between', padding: '10px 33px', fontSize: '20px'}}> 
                <span className="display-date">{moment(new Date()).format('MMMM DD, YYYY') } </span> 
                <span className="c-date-dropdown"><Dropdown onChange={(e) => handleChangeDefaultPage(e.target.value)}
                    options={dateOpts} selectedValue={dateSelected} suppressBlankOption={true} valueField="value" textField="text" /></span>
                <MobileFilterButton>
                    <MobileFilters onOpenUpdatesPanel={() => this.setState({showUpdatesPanel: true})} />
                </MobileFilterButton>
            </div>
            <div className="item-container-parent">
                <ItemContainer className={!loading ? 'item-container ' + (activeItem === 0 ? 'active' : '') : 'item-container skeleton'} onClick={()=>{ setActive(0) }}>
                    <div style={{ 'flex-direction': 'column', padding: '20px' }}>
                        <ItemLink className={!loading ? '' : 'skeleton-section'}><span style={{ fontWeight:'bold', whiteSpace: 'nowrap'}}>Parts</span> { Array.isArray(dataSelected.partsData) && dataSelected.partsData.length !== 0 && <span className='view-events-detail-link'> View Events Detail</span>}</ItemLink>
                        <div className={!loading ? '' : 'skeleton-section'} style={{fontSize: '80px', marginTop: '21px', paddingTop: 'inherit'}}>
                            { Array.isArray(dataSelected.partsData) ? dataSelected.partsData.length : 0}
                        </div>    
                    </div>
                    <img className={!loading ? 'img-item' : 'skeleton-section'} src="/images/bg-parts.png" />
                    <div className="overlay-div" />
                </ItemContainer>
                <ItemContainer className={!loading ? 'item-container ' + (activeItem === 1 ? 'active' : '') : 'item-container skeleton'} onClick={()=>{ setActive(1) }}>
                    <div style={{ flexDirection: 'column', padding: '20px' }}> 
                        <ItemLink className={!loading ? '' : 'skeleton-section'}><span style={{ fontWeight:'bold', whiteSpace: 'nowrap'}}>On-Site Service</span> {Array.isArray(dataSelected.serviceData) && dataSelected.serviceData.length !== 0 && <span className='view-events-detail-link'>View Events Detail</span>}</ItemLink>                    
                        {
                            dataSelected.serviceHistory > 0 ?
                                <div className={!loading ? '' : 'skeleton-section'} style={{fontSize: '80px', marginTop: '22px', paddingTop: 'inherit'}}>
                                    { Array.isArray(dataSelected.serviceData) ? dataSelected.serviceData.length : 0}
                                </div> :
                                <div className={!loading ? 'zero-state-info' : 'skeleton-section'} >
                                    <p className="zero-state-text">Save time and money with our on-site services.</p>
                                    <p><a className="zero-state-link" href="https://www.partssource.com/provider/on-demand-service">Learn About Our Service Options &gt;</a></p></div>
                        }
                    </div>
                    <img className={!loading ? 'img-item-1' : 'skeleton-section'} src="/images/bg-service.png" />
                    <div className="overlay-div" />
                </ItemContainer>
                <ItemContainer className={!loading ? 'item-container ' + (activeItem === 2 ? 'active' : '') : 'item-container skeleton'} onClick={()=>{ setActive(2) }}>
                    <div style={{ flexDirection: 'column', padding: '20px' }}>
                        <ItemLink className={!loading ? '' : 'skeleton-section'}><span style={{ fontWeight:'bold', whiteSpace: 'nowrap'}}>Depot Repairs</span> {Array.isArray(dataSelected.repairsData) && dataSelected.repairsData.length !== 0 && <span className='view-events-detail-link'>View Events Detail</span>}</ItemLink>
                        {
                            dataSelected.repairsHistory > 0 ?
                                <div className={!loading ? '' : 'skeleton-section'} style={{fontSize: '80px', marginTop: '22px', paddingTop: 'inherit'}}>
                                    { Array.isArray(dataSelected.repairsData) ? dataSelected.repairsData.length : 0}
                                </div> :
                                <div className={!loading ? 'zero-state-info' : 'skeleton-section'} >
                                    <p className="zero-state-text">Save time and money with our depot repair services.</p>
                                    <p><a className="zero-state-link" href="https://www.partssource.com/shop/repair">Learn About Our Depot Repair Options &gt;</a></p></div>
                        }
                    </div>
                    <img className={!loading ? 'img-item-1' : 'skeleton-section'} src="/images/bg-repairs.png" />
                    <div className="overlay-div" />
                </ItemContainer>
                <ItemContainer className={!loading ? 'item-container ' + (activeItem === 3 ? 'active' : '') : 'item-container skeleton'} onClick={()=>{ setActive(3) }}>
                    <div style={{ 'flex-direction': 'column', padding: '20px' }}>
                        <ItemLink className={!loading ? '' : 'skeleton-section'}><span style={{ fontWeight:'bold', whiteSpace: 'nowrap'}}>Loaners</span> { Array.isArray(dataSelected.loanersData) && dataSelected.loanersData.length !== 0 && <span className='view-events-detail-link'> View Events Detail</span>}</ItemLink>
                        <div className={!loading ? '' : 'skeleton-section'} style={{fontSize: '80px', marginTop: '21px', paddingTop: 'inherit'}}>
                            { Array.isArray(dataSelected.loanersData) ? dataSelected.loanersData.length : 0}
                        </div>    
                    </div>
                    <img className={!loading ? 'img-item-2' : 'skeleton-section'} src="/images/bg-loaners.png" />
                    <div className="overlay-div" />
                </ItemContainer>
            </div>
        </div>
    )
}

TodayEventSummary.propTypes = {
    dashboard: DashboardStore.StateShape,
    setLineItems: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(TodayEventSummary);
