import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import {ResponsivePie} from '@nivo/pie';
import {logEvent, formatMoney} from 'utility';

import './keyactions.less';
import KeyActionsToolTip from './KeyActionsToolTip';

const TitleContainer = styled.div`
    max-width: 1630px;
    margin: 0 auto;
`;

const Title = styled.div`
    color: #000;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
`;

const KeyActions = (props) => {
    const stats = useSelector(state => state.dashboard.stats);
    const activeSection = useSelector(state => state.dashboard.activeSection);
    const dashboardStatsContainer = `dashboard-margin-container key-actions-container`;
    const selectedCategories = useSelector(state => state.user.dashboardViewSettings.selectedCategories);
    const selectedModalities = useSelector(state => state.user.dashboardViewSettings.selectedModalities);
    const selectedFacilities = useSelector(state => state.user.dashboardViewSettings.selectedFacilities);
    const selectedRequesters = useSelector(state => state.user.dashboardViewSettings.selectedRequesters);
    const removedLineItems = useSelector(state => state.dashboard.removedLineItems);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [activeItem, setActiveItem] = useState(null);
    const [exchangeTotalPrice, seteTotalPrice] = useState([0]);
    const [exchangeDueSoonPrice, seteDueSoon] = useState([0]);
    const [exchangePastDueTransactions, setPastDueTransactions] = useState(0);
    const [exchangeDueSoonTransactions, setDueSoonTransactions] = useState(0);
    const [returnsTotalPrice, setReturnsTotalPrice] = useState([0]);
    const [returnsDueSoon, setReturnsDueSoon] = useState([0]);
    const [returnsPastDueTransactions, setReturnsPastDueTransactions] = useState(0);
    const [returnsDueSoonTransactions, setReturnsDueSoonTransactions] = useState(0);
    const [approvalOvernight, setApprovalOvernight] = useState([]);
    const [criticalApproval, setCriticalApproval] = useState([]);
    const [onTimeOrders, setOnTimeOrders] = useState([0]);
    const [overnightDelayed, setOvernightDelayed] = useState([0]);
    const [criticalOntime, setCriticalOntime] = useState([0]);
    const [criticalDelayed, setCriticalDelayed] = useState([0]);
    const [ePastDueItems, setePastDueItems] = useState([]);
    const [eDueSoonItems, seteDueSoonItems] = useState([]);
    const [rPastDueItems, setrPastDueItems] = useState([]);
    const [rDueSoonItems, setrDueSoonItems] = useState([]);
    const [aPastCutoffItems, setaPastCutoffItems] = useState([]);
    const [aCriticalDownItems, setaCriticalDownItems] = useState([]);
    const [overnightOntimeItems, setOvernightOntimeItems] = useState([]);
    const [overnightDelayedItems, setOvernightDelayedItems] = useState([]);
    const [criticalItemsOntime, setCriticalItemsOntime] = useState([]);
    const [criticalItemsDelayed, setCriticalItemsDelayed] = useState([]);

    const mapOrders = () => {
        const results = _.mapValues(stats, (v, k) => {
            if (_.isArray(v)) {
                return v.filter(o =>
                    (selectedCategories.length === 0 || selectedCategories['-1'] || selectedCategories[o.modality_name] || (selectedCategories['-2'] && !o.modality_name))
                    && (selectedRequesters.length === 0 || selectedRequesters['-1'] || selectedRequesters[o.requestor_id])
                    && (selectedFacilities.length === 0 || selectedFacilities['-1'] || selectedFacilities[o.company_id])
                    && (selectedModalities.length === 0 || selectedModalities['-1'] || (selectedModalities['-2'] && !o.category_path) || Object.keys(selectedModalities).some(key => o.category_path && o.category_path.includes(key)))
                    && (!removedLineItems.includes(k.line_item_id)));
            } else if (_.isNumber(v)) {
                return !stats[`${k}_data`]
                    ? v
                    : stats[`${k}_data`].filter(o =>
                        (selectedCategories.length === 0 || selectedCategories['-1'] || selectedCategories[o.modality_name] || (selectedCategories['-2'] && !o.modality_name))
                        && (selectedRequesters.length === 0 || selectedRequesters['-1'] || selectedRequesters[o.requestor_id])
                        && (selectedFacilities.length === 0 || selectedFacilities['-1'] || selectedFacilities[o.company_id])
                        && (selectedModalities.length === 0 || selectedModalities['-1'] || (selectedModalities['-2'] && !o.category_path) || Object.keys(selectedModalities).some(key => o.category_path && o.category_path.includes(key)))
                        && (!removedLineItems.includes(o.line_item_id))
                    ).length;
            } else {
                return v;
            }
        });

        return results;
    }
    useEffect(() => {
        if (activeItem != null && activeSection !== 'keyAction')
            setActiveItem(null);
    }, [activeSection]);

    useEffect(() => {
        setOrders(mapOrders());
    }, [stats]);

    useEffect(() => {
        const results = mapOrders();
        setOrders(results);

        if (activeItem) {
            const {onClickItem} = props;
            onClickItem(getDataSetForActiveItem(results));
        }
    }, [selectedModalities, selectedCategories, selectedRequesters, selectedFacilities]);

    useEffect(() => {
        if (typeof orders.exchanges_past_due_data !== 'undefined') {
            let accumulator = 0;
            for (let i = 0; i < orders.exchanges_past_due_data.length; i++) {
                accumulator += orders.exchanges_past_due_data[i].exchange_price;
            }
            seteTotalPrice(Math.round(accumulator));
            setePastDueItems(orders.exchanges_past_due_data);
            setPastDueTransactions(orders.exchanges_past_due_data.length);
            setLoading(false);
        }
    }, [orders]);

    useEffect(() => {
        if (typeof orders.exchanges_due_soon_data !== 'undefined') {
            let accumulator = 0;
            for (let i = 0; i < orders.exchanges_due_soon_data.length; i++) {
                accumulator += orders.exchanges_due_soon_data[i].exchange_price;
            }
            seteDueSoon(Math.round(accumulator));
            setDueSoonTransactions(orders.exchanges_due_soon_data.length);
            seteDueSoonItems(orders.exchanges_due_soon_data);
        }
    }, [orders]);

    useEffect(() => {
        if (typeof orders.returns_past_due_data !== 'undefined') {
            let accumulator = 0;
            for (let i = 0; i < orders.returns_past_due_data.length; i++) {
                accumulator += orders.returns_past_due_data[i].exchange_price;
            }
            setReturnsTotalPrice(Math.round(accumulator));
            setReturnsPastDueTransactions(orders.returns_past_due_data.length);
            setrPastDueItems(orders.returns_past_due_data);
        }
    }, [orders]);

    useEffect(() => {
        if (typeof orders.returns_due_soon_data !== 'undefined') {
            let accumulator = 0;
            for (let i = 0; i < orders.returns_due_soon_data.length; i++) {
                accumulator += orders.returns_due_soon_data[i].exchange_price;
            }
            setReturnsDueSoon(Math.round(accumulator));
            setReturnsDueSoonTransactions(orders.returns_due_soon_data.length);
            setrDueSoonItems(orders.returns_due_soon_data);
        }
    }, [orders]);

    useEffect(() => {
        if (typeof orders.approval_overnight_data !== 'undefined') {
            setaPastCutoffItems(orders.approval_overnight_data);
            setApprovalOvernight(orders.approval_overnight_data.length);
        }
    }, [orders]);

    useEffect(() => {
        if (typeof orders.approval_priority_data !== 'undefined') {
            setaCriticalDownItems(orders.approval_priority_data);
            setCriticalApproval(orders.approval_priority_data.length);
        }
    }, [orders]);

    // Overnight
    useEffect(() => {
        if (typeof orders.orders_past_cutoff_data !== 'undefined') {
            setOvernightOntimeItems(orders.orders_past_cutoff_data);
            setOnTimeOrders(orders.orders_past_cutoff_data.length);
        }
    }, [orders]);

    useEffect(() => {
        if (typeof orders.orders_delivery_delayed_data !== 'undefined') {
            setOvernightDelayedItems(orders.orders_delivery_delayed_data);
            setOvernightDelayed(orders.orders_delivery_delayed_data.length);
        }
    }, [orders]);

    useEffect(() => {
        if (typeof orders.orders_harddown_ontime_data !== 'undefined') {
            setCriticalItemsOntime(orders.orders_harddown_ontime_data);
            setCriticalOntime(orders.orders_harddown_ontime_data.length);
        }
    }, [orders]);

    useEffect(() => {
        if (typeof orders.orders_harddown_delayed_data !== 'undefined') {
            setCriticalItemsDelayed(orders.orders_harddown_delayed_data);
            setCriticalDelayed(orders.orders_harddown_delayed_data.length);
        }
    }, [orders]);

    const placeHolderData = [{
        value: 1,
    }, {
        value: 0,
    }];

    const exchangeData = [
        {
            id: 'Due Soon',
            label: 'Due Next 2 Days',
            value: exchangeDueSoonPrice,
            data: exchangeDueSoonTransactions,
        },
        {
            id: 'Past Due',
            label: 'Past Due',
            value: exchangeTotalPrice,
            data: exchangePastDueTransactions,
        },
    ];

    const returnsData = [
        {
            id: 'Due Soon',
            label: 'Due Next 2 Days',
            value: returnsDueSoon,
            data: returnsDueSoonTransactions,
        },
        {
            id: 'Past Due',
            label: 'Past Due',
            value: returnsTotalPrice,
            data: returnsPastDueTransactions,
        },
    ];

    const approvalData = [
        {
            id: 'Priority',
            label: 'Priority',
            value: criticalApproval,
            data: criticalApproval,
        },
        {
            id: 'Overnight',
            label: 'Overnight',
            value: approvalOvernight,
            data: approvalOvernight,
        },
    ];

    const selectActionItem = (data, title, subTitle, value) => {
        logEvent('DASHBOARD', {
            'Key Actions': 'Category Selected',
            'Category': title,
            'Sub Category': subTitle,
        });
        const {onClickItem} = props;
        onClickItem(data);
        setActiveItem(value);
    }

    const getDataSetForActiveItem = (data) => {
        switch (activeItem) {
            case 0:
                return data.exchanges_past_due_data;
            case 1:
                return data.exchanges_due_soon_data;
            case 2:
                return data.returns_past_due_data;
            case 3:
                return data.returns_due_soon_data;
            case 4:
                return data.approval_overnight_data;
            case 5:
                return data.approval_priority_data;
            case 6:
                return data.orders_past_cutoff_data;
            case 7:
                return data.orders_delivery_delayed_data;
            case 8:
                return data.orders_harddown_ontime_data;
            case 9:
                return data.orders_harddown_delayed_data;
            default:
                break;
        }
    }

    return (
        <>
            <div className="col-md-12 row-dashboard-key-actions-section">
                <TitleContainer>
                    <Title>Key Actions</Title>
                </TitleContainer>
                <div className = {dashboardStatsContainer}>
                    <div className="key-actions">
                        <div className="tile tile-border">
                            <div className="pie">
                                {(exchangeDueSoonPrice || exchangeTotalPrice) > 0 ?
                                    <ResponsivePie
                                        data={exchangeData}
                                        margin={{top: 0}}
                                        innerRadius={0.8}
                                        padAngle={1}
                                        colors={['#ffca82', '#ff0000']}
                                        colorBy="index"
                                        height={85}
                                        width={85}
                                        enableRadialLabels={false}
                                        enableSlicesLabels={false}
                                        enableArcLabels={false}
                                        enableArcLinkLabels={false}
                                        tooltip={({datum: {id, data}}) => (
                                            <div
                                                style={{
                                                    fontSize: 12,
                                                    padding: 8,
                                                    borderWidth: 1,
                                                    borderColor: '#ccc',
                                                    borderStyle: 'solid',
                                                    background: 'rgb(222 217 217)',
                                                    marginLeft: 'calc(50% + 10px)',
                                                    borderRadius: '4px',
                                                    position: 'absolute',
                                                    width: 156,
                                                    marginTop: -24,
                                                }}
                                            >

                                                {<strong>
                                                    {data.data}
                                                </strong>}&nbsp;
                                                <span style={{color: 'black'}}>Exchanges {id}</span>
                                            </div>
                                        )}
                                    />
                                    : <ResponsivePie
                                        data={placeHolderData}
                                        margin={{top: 0}}
                                        innerRadius={0.8}
                                        padAngle={1}
                                        colors={['#f0f0f0']}
                                        colorBy="index"
                                        height={85}
                                        width={85}
                                        enableRadialLabels={false}
                                        enableSlicesLabels={false}
                                        enableArcLabels={false}
                                        enableArcLinkLabels={false}
                                        isInteractive={false}
                                    />
                                }
                            </div>
                            <div className="dataGraph">
                                <div className="ka-column">
                                    <div className="ka-row">
                                        <div style={exchangeTotalPrice === 0 ? {color: 'black'} : {color: 'red'}} className={!loading ? 'data-container ' + (activeItem === 0 ? 'active' : '') : 'data-container skeleton past-due'} onClick={() => {
                                            selectActionItem(ePastDueItems, 'Exchanges', 'Past Due');
                                            setActiveItem(0);
                                        }} >
                                            {!loading && (<KeyActionsToolTip
                                                direction="left"
                                                tooltipText="Dollar amount of Exchanges Past Due"
                                            >
                                                {formatMoney(exchangeTotalPrice).slice(0, -3)}
                                            </KeyActionsToolTip>)}
                                            <div className={!loading ? 'data-label ' : 'data-label skeleton'}>
                                                Past Due
                                            </div>
                                        </div>
                                        <div className={!loading ? 'data-container ' + (activeItem === 1 ? 'active' : '') : 'data-container skeleton due-soon'} onClick = {() => {
                                            selectActionItem(eDueSoonItems, 'Exchanges', 'Due in 2 days');
                                            setActiveItem(1);
                                        }}>
                                            {!loading && (<KeyActionsToolTip
                                                direction="left"
                                                tooltipText="Dollar amount of Exchanges Due in Next 2 days"
                                            >
                                                {formatMoney(exchangeDueSoonPrice).slice(0, -3)}
                                            </KeyActionsToolTip>)}
                                            <div className={!loading ? 'data-label' : 'data-label skeleton'}>
                                                Due Next 2 Days
                                            </div>
                                        </div>
                                    </div>
                                    <div className={!loading ? 'section-id' : 'section-id skeleton'}>
                                        <img className={!loading ? 'section-icn' : 'section-icn skeleton'} src={require('/images/dashboard/icn_exchanges.png')} />
                                        Exchanges
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tile tile-border">
                            <div className="pie">
                                {(returnsDueSoon || returnsTotalPrice) > 0 ?
                                    <ResponsivePie
                                        data={returnsData}
                                        margin={{top: 0}}
                                        innerRadius={0.8}
                                        padAngle={1}
                                        height={85}
                                        width={85}
                                        colors={['#ffca82', '#ff0000']}
                                        colorBy="index"
                                        enableRadialLabels={false}
                                        enableSlicesLabels={false}
                                        enableArcLinkLabels={false}
                                        enableArcLabels={false}
                                        tooltip={({datum: {id, data}}) => (
                                            <div
                                                style={{
                                                    fontSize: 12,
                                                    padding: 8,
                                                    borderWidth: 1,
                                                    borderColor: '#ccc',
                                                    borderStyle: 'solid',
                                                    background: 'rgb(222 217 217)',
                                                    marginLeft: 'calc(50% + 10px)',
                                                    borderRadius: '4px',
                                                    position: 'absolute',
                                                    width: 156,
                                                    marginTop: -24,
                                                }}
                                            >
                                                {<strong>
                                                    {data.data}
                                                </strong>}&nbsp;
                                                <span>Returns {id} </span>
                                            </div>
                                        )}
                                    />
                                    : <ResponsivePie
                                        data={placeHolderData}
                                        margin={{top: 0}}
                                        innerRadius={0.8}
                                        padAngle={1}
                                        colors={['#f0f0f0']}
                                        colorBy="index"
                                        height={85}
                                        width={85}
                                        enableRadialLabels={false}
                                        enableSlicesLabels={false}
                                        enableArcLabels={false}
                                        enableArcLinkLabels={false}
                                        isInteractive={false}
                                    />
                                }
                            </div>
                            <div className="dataGraph">
                                <div className="ka-col">
                                    <div className="ka-row">
                                        <div style={returnsTotalPrice === 0 ? {color: 'black'} : {color: 'red'}} className={!loading ? 'data-container ' + (activeItem === 2 ? 'active' : '') : 'data-container skeleton past-due'} onClick = {() => {
                                            selectActionItem(rPastDueItems, 'Returns', 'Past Due');
                                            setActiveItem(2);
                                        }}>
                                            {!loading && (<KeyActionsToolTip
                                                tooltipText="Dollar amount of Returns Past Due"
                                            >
                                                {formatMoney(returnsTotalPrice).slice(0, -3)}
                                            </KeyActionsToolTip>)}
                                            <div className={!loading ? 'data-label' : 'data-label skeleton'}>
                                                Past Due
                                            </div>
                                        </div>
                                        <div className={!loading ? 'data-container ' + (activeItem === 3 ? 'active' : '') : 'data-container skeleton due-soon'} onClick={() => {
                                            selectActionItem(rDueSoonItems, 'Returns', 'Due in 2 days');
                                            setActiveItem(3);
                                        }}>
                                            {!loading && (<KeyActionsToolTip
                                                tooltipText="Dollar amount of Returns Due in Next 2 days"
                                            >
                                                {formatMoney(returnsDueSoon).slice(0, -3)}
                                            </KeyActionsToolTip>)}
                                            <div className={!loading ? 'data-label' : 'data-label skeleton'}>
                                                Due Next 2 Days
                                            </div>
                                        </div>
                                    </div>
                                    <div className={!loading ? 'section-id' : 'section-id skeleton'}>
                                        <img className={!loading ? 'section-icn' : 'section-icn skeleton'} src={require('/images/dashboard/icn_returns.png')} />
                                        Returns
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tile tile-border center">
                            <div className="pie">
                                {(criticalApproval || approvalOvernight) > 0 ?
                                    <ResponsivePie
                                        data={approvalData}
                                        margin={{top: 0}}
                                        innerRadius={0.8}
                                        padAngle={1}
                                        height={85}
                                        width={85}
                                        colors={['#ffca82', '#ff0000']}
                                        colorBy="index"
                                        enableRadialLabels={false}
                                        enableSlicesLabels={false}
                                        enableArcLabels={false}
                                        enableArcLinkLabels={false}
                                        tooltip={({datum: {id, data}}) => (
                                            <div
                                                style={{
                                                    fontSize: 12,
                                                    padding: 8,
                                                    borderWidth: 1,
                                                    borderColor: '#ccc',
                                                    borderStyle: 'solid',
                                                    background: 'rgb(222 217 217)',
                                                    marginLeft: 'calc(50% + 10px)',
                                                    borderRadius: '4px',
                                                    position: 'absolute',
                                                    width: 156,
                                                    marginTop: -24,
                                                }}
                                            >
                                                {<strong>
                                                    {data.data}&nbsp;
                                                </strong>}
                                                <span>Approvals {id}</span>
                                            </div>
                                        )}
                                    />
                                    : <ResponsivePie
                                        data={placeHolderData}
                                        margin={{top: 0}}
                                        innerRadius={0.8}
                                        padAngle={1}
                                        colors={['#f0f0f0']}
                                        colorBy="index"
                                        height={85}
                                        width={85}
                                        enableRadialLabels={false}
                                        enableSlicesLabels={false}
                                        enableArcLabels={false}
                                        enableArcLinkLabels={false}
                                        isInteractive={false}
                                    />
                                }
                            </div>
                            <div className="dataGraph">
                                <div className="ka-col">
                                    <div className="ka-row">
                                        <div style={approvalOvernight === 0 ? {color: 'black'} : {color: 'red'}} className={!loading ? 'data-container ' + (activeItem === 4 ? 'active' : '') : 'data-container skeleton past-due'} onClick={() => {
                                            selectActionItem(aPastCutoffItems, 'Approvals', 'Overnight');
                                            setActiveItem(4);
                                        }}>
                                            {!loading && (<KeyActionsToolTip
                                                tooltipText="Count of Overnight activities awaiting approval"
                                            >
                                                {approvalOvernight}
                                            </KeyActionsToolTip>)}
                                            <div className={!loading ? 'data-label' : 'data-label skeleton'}>
                                                Overnight
                                            </div>
                                        </div>
                                        <div className={!loading ? 'data-container ' + (activeItem === 5 ? 'active' : '') : 'data-container skeleton due-soon'} onClick={() => {
                                            selectActionItem(aCriticalDownItems, 'Approvals', 'Priority');
                                            setActiveItem(5);
                                        }}>
                                            {!loading && (<KeyActionsToolTip
                                                tooltipText="Count of Priority (2nd and 3rd day shipment) activities awaiting approval"
                                            >
                                                {criticalApproval}
                                            </KeyActionsToolTip>)}
                                            <div className={!loading ? 'data-label' : 'data-label skeleton'}>
                                                Priority
                                            </div>
                                        </div>
                                    </div>
                                    <div className={!loading ? 'section-id' : 'section-id skeleton'}>
                                        <img className={!loading ? 'section-icn' : 'section-icn skeleton'} src={require('/images/dashboard/icn_approvals.png')} />
                                        Approvals
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tile small left">
                            <div className="dataGraph small">
                                <div className="ka-col">
                                    <div className="ka-row">
                                        <div className={!loading ? 'data-container ' + (activeItem === 6 ? 'active' : '') : 'data-container skeleton due-soon'} onClick={() => {
                                            selectActionItem(overnightOntimeItems, 'Overnight', 'On time');
                                            setActiveItem(6);
                                        }}>
                                            {!loading && (<KeyActionsToolTip
                                                tooltipText="Count of Overnight activities scheduled to arrive on-time"
                                            >
                                                {onTimeOrders}
                                            </KeyActionsToolTip>)}
                                            <div className={!loading ? 'data-label' : 'data-label skeleton'}>
                                            On Time
                                            </div>
                                        </div>
                                        <div style={overnightDelayed === 0 ? {color: 'black'} : {color: 'red'}} className={!loading ? 'data-container ' + (activeItem === 7 ? 'active' : '') : 'data-container skeleton due-soon'} onClick={() => {
                                            selectActionItem(overnightDelayedItems, 'Overnight', 'Delayed');
                                            setActiveItem(7);
                                        }}>
                                            {!loading && (<KeyActionsToolTip
                                                tooltipText="Count of Overnight activities past scheduled arrival time (excludes backorders)"
                                            >
                                                {overnightDelayed}
                                            </KeyActionsToolTip>)}
                                            <div className={!loading ? 'data-label' : 'data-label skeleton'}>
                                            Delayed
                                            </div>
                                        </div>
                                    </div>
                                    <div className={!loading ? 'section-id' : 'section-id skeleton'}>
                                        <img className={!loading ? 'section-icn' : 'section-icn skeleton'} src={require('/images/dashboard/icn_overnight.png')} />
                                        Overnight
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tile small right">
                            <div className="dataGraph small">
                                <div className="ka-col">
                                    <div className="ka-row">
                                        <div style={{color: 'black'}} className={!loading ? 'data-container-chd ' + (activeItem === 8 ? 'active' : '') : 'data-container-chd skeleton past-due'} onClick={() => {
                                            selectActionItem(criticalItemsOntime, 'Critical Hard Down', 'On time');
                                            setActiveItem(8);
                                        }}>
                                            {!loading && (<KeyActionsToolTip
                                                direction="right"
                                                tooltipText="Count of Critical Hard Down activities scheduled to arrive on time"
                                            >
                                                {criticalOntime}
                                            </KeyActionsToolTip>)}
                                            <div className={!loading ? 'data-label' : 'data-label skeleton'}>
                                            On Time
                                            </div>
                                        </div>
                                        <div style={criticalDelayed === 0 ? {color: 'black'} : {color: 'red'}} className={!loading ? 'data-container-chd ' + (activeItem === 9 ? 'active' : '') : 'data-container-chd skeleton past-due'} onClick={() => {
                                            selectActionItem(criticalItemsDelayed, 'Critical Hard Down', 'Delayed');
                                            setActiveItem(9);
                                        }}>
                                            {!loading && (<KeyActionsToolTip
                                                direction="right"
                                                tooltipText="Count of Critical Hard Down activities past scheduled arrival time (excludes backorders)"
                                            >
                                                {criticalDelayed}
                                            </KeyActionsToolTip>)}
                                            <div className={!loading ? 'data-label' : 'data-label skeleton'}>
                                            Delayed
                                            </div>
                                        </div>
                                    </div>
                                    <div className={!loading ? 'section-id' : 'section-id skeleton'}>
                                        <img className={!loading ? 'section-icn' : 'section-icn skeleton'} src={require('/images/dashboard/icn_critical-hard-down.png')} />
                                        Critical Hard Down
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

KeyActions.propTypes = {
}

export default KeyActions;
