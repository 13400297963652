import axios from 'axios';
import React, {useState} from 'react';
import styled from 'styled-components';
import {FormularyFacet, FormularyFilterDefinitionsModal} from '../';

const Container = styled.div`
   width: 390px;
   display: inline-block;
   vertical-align: top;
   padding-right: 30px;
   padding-bottom: 15px
   padding-top: 10px;
`;

const LabelWrapper = styled.div`
margin-bottom: 24px;
`;

const FilterLabel = styled.span`
    color: rgb(0, 0, 0);
font-size: 24px;
font-weight: 300;
letter-spacing: 0px;
line-height: 25px;
padding-right: 8px;
`;

const FilterPillWrapper = styled.div`
    margin-bottom: 20px;

    .pill-label {
        font-size: 16px;
        font-weight: 700;        
        color: rgb(0,0,0);    
        margin-bottom: 10px;    

        .clear-pills {
            color: #FF0000;
            font-weight: 400;
            cursor: pointer;
            margin-left: 10px;
            text-decoration: underline;
        }        
    }
`

const ValueDefinitions = styled.span`
    color: rgb(119, 119, 119);
    margin-top: 24px;
    text-align: center;

    .icon {
        border: 1px solid;
        border-radius: 50%;
        padding: 0px 5px;
        margin-right: 5px;
        font-size: 12px;
        font-weight: 600;
    }

    .definition-link {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const ShowAllFilters = styled.div`
    text-decoration: underline;
    margin-top: 20px;
    color: rgb(119, 119, 119);
    cursor: pointer;
`;

const Pill = styled.div`    
    background-color: #f1f1f1;
    font-weight: 700;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;
    display: inline-block;
    margin-right: 5px;

    span{
    display: inline-block;

    &:nth-of-type(1){
        padding-right: 15px;
    }
    &:nth-of-type(2){
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 12px;
        cursor: pointer;
        position: absolute;
        right: 8px;
    }
    }
`;

export function FormularyFacets({facets, selectedFacets, onChange, onClearFilters}) {  
    const [showAllFilters, setShowAllFilters] = useState(false);
    const [showFilterDefinitionsModal, setShowFilterDefinitionsModal] = useState(false);

    let validFacets = facets && facets.length > 0 ? facets.filter(f => f.results.length > 0) : [];
    let displayFacets = showAllFilters ? validFacets : _.take(validFacets, 5);
    
    const onShowFilterDefinitionModal = () => {
        setShowFilterDefinitionsModal(true);
        axios.post(`/FormularyService/api/v1/log/99/Visual Formulary`, [{name: 'VF Filter Defn Modal', value: 'clicked'}]);        
    }

    return (
        <Container>
            <LabelWrapper>
                <FilterLabel>Filter</FilterLabel>
                <ValueDefinitions onClick={onShowFilterDefinitionModal}>
                    <span className="icon">i</span>
                    <span className="definition-link">Definitions</span>
                </ValueDefinitions>
            </LabelWrapper>
            {selectedFacets.length > 0 && 
                <FilterPillWrapper>
                    <div className="pill-label">Search Terms <span className="clear-pills" onClick={onClearFilters}>Clear All</span></div>
                    {selectedFacets.map((p, i) => 
                        (<Pill key={i}>
                            <span dangerouslySetInnerHTML={{__html: p.range}} />
                            <span>
                                <i className="fa fa-remove" onClick={() => onChange(_.merge(p, {isSelected: false}))} />
                            </span>
                        </Pill>))}
                </FilterPillWrapper>
            }
            {displayFacets.map((f, i) => <FormularyFacet facet={f} key={`form-facet-${i}`} onChange={onChange} />)}  
            {!showAllFilters && validFacets.length > 5 && <ShowAllFilters onClick={() => setShowAllFilters(true)}>Show All Filters</ShowAllFilters>}   
            {showFilterDefinitionsModal && <FormularyFilterDefinitionsModal onClose={() => setShowFilterDefinitionsModal(false)} /> }    
        </Container>
    );
}
