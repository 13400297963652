import axios from 'axios';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import * as UserStore from 'stores/User';
import {QuantitySelector, TextField, Checkbox, FileUploader, Popup, Dropdown, RadioButton} from '@partssourceinc/react-ui-core';
import {formatMoney} from 'utility';
import $ from 'jquery';
import AssetInformationForm, {AssetFormType} from 'components/AssetInformationForm';
import 'less/returnorderform.less';
import OrderLineHeader from './OrderLineHeader';
import {RETURN_CONFIRMATION_MSG} from '../enums'

@withRouter
@connect((state) => ({network: state.network, user: state.user}))
export default class ReturnOrderForm extends React.Component {
    static propTypes = {
        user: UserStore.StateShape,
        label: PropTypes.string,
        onCancel: PropTypes.func.isRequired,
        orderLine: PropTypes.any,
    };
    
    constructor(props) {
        super(props);
        this.state = {
            orderLine: props.orderLine,
            showErrors: false,   
            showReturnOrderForm: true,
            showConfirmation: false,          
            returnRequest: {},
            notes: '',
            selectedReason: {},
            showReturnDialog: true,
            showReturnQtyWarningDialog: false,
            showSelectorErrors: false,
            reqFields: {},
            shippingPreferences: [
                {value: 'UPS', text: 'UPS'}, 
                {value: 'FedEx', text: 'FedEx'},
            ],
            returnReasons: [
                {
                    value: '1',
                    text: 'Wrong Part Received',
                    replace: false,
                    photoReq: false,
                },
                {
                    value: '2',
                    text: 'Part Not Needed',
                    replace: false,
                    photoReq: false,
                },
                {
                    value: '3',
                    text: 'Failed Under Warranty',
                    replace: true,
                    photoReq: false,
                },
                {
                    value: '6',
                    text: 'Received Extra Quantity',
                    replace: false,
                    photoReq: false,
                },
                {
                    value: '7',
                    text: 'Recall by Manufacturer or FDA',
                    replace: false,
                    photoReq: false,
                },
                {
                    value: '8',
                    text: 'Returning Loaner',
                    replace: false,
                    photoReq: false,
                },
                {
                    value: '26',
                    text: 'Poor Quality',
                    replace: false,
                    photoReq: false,
                },
                {
                    value: '21',
                    text: 'Hero Kit',
                    replace: false,
                    photoReq: false,
                },
                {
                    value: '22',
                    text: 'DOA',
                    replace: true,
                    photoReq: false,
                },
                {
                    value: '27',
                    text: 'Ordered Wrong Part',
                    replace: false,
                    photoReq: false,
                },
                {
                    value: '51',
                    text: 'Shipping Issue',
                    replace: true,
                    photoReq: true,
                },
            ],
            isFileSelected: false,
            fileDisplay: '',
            files: [],
            uploadFile: {
                name: '',
                file: null,
            },
            errorMessage: '',
            attachments: [],
        };
    }
   
    componentWillMount() {
        const {orderLine, returnRequest} = this.state;
        const {network: {tokenInfo}, user: {info: {email}}} = this.props;
        returnRequest.partNumber = orderLine.partNumber;
        returnRequest.lineItemId = orderLine.lineItemId;
        returnRequest.requestorId = tokenInfo.userId;
        returnRequest.companyId = orderLine.facilityId;
        returnRequest.exchangeQuantity = orderLine.quantity;
        returnRequest.returnQuantity = 1;
        returnRequest.comments = '';
        returnRequest.customerPhone = '';
        returnRequest.notifyEmailAddress = email;
        returnRequest.returnValueProperties = [];
        returnRequest.shippingCarrier = 'UPS';
        returnRequest.attachments = [];
        returnRequest.wantsLikeReplacement = false;

        let arg = {
            CustomerId: returnRequest.companyId,
            RequestorId: returnRequest.requestorId,
            VendorId: orderLine.vendorId,
            PurchaseChoice: orderLine.purchaseChoice,
            FieldUsage: 4,
            PartNumber: returnRequest.partNumber,
        }

        axios.post('/ShoppingService/api/v1/lineItems/requredfields', arg)
            .then(x => {                
                if (x.data.fields && x.data.fields.length > 0)                       
                    this.setState({reqFields: Array.from(x.data.fields)});
            });

        if (orderLine.fieldValues != null) {
            let costCenterField = orderLine.fieldValues.filter(item => item.prompt === 'Cost Center');                
            if (costCenterField.length > 0)
                returnRequest.costCenter = costCenterField[0].value;
            
            let equipmentField = orderLine.fieldValues.filter(item => item.prompt === 'Equipment Serial #');                            
            if (equipmentField.length > 0)
                returnRequest.equipmentSerial = equipmentField[0].value;

            let assetField = orderLine.fieldValues.filter(item => item.prompt === 'Asset ID');                            
            if (assetField.length > 0)
                returnRequest.assetId = assetField[0].value;

            let accountField = orderLine.fieldValues.filter(item => item.prompt === 'Account');                            
            if (accountField.length > 0)
                returnRequest.account = accountField[0].value;

            let workOrderField = orderLine.fieldValues.filter(item => item.prompt === 'Work Order');
            if (workOrderField.length > 0)
                returnRequest.workOrder = workOrderField[0].value;
        } else {
            if (orderLine.workOrderNumber != null)
                returnRequest.workOrder = orderLine.workOrderNumber;
            if (orderLine.assetId != null)
                returnRequest.assetId = orderLine.assetId;
            if (orderLine.costCenter != null)
                returnRequest.costCenter = orderLine.costCenter;
            if (orderLine.account != null)
                returnRequest.account = orderLine.account;
            if (orderLine.equipmentSerialNumber != null)
                returnRequest.equipmentSerial = orderLine.equipmentSerialNumber;
        }

        this.setState({returnRequest});        
    }

    changeReturnReason(value) {
        const {returnReasons} = this.state;
        let returnReason = returnReasons.filter(item => item.value === value);
        let selectedReason = returnReason[0];
        let {returnRequest, errorMessage} = this.state;
        returnRequest.wantsLikeReplacement = selectedReason.replace;
        errorMessage = (!selectedReason.photoReq) ? '' : errorMessage;
        this.setState({selectedReason, returnRequest, errorMessage});             
    }
    
    updateReturnRequest = (event, data) => {
        const {returnRequest, orderLine} = this.state;
        if (data.value > orderLine.quantity) {
            this.setState({showSelectorErrors: true})
        } else {
            returnRequest[data.id] = data.value;
            this.setState({returnRequest, showSelectorErrors: false});
        }
    }

    updateReturnQuantity = (event, data) => {
        const {returnRequest, orderLine} = this.state;
        returnRequest.returnQuantity = data.value;
        if (returnRequest.returnQuantity > orderLine.quantity) {
            this.setState({showSelectorErrors: true})
        } else {
            this.setState({returnRequest, showSelectorErrors: false});
        }
    }
    
    updateReturnRequestReplacePart(element) {
        const {returnRequest} = this.state;
        returnRequest.wantsLikeReplacement = element.checked;
        this.setState({returnRequest});
    }
    
    updateReturnValueProperties(fieldsData) {
        const {returnRequest} = this.state;
        let updatedReturnValueProperties = [];

        for (let i = 0; i < fieldsData.length; i++) {
            let fieldData = fieldsData[i];

            if (fieldData.value && fieldData.value.length && fieldData.value.length > 0) {
                let fieldValue = fieldData.fieldUid + '|' + fieldData.prompt + '|' + fieldData.value;
                updatedReturnValueProperties.push(fieldValue);
            }
        }

        returnRequest.returnValueProperties = updatedReturnValueProperties;
        this.setState({returnRequest});
    }
 
    updateShippingPreference = (val) => {
        const {returnRequest} = this.state;
        returnRequest.shippingCarrier = val;

        this.setState({returnRequest});
    }
    
    handleOnReturnConfirm() {
        const {selectedReason, attachments, showErrors, returnRequest,showSelectorErrors} = this.state;
        
        let areAllRequiredFieldsEntered = this.checkAllRequiredFieldsEntered(returnRequest.returnValueProperties);

        if (returnRequest.returnQuantity === '' ||
            returnRequest.comments === '' || showErrors ||
            showSelectorErrors ||
            !selectedReason.value ||
            !areAllRequiredFieldsEntered) {
            this.setState({showErrors: true});
            return;
        }
        if (returnRequest.returnQuantity > returnRequest.exchangeQuantity) {
            this.setState({showReturnQtyWarningDialog: true});            
            return;
        }
        if (selectedReason.photoReq && attachments.length === 0) {
            this.setState({errorMessage: 'You must supply photos explaining the reason for this return.'});
            return;
        }
        returnRequest.returnReason = selectedReason.text + ' (' + selectedReason.value + ')';
        returnRequest.attachments = attachments;

        axios.post('/ShoppingService/api/v1/returns/initiate', returnRequest)
            .then(() => {            
                this.setState({showReturnOrderForm: false, showConfirmation: true});
            });
    }

    checkAllRequiredFieldsEntered(enteredFields) {
        const {reqFields} = this.state;

        let reqFieldsUids = reqFields.filter(x => x.isRequired).map(x => x.fieldUid);

        if (enteredFields.length < reqFieldsUids.length) {
            return false;
        } else {
            for (let i = 0; i < reqFieldsUids.length; i++) {
                let fieldUid = reqFieldsUids[i];
                let reqFieldEntered = false;

                for (let i = 0; i < enteredFields.length; i++) {
                    let currEnteredField = enteredFields[i];
        
                    if (currEnteredField.startsWith(fieldUid)) {
                        reqFieldEntered = true;
                        break;
                    }
                }

                if (!reqFieldEntered) {
                    return false;
                }
            }
        }

        return true;
    }

    validatePhoneNumber(e, data) {
        const {returnRequest} = this.state;
        const number = e && e.target && e.target.value;

        if (!number) {
            this.setState({showErrors: false});
            return;
        }
        let ptrn = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
        if (number.match(ptrn)) {

            let fnumber = ('' + number).replace(/\D/g, '');
            if (fnumber.length > 11 || fnumber.length < 10) {
                this.setState({showErrors: true}); return;
            } else {
                returnRequest.customerPhone = data.value;
                this.setState({returnRequest, showErrors: false});
             
            }

        } else {
            this.setState({showErrors: true});
            return;
        }

    }

    processFiles(e) {
        e.preventDefault();
        let files = e.target.files;
    
        let cleanup = false;
        if (!files) {
            this.setState({
                errorMessage: 'Your attachment was not uploaded. Please try again.',
            });
            cleanup = true;
        }

        if (files[0].size > 2097152) {
            this.setState({
                errorMessage: 'File size exceeds the 2MB limit.',
            });
            cleanup = true;
        }

        const {attachments} = this.state;
        let file = files[0];

        let att = attachments.filter(item => item.fileName === file.name);

        if (att.length > 0) {
            this.setState({
                errorMessage: 'File with the same name is already uploaded.',
            });
            cleanup = true;
        }

        if (cleanup) {      
            return;
        } else {
            this.setState({errorMessage: ''});
        } 
    
        this.setState({
            isFileSelected: true,
            fileDisplay: $(e.target)
                .val()
                .split('/')
                .pop()
                .split('\\')
                .pop(),
        });
        let reader = new FileReader();
    
        reader.onload = upload => {
            let newFile = {};
            newFile.fileName = file.name;
            newFile.mimeType = file.type;
            newFile.content = upload.target.result;      
            attachments.push(newFile);
      
            this.setState({attachments});
        };
    
        reader.readAsDataURL(file);
    }

    removeAttachment(attachment) {
        const {attachments} = this.state;   
        this.setState({attachments: attachments.filter(item => item.fileName !== attachment.fileName)});
    }

    renderAttachment(attachment) {        
        return (<div className="row" style={{paddingLeft: '20px'}}>
            <div className="col-md-8">{attachment.fileName}</div>
            <div className="col-md-4">
                <div onClick={() => this.removeAttachment(attachment)} className="pull-right not-registered removeLinkBlue">
                    &nbsp;[Remove]&nbsp;
                </div>
            </div>        
        </div>);
    }

    handleOnCancel() {
        const {onCancel} = this.props;
        this.setState({showReturnOrderForm: true});        
        onCancel();
    }
      
    handleWarningOnCancel() {
        this.setState({showReturnQtyWarningDialog: false});
    }

    render() {
        const {
            showErrors, 
            selectedReason,
            showReturnOrderForm,
            showConfirmation,
            showReturnQtyWarningDialog, 
            attachments,
            errorMessage,
            showSelectorErrors,
            returnRequest, 
            returnReasons,
            shippingPreferences,
            orderLine,
            fileDisplay,
            isFileSelected,
            reqFields,
        } = this.state;
        const returnQuantity = returnRequest.returnQuantity;         
        const comments = returnRequest.comments;
        const shippingPreference = returnRequest.shippingCarrier;
        const notifyEmailAddress = returnRequest.notifyEmailAddress;
        const showReplaceCheckBox = selectedReason ? selectedReason.replace : false;
        let firstNote = '';
        if ((orderLine.notes || []).length > 0)
            firstNote = orderLine.notes[0].text;
        
        return (<div>
            {showReturnOrderForm && <Popup
                confirmText="INITIATE RETURN"
                cancelText="Cancel"
                show={true}
                disabled={true}
                onConfirm={::this.handleOnReturnConfirm}
                onCancel = {:: this.handleOnCancel}
                onClose = {:: this.handleOnCancel}
                className="return-order-form">
                <div className="return-order-details container-fluid">
                    <h1>Order # {orderLine.lineItemId}</h1>
                    <OrderLineHeader 
                        item = {orderLine}
                        updateReturnRequest={this.updateReturnQuantity}
                        selectedValue={returnQuantity} 
                        showErrors={showSelectorErrors || returnQuantity === ''} />
                   
                    <div className="row ">
                        <div className="col-md-12 separator" />
                    </div>
                    <div className="row ">
                        <div className="col-md-6 col-xs-12" >
                            <Dropdown id="selectedReason" 
                                options={returnReasons} 
                                selectedValue={selectedReason.value}
                                onChange={e => this.changeReturnReason(event.target.value)}
                                name="returnreasons" label={'Return Reason'} 
                                showErrorMessage={showErrors && !selectedReason.value}                      
                                required={true}
                                tabIndex={0} 
                            />
                            {showReplaceCheckBox && <Checkbox label="Replace returned part?" id="wantsLikeReplacement" checked={returnRequest.wantsLikeReplacement} onChange={:: this.updateReturnRequestReplacePart} />}
                            <TextField id="comments" label="Notes" multiLine={true} rows={4} tabIndex={0} required={true} showErrorMessage={showErrors && comments === ''} onBlur={this.updateReturnRequest} />
                            <TextField id="customerPhone" placeholder="123-123-1234" label="Phone" tabIndex={0} required={true} onBlur={::this.validatePhoneNumber} showErrorMessage={showErrors} />
                            {notifyEmailAddress && <TextField id="notifyEmailAddress" label="Email" tabIndex={0} required={true} text={notifyEmailAddress} value={notifyEmailAddress} onBlur={this.updateReturnRequest} />}

                            <Dropdown 
                                id="shippingPrefferences" 
                                options={shippingPreferences} 
                                selectedValue={shippingPreference}
                                onChange={e => this.updateShippingPreference(e.target.value)}
                                name="shippingPrefferences" 
                                label={'Shipping Preferences'} 
                                showErrorMessage={showErrors && !shippingPreference}                      
                                required={true}
                                tabIndex={0} 
                            />
                            <div>&nbsp;</div>                             
                            <FileUploader
                                label="CHOOSE FILE"
                                restriction="Max file size 2MB"
                                infoCenter={false}
                                photosTab={false}
                                isFileSelected={isFileSelected}
                                fileDisplay={fileDisplay}                        
                                onChange={::this.processFiles}
                                showUpload={true}
                            />                            
                            {attachments.map(item => this.renderAttachment(item))}
                            <br />
                            <span className="errorMessage">{errorMessage}</span> 
                        </div>
                        <div className="col-md-6">
                            {reqFields.length > 0 && <AssetInformationForm
                                fields={reqFields}
                                formType={AssetFormType.Both}
                                readOnly={false}
                                showRequester={false}
                                hideSaveButton={true}
                                allowMissingFields={false}
                                displayInline={true}
                                hideTitle={true}
                                showErrors={true}
                                priority={false}                                
                                onFieldUpdate={::this.updateReturnValueProperties}
                                preventCriticalCheckbox={true}
                            />}
                        </div>
                                   
                    </div>
                         
                </div>        
            </Popup> } 

            {showConfirmation && <Popup
                confirmText="OK"              
                show={showConfirmation}
                onConfirm= {:: this.handleOnCancel}
                onCancel = {:: this.handleOnCancel}
                onClose = {:: this.handleOnCancel}
                className="asset-information-dialog"
            >
                <div className="asset-information-dialog">
                    <span>{RETURN_CONFIRMATION_MSG}</span>            
                </div>
            </Popup>}  

            {showReturnQtyWarningDialog && <Popup
                confirmText="OK"              
                show={showReturnQtyWarningDialog}
                onConfirm= {:: this.handleWarningOnCancel}
                onCancel = {:: this.handleWarningOnCancel}
                onClose = {:: this.handleWarningOnCancel}
                className="asset-information-dialog"
            >
                <div className="asset-information-dialog">
                    <span>You cannot return more quantity than the ordered: {returnRequest.exchangeQuantity}.</span>            
                </div>
            </Popup>}
        </div>);                            
    }
}

