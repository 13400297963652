import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Title = styled.div`
    line-height: 24px;
    font-size: 16px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ::after {
        content: "\f104";
        font-family: FontAwesome;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-decoration: none;
        float: right;
        margin-left: 5px;
        cursor: pointer;
    }

    &.active::after {
        content: "\f107";
    }
`;

const Collapsible = ({open, title, children}) => {
    const [isOpen, setIsOpen] = useState(open);

    const handleFilterOpening = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div>
            <Title className={isOpen ? 'active' : ''} onClick={handleFilterOpening}>
                {title}
            </Title>

            <div>
                <div>{isOpen && <div>{
                    children
                }</div>}</div>
            </div>
        </div>
    );
};

Collapsible.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

export default Collapsible;
