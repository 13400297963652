import React from 'react';
import styled from 'styled-components';
import {SwitchCheckbox} from '@partssourceinc/react-ui-core';
import {formatMoney} from 'utility';
import {NavLink} from 'react-router-dom';

const Container = styled.div`
min-height: 480px !important;
height: 480px !important;
`
const Header = styled.div`
  .edit {
    color: rgb(119, 119, 119);
    text-decoration: underline;
    cursor: pointer;
  }

  .auto-refresh-toggle {
    float: right;
  }
`;

const RuleName = styled.div`
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const Detail = styled.div`
  font-size: 16px;
  margin-bottom: 8px;

  span {
    font-weight: 600;
  }

  &.limit {
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  a {
    margin-left: 5px !important;
    color: #005da6 !important;
    font-weight: 600 !important;
    cursor: pointer;
    text-decoration: none !important;
  }
`;

const ScoreImage = styled.img`
  width: 15px;
  position: relative;
  top: -1px;
  margin-left: 5px;
`;

const ScoreDescription = styled.span`
  padding-left: 6px;
  padding-right: 2px;
  font-size: 14px;
  font-weight: 400 !important;
`;

export function FormularyCard({data, onSwitch, onSelect}) {    
    const getFormularyUI = (formularyScoreRanking, formularyScore) => {
        switch (formularyScoreRanking) {
            case 1:
                return <React.Fragment><ScoreImage src="/images/formulary/icn_best.png" /><ScoreDescription>BEST:</ScoreDescription><span>{formularyScore.toFixed(2)}</span></React.Fragment>
            case 2:
                return <React.Fragment><ScoreImage src="/images/formulary/icn_very-good.png" /><ScoreDescription>VERY GOOD:</ScoreDescription><span>{formularyScore.toFixed(2)}</span></React.Fragment>
            case 3:
                return <React.Fragment><ScoreImage src="/images/formulary/icn_moderate.png" /><ScoreDescription>MODERATE:</ScoreDescription><span>{formularyScore.toFixed(2)}</span></React.Fragment>
            case 4:
                return <React.Fragment><ScoreImage src="/images/formulary/icn_fair.png" /><ScoreDescription>FAIR:</ScoreDescription><span>{formularyScore.toFixed(2)}</span></React.Fragment>
            case 5:
                return <React.Fragment><ScoreImage src="/images/formulary/Poor.png" /><ScoreDescription>POOR:</ScoreDescription><span>{formularyScore.toFixed(2)}</span></React.Fragment>                          
            default:
                return '';
        }
    }
    return (
        <Container className="card-view">
            <Header>
                <span className="edit" onClick={onSelect}>View/Edit</span>
                <SwitchCheckbox
                    label=""
                    className="auto-refresh-toggle"
                    value={data.isActive}
                    onChange={(checked) => onSwitch(data, checked)} />
            </Header>
            <RuleName>{data.name}</RuleName>
            <Detail>Rule Ranking: <span>{data.sortOrder}</span></Detail>
            {data.manufacturerDisplay && <Detail>OEM: <span>{data.manufacturerDisplay}</span></Detail>}
            {data.modalityDisplay && <Detail>Modality: <span>{data.modalityDisplay}</span></Detail>}
            {data.categoryDisplay && <Detail>Category: <span>{data.categoryDisplay}</span></Detail>}
            {data.displayPartNumber && <Detail>Item Detail: 
                {data.detailUrl ? <NavLink className="grid-header-link" to={data.detailUrl} target="_blank">
                    {data.displayPartNumber}
                </NavLink> : <span>{data.displayPartNumber}</span>}
            </Detail>}
            {data.preferredCondition && <Detail>Preferred Condition: <span>{data.preferredCondition}</span></Detail>}
            <Detail className="limit">Facilities: <span>{data.facilityDisplay.replaceAll('<br/>', ' ')}</span></Detail>
            <Detail>Display Type: <span>{data.displayPreference}</span></Detail>
            <Detail>Purchase Requires Approval: <span>{data.requireApproval}</span></Detail>
            <Detail>Display 'Preferred Choice': <span>{data.displayPreferredChoice}</span></Detail>
            {data.reasonForRule && <Detail>Reason for Rule: <span>{data.reasonForRule}</span></Detail>}
            <Detail>Pro forma Savings: <span>{formatMoney(data.proFormaSavings)}</span></Detail>
            {data.formularyScore !== undefined && <Detail>Formulary Score: <span>{getFormularyUI(data.formularyScoreRanking, data.formularyScore)}</span></Detail>}
        </Container>  
    );
}
