import axios from 'axios';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {Button} from '@partssourceinc/react-ui-core';

import 'less/pdfbutton.less';

export default class PDFButton extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        pdfUrl: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        buttonType: PropTypes.oneOf(['Primary', 'Secondary', 'Link']),
        directLink: PropTypes.bool,
    };

    static defaultProps = {
        buttonType: 'Link',
    };

    constructor(props) {
        super(props);

        this.state = {
            loadingPdf: false,
        };
    }

    render() {
        const {buttonType, title} = this.props;
        const {loadingPdf} = this.state;

        switch (buttonType) {
            case 'Primary':
                return <Button loading={loadingPdf} onClick={() => this.openPdf()}>{title}</Button>;
            case 'Secondary':
                return <Button secondary={true} loading={loadingPdf} onClick={() => this.openPdf()}>{title}</Button>;
            case 'Link':
                return (<div className={loadingPdf ? 'pdf-button pdf-button--disabled' : 'pdf-button'} onClick={() => this.openPdf()}>
                    {title}
                    {loadingPdf ? <div className="pdf-button_loader" /> : null}
                </div>);
            case 'MultiLink':
                return (<div className={loadingPdf ? 'pdf-button pdf-button--disabled' : 'pdf-button'} onClick={() => this.openPdfs()}>
                    {title}
                    {loadingPdf ? <div className="pdf-button_loader" /> : null}
                </div>);
        }
    }

    openPdf() {
        const {pdfUrl, fileName, directLink} = this.props;
        this.setState({loadingPdf: true});

        if (!directLink) {
            axios.get(pdfUrl, {responseType: 'arraybuffer', headers: {'Content-Type': 'application/json', 'Accept': 'application/pdf'}}).then(x => {
                const data = x.data;
                const file = new Blob([data], {type: 'application/pdf'});

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    this.setState({loadingPdf: false});
                    window.navigator.msSaveOrOpenBlob(file);
                    return;
                }

                const fileURL = URL.createObjectURL(file);
                let link = document.createElement('a');
                link.type = 'hidden';
                link.href = fileURL;
                link.download = `${fileName}.pdf`;
                document.body.appendChild(link);
                link.click();

                setTimeout(function () {
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(fileURL);
                }, 100);

                this.setState({loadingPdf: false});
            });
        } else {
            let link = document.createElement('a');
            link.type = 'hidden';
            link.href = pdfUrl;
            link.download = `${fileName}.pdf`;
            document.body.appendChild(link);
            link.click();

            setTimeout(function () {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
            }, 100);

            this.setState({loadingPdf: false});
        }
    }

    openPdfs() {
        const {pdfUrl, fileName} = this.props;
        let urls = pdfUrl.split(',');
        let names = fileName.split(',');

        if (urls && urls.length > 0) {
            let counter = 0;
            this.processPdf(urls, names, counter);
        }
    }

    processPdf(urls, names, counter) {
        if (urls[counter] && urls[counter].length > 0) {
            this.setState({loadingPdf: true});
            axios.get(urls[counter], {responseType: 'arraybuffer', headers: {'Content-Type': 'application/json', 'Accept': 'application/pdf'}}).then(x => {
                const data = x.data;
                const file = new Blob([data], {type: 'application/pdf'});

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    this.setState({loadingPdf: false});
                    window.navigator.msSaveOrOpenBlob(file);
                    return;
                }

                const fileURL = URL.createObjectURL(file);
                let link = document.createElement('a');
                link.type = 'hidden';
                link.href = fileURL;
                link.download = `${names[counter]}.pdf`;
                document.body.appendChild(link);
                link.click();

                this.setState({loadingPdf: false});

                setTimeout(function () {
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(fileURL);

                    urls.unshift();

                    if (urls && urls.length > 0) {
                        counter = counter + 1;
                        this.processPdf(urls, names, counter)
                    }

                }.bind(this), 100);
            });
        }
    }
}
