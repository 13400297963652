import axios from 'axios';
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Popup} from '@partssourceinc/react-ui-core';
import {ProductImage} from '@partssourceinc/react-ui-core';
import * as UserStore from 'stores/User';
import {formatMoney} from 'utility';
import {RadioButton} from '@partssourceinc/react-ui-core';

import 'less/buyingOptionsDialog.less';
import {ExchangeAcknolwedgementDialog} from './Settings/ExchangeAcknolwedgementDialog';

@connect((state) => ({user: state.user}))
export default class BuyingOptionsDialog extends Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        imagePath: PropTypes.string,
        user: UserStore.StateShape,
    };

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            selectedValue: '',
            showExchangeAcknowledgement: false,
        }
    }

    componentDidMount() {
        const {options} = this.props;
        this.setState({selectedValue: options.find(x => x.isSelected).psPartNumber || ''});
    }

    onUpdate(verifyExchange) {
        const {options, onSave} = this.props;
        const {selectedValue} = this.state;

        let selectedOption = options.find(x => x.psPartNumber === selectedValue);
 
        if (verifyExchange && selectedOption.purchaseChoiceId === 2) {
            this.setState({showExchangeAcknowledgement: true});
            return;
        } else
            selectedOption.isExchangeTermsChecked = selectedOption.purchaseChoiceId === 2;

        this.setState({saving: true});
        axios.post('/ShoppingService/api/v1/approval/ChangeOption', selectedOption).then(() => {
            onSave();
        });
    }

    onSelectionChange(e) {
        this.setState({selectedValue: e.target.value});
    }

    render () {
        const {saving, selectedValue, showExchangeAcknowledgement} = this.state;
        const {options, imagePath, user: {companyLogo, preferredLogo}, onCancel} = this.props;

        let selectedOption = options.find(x => x.psPartNumber === selectedValue);
        
        let showCompanyLogo = selectedOption && selectedOption?.formularySetting && selectedOption?.formularySetting.showCompanyLogo || selectedOption?.formularySetting && selectedOption.formularySetting?.showCompanyLogo == undefined;

        return (
            <>
                {showExchangeAcknowledgement ? 
                    <ExchangeAcknolwedgementDialog 
                        onCancel={() => this.setState({showExchangeAcknowledgement: false})} 
                        onConfirm={() => this.onUpdate(false)} /> : 
                    <Popup confirmText="Update Item"
                        cancelText="Cancel"
                        show={true}
                        onConfirm={() => this.onUpdate(true)}
                        onCancel={onCancel}
                        className="buying-options-dialog"
                        loading={saving}>
                        <div>
                            <div className="buying-options-title">Other Buying Options</div>
                            {options.map((o, i) =>
                                (<div className="buying-option-item" key={`buying-option-item-${i}`}>
                                    <RadioButton data-ea-ui-link={o.condition} data-ea-link-text="Other Buying Options"
                                        id={`option-${i}`}
                                        checked={o.psPartNumber === selectedValue} 
                                        value={o.psPartNumber}
                                        onChange={(e) => this.onSelectionChange(e)} 
                                        name={`option-${i}`}
                                        style={{'float': 'left'}}
                                    >
                                        <label htmlFor={`option-${i}`}>&nbsp;</label>
                                    </RadioButton>
                                    <div className="product-image"><ProductImage style={{width: '120px'}} url={imagePath} altText="" /></div>
                                    <div className="pInfo">
                                        {o.isFormularyOption && !o.isContractProOption &&
                                    <div className="fomulary-logo">
                                        {showCompanyLogo ? 
                                            (companyLogo ? (<img src={`data:image/jpeg;base64,${companyLogo}`} />) : null)
                                            : null }
                                        <img src={`${preferredLogo}`} style={{width: '125px'}} alt="Preferred" />
                                    </div>}
                                        {o.isContractProOption && <span className="contract-badge"><span>CONTRACT</span></span>}
                                        <div className="description">{o.description}</div>
                                        <div className="info">
                                    Part Number#:<span>{o.partNumber}</span>
                                        </div>
                                        <div className="info">
                                    OEM:<span>{o.oemName}</span>
                                        </div>
                                        <div className="info">
                                    Condition: <span>{o.purchaseChoiceId === 2 && <img src="/images/exchange.svg" alt="Exchange Item" style={{height: '15px', padding: '0 2px'}} />} {o.condition}</span>
                                        </div>
                                        {!o.isContractProOption && <div className="info">
                                    Warranty: <span>{o.warranty}</span>
                                        </div>}
                                        {!o.isContractProOption && <div className="info">
                                    Return Status: <span>{o.returnStatus}</span>
                                        </div>
                                        }
                                        {!o.isContractProOption && o.estimatedLeadTime > 0 &&
                                    <div className="info">
                                        Estimated Lead Time: <span>{o.estimatedLeadTime} Day</span>
                                    </div>}
                                    </div>
                                    <div className="product-price">{formatMoney(o.price)}<span>/{o.uom}</span></div>
                                </div>)
                            )}
                        </div>
                    </Popup>}
            </>
        )
    }
}
