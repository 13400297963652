export const RETURN_CONFIRMATION_MSG = 'We are processing your return and will notify you within 24-48 hours via email. You can also check the status in Exchanges & Returns, found in the My Account menu.'

export const OrderLineItemStatus = {
    Requested: 1,
    Sourced: 3,
    Sourcing: 2,
    Repairing: 12,
    NoSale: 5,
}

export const BasicBIReportOperatorEnum = {
    IN: 'In',
    NOT_IN: 'NotIn',
    ALL: 'All'
}

export const PowerBiReportIdsEnum = {
  QBR: 'df17a0f4-3883-4c83-a913-b3c798a69f76'

}
