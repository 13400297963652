import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Popup, TextFieldTags} from '@partssourceinc/react-ui-core';

const Container = styled.div`

`;

const Title = styled.div`
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 300;
`;

export function FormularyFacilitiesModal({onClose, rule, facilities, onChange}) {  
    const [selectedValue, setSelectedValue] = useState({});

    useEffect(() => {        
        let mappedValues = {};
        rule.facilities.forEach(r => {
            mappedValues[r.id] = r.name 
        });
        setSelectedValue(mappedValues);
    }, []);

    const onFacilityChange = (value) => {
        setSelectedValue(value);
    }

    const onSave = () => {        
        let mappedFacilities = [];
        let keys = Object.keys(selectedValue);
        keys.forEach(k => {
            mappedFacilities.push({id: parseInt(k), name: selectedValue[k]});
        });        

        onChange(mappedFacilities);
    }

    return (
        <Popup 
            className="formulary-facility-modal"
            confirmText="Save"             
            show={true} 
            hideButtons={false}
            onConfirm={onSave}
            onCancel={onClose}>
            <Container>
                <Title>Add/Remove Facilities</Title>
                <TextFieldTags
                    placeHolder={`All Facilities`}
                    value={selectedValue}
                    listItems={facilities}
                    keyField="id"
                    valueField="name"
                    onChange={onFacilityChange}
                    useMultiSelect={true}
                />
            </Container>
        </Popup>
    );
}
