import axios from "axios";
import moment from "moment";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import * as ReactRouterPropTypes from "react-router-prop-types";
import _ from "lodash";
import ApprovalPurchaseButton from "components/ApprovalPurchaseButton";
import { Button, Popup, TextFieldTags} from "@partssourceinc/react-ui-core";
import BuyingOptionsDialog from "components/BuyingOptionsDialog";
import PDFButton from "components/PDFButton";
import { ProductImage } from "@partssourceinc/react-ui-core";
import RejectApprovalDialog from "components/RejectApprovalDialog";
import ReturnOrderForm from "components/ReturnOrderForm";
import SubmitPoForm from "components/SubmitPoForm";
import TrackingNumberEntryDialog from "components/TrackingNumberEntryDialog";
import TrackingPanel from "components/TrackingPanel";
import * as UserStore from "stores/User";
import * as NetworkStore from "stores/Network";
import * as DashboardStore from "stores/Dashboard";
import { classNames, logEvent, formatMoney, getTrackingNumbersUrlsData, getTrackingUrl, getFormattedPhoneNumber } from "utility";
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import {Lg, PhonePortrait, Default, PhoneLandscape} from 'responsive';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import $ from 'jquery';
import 'less/grid/primereact.less';
import 'less/customServerCards.less';

export const CardActionType = {
    Quote: 'Quote',
    AwaitingPO: 'Awaiting PO',
    AwaitingApproval: 'AwaitingApproval',
    AwaitingApprovalReadOnly: 'AwaitingApprovalReadOnly',
    Delivered: 'Delivered',
    OrderHistory: 'OrderHistory',
    Exchange: 'Exchange',
    ServiceEvent: 'ServiceEvent',
    Return: 'Return',
    Repair: 'Repair',
};

export const RequesterViewType = {
    AllRequesters: 'AllRequesters',
    CurrentUser: 'CurrentUser',
};

const ViewType = {
    Card: 'Card',
};
@withRouter
@connect((state) => ({user: state.user, dashboard: state.dashboard, sessionStorageAvailable: state.network.sessionStorageAvailable}), Object.assign({}, UserStore.actionCreators, DashboardStore.actionCreators, NetworkStore.actionCreators))
export class CustomServerCards extends Component {
    static displayName = 'CustomServerCards';
    static propTypes = {
        // Needs to match the default columns property in the user store
        gridName: PropTypes.string,
        gridKeys: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.string,
                fieldName: PropTypes.string,
                value: PropTypes.string,
                tabDisplay: PropTypes.string,
                // cardAction: PropTypes.oneOf(Object.keys.CardActionType),
                height: PropTypes.number,
            })
        ),
        data: PropTypes.array.isRequired,
        columns: PropTypes.array.isRequired,
        label: PropTypes.string.isRequired,
        subLabel: PropTypes.string.isRequired,
        onSelect: PropTypes.func,
        onHandleBundleCandidates: PropTypes.func,
        disableCardView: PropTypes.bool,
        headerLink: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
        }),
        cardAction: PropTypes.oneOf(Object.values(CardActionType)),
        showCheckbox: PropTypes.bool,
        selectionMode: PropTypes.oneOf(['single', 'multiple', 'checkbox']),
        selectionKeyField: PropTypes.string,
        onRefresh: PropTypes.func,
        loadingData: PropTypes.bool,
        hideLegend: PropTypes.bool,
        gridEntityName: PropTypes.string,
        showShipInfo: PropTypes.bool,
        height: PropTypes.number,
        showRequesterToggle: PropTypes.bool,
        onRequesterToggle: PropTypes.func,
        lastCardScroll: PropTypes.func,
        requesterViewType: PropTypes.oneOf(Object.values(RequesterViewType)),
        user: UserStore.StateShape,
        dashboard: DashboardStore.StateShape,
        handleScroll: PropTypes.func,
        ...DashboardStore.ActionShape,
        ...UserStore.ActionShape,
        ...ReactRouterPropTypes,
    };

    static defaultProps = {
        gridKeys: [],
        selectionMode: 'single',
        showRequesterToggle: false,
        requesterViewType: RequesterViewType.CurrentUser,
    };

    constructor(props) {
        super(props);

        this.state = {
            currentTab: null,
            tabCounts: [],
            view: ViewType.Card,
            checkboxItems: [],
            showBuyingOptions: false,
            buyingOptions: [],
            buyingOptionsImage: '',
            showRejectDialog: false,
            showSubmitPoDialog: false,
            rejectItems: {},
            notification: '',
            purchaseLines: [],
            showColumnSelection: false,
            columnSelectionColumns: [],
            columns: _.sortBy(props.user[props.gridName], 'order'),
            requesterViewType: props.requesterViewType || RequesterViewType.CurrentUser,
            showCheckInDialog: false,
            showReturnDialog: false,
            selectedLineItem: {},
            showTrackingNumberDialog: false,
            gridEntityName: props.gridEntityName || 'orders',
            showNudgeConfirmDialog: false,
            showNudgeRequesterConfirmDialog: false,
            showTrackingHistory: false,
            showMissingInvoice: false,
            currentPage: 1,
            selectedPageSize: 10,
            defaultTab: true,
            renderGridMobile: false,
            onOpsV2: location.pathname.toLowerCase() === '/command-center' ? true : false,
            tooltipLineItemId: -1,
            tooltipLocation: {},
            showSearchBox: true,
            showNudgeMenu: false,
            nudgeRequesterList: [],
            selectedNudgeItem: {},
            selectedNudgeRecipients: [],
            maxNudges: 5,
            maxNudgesSent: false,
        };
        this.sendLogTrackShipping = this.sendLogTrackShipping.bind(this);
        -
        axios
            .get('/ShoppingService/api/v1/company/contacts/')
            .then(x => this.setState({nudgeRequesterList: x.data.map(r => ({id: r.contactId, value: r.fullName}))}));

    }

    componentDidMount() {
        const {subLabel, sessionStorageAvailable} = this.props;
        if (sessionStorageAvailable)
            sessionStorage.prevGrid = subLabel;
    }

    componentWillReceiveProps(props) {
        const {columns: currentColumns} = this.props;
        const {columns: stateColumns} = this.state;
        this.setTabCounts(props);

        if ((currentColumns || []).length === 0 && (props.columns || []).length > 0) {
            const columns = [
                ...stateColumns
                    .concat(props.columns)
                    .reduce((m, o) => m.set(o.field, Object.assign(m.get(o.field) || {}, o)), new Map())
                    .values(),
            ];

            this.setState({columns: _.sortBy(columns, 'order'), view: props.view});
        }
    }

    setTabCounts = (props) => {
        const {gridKeys} = props;
        let tabCounts = [];

        for (let i = 0; i < gridKeys.length; i++) {
            let key = gridKeys[i];
            tabCounts.push({id: key.id, count: this.getBadgeCount(key, props)});
        }

        this.setState({tabCounts});
        return tabCounts;
    }

    onCardViewSearch = (searchTerm) => {
        const {onCardSearch} = this.props;
        onCardSearch(searchTerm.target.value);
        this.setState({searchText: searchTerm.target.value});
    }

    onApprove = (item, approveAll) => {
        const {checkboxItems} = this.state;
        const {
            user: {facilities},
            onRefresh,
        } = this.props;

        let request;

        if (approveAll) {
            const facility = facilities.find((x) => Number(x.facilityId) === Number(checkboxItems[0].facilityId));
            request = {
                groupId: facility.groupId,
                companyId: facility.facilityId,
                lineItemIds: checkboxItems.map((x) => x.lineItemId),
            };
        } else {
            const facility = facilities.find((x) => Number(x.facilityId) === Number(item.facilityId));
            request = {
                groupId: facility.groupId,
                companyId: item.facilityId,
                lineItemIds: [item.lineItemId],
            };
        }

        this.setState({saving: true});

        axios.post('/ShoppingService/api/v1/approval/approve', request).then(() => {
            if (approveAll) {
                let notification = checkboxItems.length === 1 ? `(${checkboxItems.length}) item submitted for approval` : `(${checkboxItems.length}) items submitted for approval`;
                this.setState({notification, checkboxItems: []});
            }
            onRefresh();
        });
    }

    onNudgeApprover = (item, isHoldPo) => {
        const {
            user: {facilities},
        } = this.props;
        const {id_ins} = this.state;
        let request;
        const facility = _.find(facilities, (x) => x.facilityName === item.facility);
        request = {
            groupId: facility.groupId,
            companyId: item.facilityId,
            lineItemIds: [item.lineItemId],
            isPoHold: isHoldPo,
        };
        axios.post('/ShoppingService/api/v1/orders/sendNudge', request).then(() => {
            this.setState({showNudgeConfirmDialog: true, selectedLineItem: item});
        });

        let currdate = new Date();
        let createdate = moment(item.created).toDate();

        logEvent('Nudge Approver', {
            'Nudge Approver': item.lineItemId,
            'Ship Priority': item.carrierPriority,
            Age: `${parseInt((currdate - createdate) / (1000 * 60 * 60 * 24))}day`,
            'Time to Cutoff': item.shipCutoffUtc ? `${parseInt((moment(item.shipCutoffUtc).toDate() - currdate) / (1000 * 60) / 60)}hr` : '',
        });
    }

    truncateDescriptionText = (desc) => {
        if (!desc) return desc;

        if (desc.length < 80) {
            return desc;
        } else {
            return desc.substring(0, 80) + ' ...';
        }
    }

    renderShippingForService = (data) => {
        const {
            user: {settings},
        } = this.props;

        const dedicatedPhoneNumber = settings.dedicatedPhoneNumber ? 
            getFormattedPhoneNumber(settings.dedicatedPhoneNumber) : defaultPhoneNumber;

        const status = data.status;
        let text = '';
        let scheduledText = 'Scheduled Completion PENDING';
        let date = '';

        switch (status) {
            case 'Initiated':
                text = 'Awaiting Quote';
                break;
            case 'Open Service':
                text = data.serviceInfo ? data.serviceInfo.serviceAppointmentStatus : '';
                if (data.serviceInfo && data.serviceInfo.arrivalTime) {
                    scheduledText = `Service Specialist Arrival`;
                    date = moment(data.serviceInfo.arrivalTime, 'YYYY-MM-DD-h.mm.ss').format('MM/DD/YY HH:mm A');
                } else scheduledText = 'Service Specialist Arrival - Pending';
                break;
            case 'Quoted':
                text = 'For questions or to cancel this quote, call ';
                break;
            case 'Awaiting PO':
                text = 'Awaiting PO';
                break;
            case 'Awaiting Approval':
                text = 'Awaiting Approval';
                break;
        }

        if (status === 'Quoted') {
            return (
                <div className="col-md-12" style={{height: '42px'}}>
                    <strong style={{fontSize: 16}}>{text}<a style={{textDecoration: 'underline'}} href={'tel:+1' + defaultPhoneNumber}>{dedicatedPhoneNumber}</a></strong>
                </div>
            );
        } else {
            return (
                <ul className="StepProgress">
                    <li className={'StepProgress-item is-next'}>
                        <div className="eta-info">
                            {scheduledText} <strong>{date}</strong>
                        </div>
                    </li>
                    <li className="StepProgress-item is-done current">
                        <div className="order-status">
                            <div className="status">{text || 'Open Service Event'}</div>
                        </div>
                    </li>
                </ul>
            );
        }
    };

    renderShipSection = (data, cardActionType) => {
        if (cardActionType === 'Repair' && data.groupName !== 'ShippedInbound' && data.groupName !== 'Delivered') {
            if (this.state.currentTab === 'initiated' && !data.rgaNumber) return this.renderMissingPaperWorkSection(data, cardActionType);
            else if (this.state.currentTab === 'initiated' && data.rgaNumber && data.dueDate) return this.renderRgaRepairSection(data, cardActionType);
            else return null;
        }

        if (data.groupName === 'Requested' || data.groupName === 'eQuoted') {
            return this.renderQuoteSection(data);
        }

        if (cardActionType === 'Exchange' || cardActionType === 'Return') {
            return this.renderRgaSection(data, cardActionType);
        }

        let eta = 'PENDING';

        if (data.status === 'Ordered' || data.status === 'Back Ordered') {
            eta = 'Scheduled Delivery Date Pending';
        } else if (data.status === 'Shipped' || data.groupName === 'ShippedInbound') {
            if (data.estimatedDelivery) {
                let date = moment(data.estimatedDelivery).format('MM/DD');
                let time = moment(data.estimatedDelivery).format('hh:mma');
                eta = `Scheduled Delivery ${date}, ${time === '12:00am' ? 'End of Day' : time}`;
            } else {
                eta = 'Scheduled Delivery Date Pending';
            }
        }

        let isDelivered = data.status === 'Delivered' || data.groupName === 'Delivered';

        let shipLine2;

        if (data.status === 'Ordered' || data.status === 'Back Ordered') {
            shipLine2 = data.estimatedShipDate ? `Estimated to ship by ${moment(data.estimatedShipDate).format('MM/DD')}` : data.shippingAddress;
        } else if (data.status === 'Shipped' || data.groupName === 'ShippedInbound' || isDelivered) {
            shipLine2 = data.shippingAddress;
        }

        if (data.groupName === 'ShippedInbound') data.status = 'Shipped Inbound';

        if (data.groupName === 'Delivered') data.status = 'Delivered';

        let shipLine3 = '';

        if (data.status === 'Ordered' || data.status === 'Back Ordered') { 
            if (data.prependPoToShipping) 
                shipLine3 = data.estimatedShipDate ? '' : data.shippingAttention;
            else 
                shipLine3 = data.estimatedShipDate ? '' : `PO# ${data.poNumber}` + (data.shippingAttention ? `, ${data.shippingAttention}` : '');
        } else { 
            if ((data.status === 'Shipped' || data.groupName === 'ShippedInbound') && !data.prependPoToShipping)
                shipLine3 = data.prependPoToShipping ? data.shippingAttention : (data.poNumber ? `PO# ${data.poNumber}` + (data.shippingAttention ? `, ${data.shippingAttention}` : '') : '');
            else 
                shipLine3 = data.shippingAttention;
        }

        return (
            <React.Fragment>
                {data.isServiceItem && data.statusId === 8 ? null : (
                    <div className="card-ship-info">
                        {data.status !== 'Quoted' && data.status !== 'eQuoted' && (
                            <div className="history-link" onClick={() => this.sendLogViewHistory(data)}>
                                View History
                            </div>
                        )}
                        {data.serviceInfo ? (
                            this.renderShippingForService(data)
                        ) : (
                            <ul className="StepProgress">
                                <li className={isDelivered ? 'StepProgress-item is-next complete' : 'StepProgress-item is-next'}>
                                    <div className="eta-info">
                                        {isDelivered ? (
                                            <div className="delivered">{`Delivered ${moment(data.dateDelivered).format('MMMM DD, YYYY hh:mm a')}`}</div>
                                        ) : (
                                            <div className="eta">
                                                {' '}
                                                {data.status === 'Ordered' || data.status === 'Back Ordered' || data.status === 'Shipped' || data.groupName === 'ShippedInbound' ? (
                                                    <React.Fragment>{eta}</React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {' '}
                                                        ETA <div className="eta-date">{eta}</div>{' '}
                                                    </React.Fragment>
                                                )}{' '}
                                            </div>
                                        )}
                                        {shipLine2 ? <div className="eta-desc">{shipLine2}</div> : null}
                                        {isDelivered ? (
                                            <a
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                onClick={(event) => this.sendLogTrackShipping(event, data)}
                                                href={`${getTrackingUrl(data.trackingNumber, data.shipViaId)}`}
                                            >
                                                {data.trackingNumber}
                                            </a>
                                        ) : null}
                                        <div className="eta-line-3">{shipLine3}</div>
                                    </div>
                                </li>
                                {isDelivered ? null : (
                                    <li className="StepProgress-item is-done current">
                                        <div className="order-status">
                                            <div className="status">
                                                {data.status} {data.status === 'Ordered' ? moment(data.closedDate).format('MM/DD/YYYY') : ''}
                                                {data.urlsData && data.urlsData && data.urlsData.length > 0 && (
                                                    <div className="tracking">
                                                            Tracking #
                                                        {data.urlsData &&
                                                                data.urlsData.map((urlData, index) => {
                                                                    return (
                                                                        <a target="_blank" rel="noreferrer noopener" href={`${urlData.url}`}>
                                                                            {urlData.number}{' '}
                                                                        </a>
                                                                    );
                                                                })}
                                                    </div>
                                                )}
                                                {data.urlsData && data.urlsData.length < 1 && <div className="tracking">Tracking number unavailable</div>}
                                            </div>
                                            <div className="ship-method">{data.carrierPriority}</div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    }

    renderWarningSection = (data) => { 
        const {user: {settings: {isShowBackorderMessages}}} = this.props;

        if (!isShowBackorderMessages)
            return null;

        let showWarningMessage = false;
        let warrningMessage = '';
        let showFollowupDate = false;

        if (data.statusId == 25 && (data.estimatedShipDateReasonId == 2 || data.backordered == 'Yes')) { // ordered && backorder
            showWarningMessage = true;
            if (data.estimatedShipDate)
                warrningMessage = `This item is on backorder until ${moment(data.estimatedShipDate).format('MM/DD/YYYY')}`;
            else
                warrningMessage = `This item is on backorder with no estimated ship date`;

            showFollowupDate = true;
        } else if (data.statusId == 25 && (!data.trackingStatusId || data.trackingStatusId == 1) && (data.transitDays == 1 || data.transitDays == 2) && (data.estimatedShipDate && new Date(data.estimatedShipDate) > new Date().setHours(0, 0, 0, 0))) {
            showWarningMessage = true;
            warrningMessage = `This item will ship on ${moment(data.estimatedShipDate).format('MM/DD/YYYY')}`
        }

        return (
            showWarningMessage &&
            <div className="card-warrning-info">
                <div style={{fontWeight: '600'}}> <img style={{width: '20px', marginBottom: '5px', marginRight: '5px'}} src="images/icn_orange-exclamation@2x.png" />{warrningMessage}</div>
                { showFollowupDate && (<div style={{marginLeft: '26px', fontWeight: '600'}}>Follow up date: {moment(data.followupDate).format('MM/DD/YYYY')} </div>) } 
            </div>            
        )
    }

    renderQuoteSection = (data) => {
        const title = data.groupName === 'Requested' ? 'Quote Initiated' : 'Quote Available';
        const description = data.groupName === 'Requested' ? 'We are working on your quote.' : 'Item is ready for purchase.';

        return (
            <div className="card-missing-paperwork card-missing-paperwork--bottom">
                <div className="card-missing-paperwork-title">{title}</div>
                <div>{description}</div>
            </div>
        );
    }

    renderRgaSection = (data, cardActionType) => {
        const rgaClasses = {
            'card-exchange-return': true,
            isDue: data.isDue,
            overDue: data.isOverDue,
        };

        return (
            <div className={classNames(rgaClasses)}>
                <div className="card-missing-paperwork-title">{`${cardActionType} Due ${moment(data.dueDate).format('MM/DD/YYYY')}`}</div>
                {data.isOverDue ? <div className="rga-past-due">PAST DUE</div> : null}
                {cardActionType == 'Return' ? (
                    <div>Returns are credited upon receipt and inspection. If you have used your own shipping label enter tracking below to avoid delays. </div>
                ) : (
                    <div>To avoid additional charges, your core exchange must be shipped back by the due date. If you have used your own shipping label enter tracking below to avoid delays.</div>
                )}
            </div>
        );
    }

    renderRgaRepairSection = (data) => {
        const rgaClasses = {
            'card-missing-paperwork': true,
            'm-top-40': false,
            isDue: data.isDue,
            overDue: data.isOverDue,
        };

        return (<div className={classNames(rgaClasses)}>
            <div className="card-missing-paperwork-title">{`Return Due ${moment(data.dueDate).format('MM/DD/YYYY')}`}</div>
            {(data.rgaCreatedTimestamp &&
            !data.canGenerateShippingLabel &&
            (!data.vendorRmaRequired || (data.vendorRmaRequired && data.vendorRmaNumber)) &&
            (data.trackingNumber && data.isShipFromCustomer)) ? <div className="card-rga-printed"><FontAwesomeIcon icon={faCheckCircle} size="sm" />&nbsp;<span>Label Created</span> on {moment(data.rgaCreatedTimestamp).format('MM/DD/YYYY')}</div> : null}
            { data.isOverDue ? <div className="rga-past-due">PAST DUE</div> : null }
            <div>To avoid cancellation ship your repair to us by the due date. If you have used your own shipping label enter tracking to the right to avoid delays.</div>
        </div>)
    }

    renderMissingPaperWorkSection = (data, cardActionType) => {
        const rgaClasses = {
            'card-missing-paperwork': true,
        };

        return (
            <div className={classNames(rgaClasses)}>
                <div className="card-missing-paperwork-title">Repair Initiated</div>
                <div>Paperwork can take up to 48 hours to prepare. Sending without this paperwork can cause additional delays </div>
            </div>
        );
    }

    onPackingBundle = (lineItemId) => {
        const {onHandleBundleCandidates} = this.props;
        onHandleBundleCandidates(lineItemId);
    };

    shouldShowNudgeRequester = (data) => {
        const {
            user: {info: {contactId}},
        } = this.props;

        const {maxNudges} = this.state;

        if (data.rgaEtaDate &&
            data.requesterId && data.requesterId !== contactId && data.nudgeCount < maxNudges &&
            (moment().isAfter(moment(data.rgaEtaDate), 'day') || moment().isSameOrAfter(moment(data.rgaEtaDate).subtract(2, 'days'), 'day')))
            return true;
        else
            return false;
    }

    renderAction(data, cardActionType) {
        const {history} = this.props;
        const {location} = window;

        if (!cardActionType) {
            switch (data.statusId) {
                case 1:
                    // Awaiting approval
                    cardActionType = CardActionType.AwaitingApprovalReadOnly;
                    break;
                case 5:
                case 6:
                case 10:
                    // Exchange or return
                    cardActionType = CardActionType.Exchange;
                    break;
                default:
                    break;
            }
        }

        switch (cardActionType) {
            case CardActionType.Quote:
                return (
                    <div className="card-action">
                        {data.isServiceItem ? <div className="action-row">
                            <Button secondary={true} onClick={() => history.push(`/service/${data.lineItemId}`, {from: location.pathname + location.search})}>CHOOSE TECHNICIANS{data.optionCount > 0 && ` (${data.optionCount})`}</Button>
                        </div> :
                            <div className="action-row">
                                <Button secondary={true} onClick={() => history.push(`/quotes/${data.lineItemId}`)}>SEE BUYING OPTIONS{data.optionCount && ` (${data.optionCount})`}</Button>
                            </div>
                        }
                    </div>
                );
            case CardActionType.AwaitingPO:
                return (
                    <div className="card-action">
                        <div className="action-row">
                            <Button secondary={true} onClick={() => this.setState({showSubmitPoDialog: true, checkboxItems: [data]})}>
                                ADD P.O.#
                            </Button>
                        </div>
                    </div>
                );
            case CardActionType.AwaitingApproval: {
                const {
                    user: {facilities},
                } = this.props;
                const approvalLimit = facilities.find((x) => Number(x.facilityId) === Number(data.facilityId)).purchaseLimit;

                return (
                    <div className="card-action">
                        <div className="action-row">
                            {(data.options && data.options.length > 1) && !data.isVariantProduct ? (
                                <span className="more-options" onClick={() => this.setState({showBuyingOptions: true, buyingOptions: data.options, buyingOptionsImage: data.imagePath})}>
                                    Other Buying Options ({data.options.length - 1})
                                </span>
                            ) : null}
                            <Button secondary={true} className="alternate" onClick={() => this.setState({rejectItems: [data], showRejectDialog: true})}>
                                REJECT
                            </Button>
                            {approvalLimit >= data.extendedPrice ? (
                                <ApprovalPurchaseButton lineItemIds={[data.lineItemId]} />
                            ) : (
                                <Button secondary={true} onClick={() => this.onApprove(data)}>
                                        APPROVE
                                </Button>
                            )}
                        </div>
                    </div>
                );
            }
            case CardActionType.AwaitingApprovalReadOnly:                
                return (
                    <div className="card-action">
                        <div className="action-row">
                            {data.nextApprovalGroup ? (
                                <Button className="button-nudge" secondary={true} onClick={() => this.onNudgeApprover(data, false)}>
                                    Nudge Approver
                                </Button>
                            ) : null}
                        </div>
                    </div>
                );
            case CardActionType.Delivered:
                return (
                    <div className="card-action">
                        <div className="action-row">
                            <span className="more-options" onClick={() => history.push(data.detailUrl)}>
                                BUY IT AGAIN
                            </span>
                            {data.checkedIn === 'No' ? (
                                <Button secondary={true} className="alternate" onClick={() => this.setState({showCheckInDialog: true, selectedLineItem: data})}>
                                    Check In
                                </Button>
                            ) : null}
                            <Button secondary={true} onClick={() => this.setState({showReturnDialog: true, selectedLineItem: data})}>
                                RETURN
                            </Button>
                        </div>
                    </div>
                );
            case CardActionType.OrderHistory:
                return (
                    <div className="card-action">
                        <div className="action-row">
                            <Button secondary={true} onClick={() => history.push(data.detailUrl)}>
                                BUY IT AGAIN
                            </Button>
                        </div>
                    </div>
                );
            case CardActionType.Exchange:
            case CardActionType.Return:
                let showNudgeRequester = this.shouldShowNudgeRequester(data);

                return (
                    <div className="card-action">
                        <div className="action-row">
                            {data.rgaNumber ? (
                                <span className="more-options" onClick={() => this.onClickShowTrackingNumberDialog(data)}>
                                    ENTER TRACKING #
                                </span>
                            ) : null}
                            {data.rgaNumber ? (
                                <PDFButton
                                    buttonType="Secondary"
                                    title="Print Label & Documents"
                                    fileName={data.rgaNumber}
                                    pdfUrl={`ShoppingService/api/v1.0/lineItems/rgapaperwork/${data.lineItemId}/${data.rgaNumber}`}
                                />
                            ) : null}
                            {showNudgeRequester ? (
                                <Button className="button-nudge" secondary={true} onClick={() => this.onShowNudgeMenu(data)}>
                                    Nudge Requesters
                                </Button>
                            ) : null}
                        </div>
                    </div>
                );
            case CardActionType.Repair:
                if (data.groupName !== 'Initiated' || !data.rgaNumber) return null;
                return (
                    <div className="card-action">
                        <div className="action-row">
                            {data.rgaNumber ? (
                                <span className="more-options" onClick={() => this.onClickShowTrackingNumberDialog(data)}>
                                    ENTER TRACKING NUMBER
                                </span>
                            ) : null}
                            {data.canGenerateShippingLabel && <Button onClick={() => this.onPackingBundle(data.lineItemId)} secondary={true}>PRINT FORM</Button>}
                            {(!data.canGenerateShippingLabel &&
                                (!data.vendorRmaRequired || (data.vendorRmaRequired && data.vendorRmaNumber)) &&
                                (data.trackingNumber && data.isShipFromCustomer)) ?
                                <PDFButton
                                    title="Print Form"
                                    buttonType="Secondary"
                                    directLink={true}
                                    fileName={`PackingSlip${data.lineItemId}`}
                                    pdfUrl={`${process.env.REACT_APP_ORION_API}/ReportGenService/RgaReports/RgaPackingSlip?lineItemId=${data.lineItemId}`} /> : null}
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }

    onClickShowTrackingNumberDialog = (data) => {
        const {gridName} = this.props;

        let logObj = {
            'Reference Number': data.lineItemId,
            Origination: '',
        };

        if (gridName == 'repairsColumns') {
            logObj.Origination = 'Repair card view';
        } else {
            const {currentTab} = this.state;
            if (currentTab == 'returns') logObj.Origination = 'Return card view';
            else logObj.Origination = 'Exchange card view';
        }

        logEvent('Tracking Entry', logObj);

        this.setState({showTrackingNumberDialog: true, selectedLineItem: data});
    }

    getBadgeCount = (gridKey, props) => {
        const {searchText, columns} = this.state;
        const {data} = props || this.props;
        let keyValues = typeof gridKey.value === 'string' ? gridKey.value.split(',') : gridKey.value.toString();
        let items = _.filter(data, (v) => _.includes(keyValues, v[gridKey.fieldName].toString()));

        if (searchText && items.length > 0) {
            items = items.filter((x) => {
                let searchString = '';
                columns.filter((x) => x.visible).forEach((qc) => (searchString += (x[qc.field] || '').toString().toLowerCase()));
                columns
                    .filter((x) => x.visible && x.isNested)
                    .forEach((qc) => {
                        let nestedField = qc.field.split('.');
                        if (nestedField.length === 2) searchString += (x[nestedField[0]][nestedField[1]] || '').toString().toLowerCase();
                    });

                return searchString.indexOf(searchText) > -1;
            });
        }
        return items.length;
    }

    getGridItems() {
        const {view} = this.state;
        const {data} = this.props;

        let gridItems = [];

        gridItems = _.cloneDeep(data).map((g) => {
            g.dateCreated = moment(g.dateCreated).format('MM/D/YYYY');
            g.description = this.truncateDescriptionText(g.description);
            if (view === ViewType.Grid && g.rgaCreatedTimestamp &&
                !g.canGenerateShippingLabel &&
                (!g.vendorRmaRequired || (g.vendorRmaRequired && g.vendorRmaNumber)) &&
                (g.trackingNumber && g.isShipFromCustomer)) {
                g.hasRgaDocumentation = `${g.hasRgaDocumentation} | Label Created on ${moment(g.rgaCreatedTimestamp).format('MM/DD/YYYY')}`
            }
            return g
        });

        return gridItems;
    }

    renderCard = (data) => {
        const {currentTab} = this.state;
        const {
            gridKeys,
            cardAction,
            history,
            showShipInfo,
            user: {settings},
            height: cardHeight,
        } = this.props;

        let cardActionType = gridKeys.length === 0 ? cardAction : gridKeys.filter((x) => x.id === currentTab)[0].cardAction;

        let gridKey = gridKeys.filter((x) => x.id === currentTab)[0];
        let gridHeight = gridKey && gridKey.height ? gridKey.height : null;
        let height = gridHeight || cardHeight || '640';

        let hasActionButtons = false;
        if (!cardActionType) {
            switch (data.statusId) {
                case 1:
                    // Awaiting approval
                    hasActionButtons = true;
                    break;
                case 5:
                case 6:
                case 10:
                    // Exchange or return
                    hasActionButtons = this.shouldShowNudgeRequester(data);
                    break;
                default:
                    hasActionButtons = false;
            }
        }

        return (
            <div className={hasActionButtons ? 'card-view' : 'card-view no-action-buttons'} style={{height: height + 'px'}}>
                <div>
                    {(data.groupName !== 'eQuoted' && data.groupName !== 'Requested') &&
                        <span className="order-label">{`ORDER #${data.orderId}`}</span>}
                    <span className="view-details" onClick={() => this.onSelectCard(data)}>
                        View Details
                    </span>
                    <span className="created-label">{`CREATED ${moment(data.created).format('MM/D/YYYY')}`}</span>
                </div>
                {data.urgencyId === 1 || data.priorityId === 1 ? (
                    <div className="hard-down">
                        <i className="fa fa-exclamation" />
                        <span>Critical Hard Down</span>
                    </div>
                ) : null}
                <div className={data.detailUrl ? "part-description" : "part-description-no-details"} onClick={() => (data.detailUrl ? history.push(data.detailUrl) : null)}>
                    {data.isContractProOption && (
                        <div className="contract-badge">
                            <span>CONTRACT</span>
                        </div>
                    )}
                    {data.description}
                </div>
                <div>
                    <span className="part-image" onClick={() => (data.detailUrl ? history.push(data.detailUrl) : null)}>
                        {data.isServiceItem ? (
                            <img src="/images/icn_repair.png" alt="Service Repair" style={{width: 60, marginLeft: 20}} />
                        ) : (
                            <ProductImage style={{width: '100px'}} url={data.imagePath} altText={data.imageAlt} />
                        )}
                    </span>
                    <span className="part-info">
                        {!data.isContractProApproval && data.isFormularyApproval ? (
                            <span className="formulary-approval">
                                <i className="fa fa-exclamation" />
                                Non-Formulary Option Selected
                            </span>
                        ) : null}
                        {data.isContractProApproval ? (
                            <span className="formulary-approval">
                                <i className="fa fa-exclamation" />
                                Non-Contract Option Selected
                            </span>
                        ) : null}
                        {data.groupName !== 'Initiated' && data.price > 0 && <span className="price">{settings.hidePricing ? '' : formatMoney(data.price)}</span>}
                        {data.isServiceItem && data.manufacturer ? (
                            <span className="detail">
                                OEM: <span>{data.manufacturer}</span>
                            </span>
                        ) : null}

                        {data.isServiceItem && data.serviceInfo && data.serviceInfo.model ? (
                            <span className="detail">
                                Model Number: <span>{data.serviceInfo ? data.serviceInfo.model : ''}</span>
                            </span>
                        ) : null}

                        {data.isServiceItem && data.serviceInfo && data.serviceInfo.serviceType && data.serviceInfo.serviceType !== ' ' ? (
                            <span className="detail">
                                Service Category: <span>{data.serviceInfo ? data.serviceInfo.serviceType : ''}</span>
                            </span>
                        ) : null}

                        {data.isServiceItem && data.workOrderNumber ? (
                            <span className="detail">
                                Work Order Number: <span>{data.workOrderNumber}</span>
                            </span>
                        ) : null}

                        {data.isServiceItem && data.serviceInfo && data.serviceInfo.turnaroundTime ? (
                            <span className="detail">
                                Timeframe Needed by: <span>{data.serviceInfo ? data.serviceInfo.turnaroundTime : ''}</span>
                            </span>
                        ) : null}

                        {data.isServiceItem && data.assetId ? (
                            <span className="detail">
                                Asset ID: <span>{data.assetId}</span>
                            </span>
                        ) : null}

                        {data.isServiceItem && data.created ? (
                            <span className="detail">
                                Created Date: <span>{data.created}</span>
                            </span>
                        ) : null}
                        {!data.isServiceItem ? (
                            <span className="detail">
                                Qty: <span>{data.quantity}</span>
                            </span>
                        ) : null}

                        {!data.isServiceItem && data.partNumber && (
                            <span className="detail">
                                Item #:<span>{data.partNumber}</span>
                            </span>
                        )}

                        {!data.isServiceItem ? (
                            <span className="detail">
                                Condition:
                                <span>
                                    {data.condition} {data.purchaseChoice}
                                </span>
                            </span>
                        ) : null}
                        {!data.isServiceItem && data.nonReturnable ? (
                            <span className="detail">
                                Return Status:
                                <span>{data.nonReturnable ? 'Not Returnable' : 'Returnable'}</span>
                            </span>
                        ) : null}
                        <span className="detail">
                            Facility:<span>{data.facility}</span>
                        </span>

                        {data.isServiceItem && data.priority ? (
                            <span className="detail">
                                Priority: <span>{data.priority}</span>
                            </span>
                        ) : null}

                        {data.isServiceItem && data.status ? (
                            <span className="detail">
                                Status: <span>{data.status}</span>
                            </span>
                        ) : null}

                        {!data.isServiceItem && data.requester ? (
                            <span className="detail">
                                Requester:<span>{data.requester}</span>
                            </span>
                        ) : null}

                        <span className="detail">
                            Ref #:<span>{data.lineItemId}</span>
                        </span>
                        {data.lastApprovedBy && (cardActionType === CardActionType.AwaitingApproval || cardActionType === CardActionType.AwaitingApprovalReadOnly) ? (
                            <span className="detail">
                                Last Approved By:<span>{data.lastApprovedBy}</span>
                            </span>
                        ) : null}
                        {data.nextApprovalGroup && cardActionType === CardActionType.AwaitingApprovalReadOnly ? (
                            <span className="detail">
                                Awaiting Approval By:<span>{data.nextApprovalGroup}</span>
                            </span>
                        ) : null}
                        {cardActionType === CardActionType.AwaitingApproval ? (
                            <span className="detail">
                                Ship Method:<span>{data.carrierPriority}</span>
                            </span>
                        ) : null}
                        {cardActionType === CardActionType.Repair && data.serialNumber ? (
                            <span className="detail">
                                Serial Number: <span>{data.serialNumber}</span>
                            </span>
                        ) : null}
                        {showShipInfo ? (
                            <React.Fragment>
                                {/*
                                {data.estimatedShipDate ? <span className="detail">Estimated Ship Date:<span>{moment(data.estimatedShipDate).format('MMM DD, YYYY')}</span></span> : null}
                                {data.estimatedDelivery ? <span className="detail">ETA:<span>{moment(data.estimatedDelivery).format('MMM DD, YYYY')}</span></span> : null}
                                {data.trackingNumber ? <span className="detail">Tracking Number:<span>{data.trackingNumber}</span></span> : null}
                                */}
                            </React.Fragment>
                        ) : null}
                    </span>
                </div>
                {this.renderShipSection(data, cardActionType)}
                {this.renderWarningSection(data, cardActionType)}
                {this.renderAction(data, cardActionType)}
            </div>
        );
    }

    onConfirm = () => {
        const {onRefresh} = this.props;
        onRefresh();
    }

    onShowRejectItemsDialog = () => {
        const {checkboxItems} = this.state;
        this.setState({rejectItems: checkboxItems, showRejectDialog: true});
    }

    onChangeOption = () => {
        const {onRefresh} = this.props;
        this.setState({showBuyingOptions: false});
        onRefresh();
    }

    onRejectItems = () => {
        const {onRefresh} = this.props;
        this.setState({showRejectDialog: false, checkboxItems: []});
        onRefresh();
    }

    onPoSubmit = () => {
        const {onRefresh} = this.props;
        const {checkboxItems} = this.state;
        let notification = checkboxItems.length === 1 ? `(${checkboxItems.length}) item submitted PO#` : `(${checkboxItems.length}) items submitted PO#`;
        this.setState({notification, checkboxItems: []});
        onRefresh();
    }

    onPoDialogSubmit = () => {
        const {onRefresh} = this.props;
        this.setState({checkboxItems: [], showSubmitPoDialog: false});
        onRefresh();
    }

    handleRequesterToggle = (e, result) => {
        const {currentTab, requesterViewType} = this.state;
        const {
            onRequesterToggle,
            location: {pathname},
        } = this.props;
        const newViewType = result.value.selection == 'Left' ? RequesterViewType.CurrentUser : RequesterViewType.AllRequesters;

        this.setState({requesterViewType: newViewType});

        logEvent('Mine vs Everyone', {
            'Switch toggle': requesterViewType,
            'Toggled page': pathname,
            'Toggled tab': currentTab,
        });

        if (onRequesterToggle) onRequesterToggle(newViewType);
    }

    onSelectCard = (card) => {
        const {onSelect} = this.props;
        this.sendLogViewDetail(card);
        onSelect(card);
    }

    sendLogViewDetail = (row) => {
        const {
            location: {pathname},
        } = this.props;
        const {id_ins} = this.state;
        let pageName = pathname.indexOf('/orders/') > -1 ? 'Orders' : pathname.replace('/', '');

        logEvent('VIEW DETAILS', {
            id_ins: id_ins,
            'Order number': row.orderId,
            'Ref number': row.lineItemId,
            'Detail type': pageName,
            'Detail state': row.groupName || row.status || row.statusName,
        });
    }

    sendLogViewHistory = (data) => {
        const {
            location: {pathname},
        } = this.props;
        const {currentTab, id_ins} = this.state;
        const pageName = pathname.indexOf('/orders/') > -1 ? 'Orders' : pathname.replace('/', '');

        logEvent('VIEW HISTORY', {
            'View History': data.lineItemId,
            Age: data.closedDate ? moment(new Date().valueOf()).diff(moment(Date.parse(data.closedDate)).valueOf(), 'minutes') : 0,
            'Ship Priority': data.carrierPriority,
            Page: pageName,
            Tab: currentTab || pageName,
        });

        this.setState({showTrackingHistory: true, selectedLineItem: data});
    }

    sendLogTrackShipping = (e, data) => {
        if (!data || (data && !data.lineItemId)) return;

        e.preventDefault();
        const {
            location: {pathname},
        } = this.props;
        const {currentTab, id_ins} = this.state;
        const pageName = pathname.indexOf('/orders/') > -1 ? 'Orders' : pathname.replace('/', '');

        logEvent('Track Shipping', {
            'Track Shipping': data.lineItemId,
            'Ship Priority': data.carrierPriority ? data.carrierPriority : '',
            Age: data.closedDate ? moment(new Date().valueOf()).diff(moment(Date.parse(data.closedDate)).valueOf(), 'minutes') : 0,
            Page: pageName,
            Tab: currentTab,
        });
        window.open(e.target.href);
    }

    handleOnCheckInConfirm = () => {
        const {selectedLineItem, requesterViewType} = this.state;
        this.setState({showCheckInPartPopup: false});
        const {updatePartReceived, onRefresh} = this.props;
        updatePartReceived(selectedLineItem).then(() => {
            this.setState({showCheckInDialog: false});
            if (onRefresh) onRefresh(requesterViewType);
        });
    }

    handleOnReturnConfirm = () => this.setState({showReturnDialog: false, selectedLineItem: {}});

    closest = (el, selector) => {
        let matchesFn;

        // find vendor prefix
        ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
            if (typeof document.body[fn] == 'function') {
                matchesFn = fn;
                return true;
            }
            return false;
        });

        let parent;

        // traverse parents
        while (el) {
            parent = el.parentElement;
            if (parent && parent[matchesFn](selector)) {
                return parent;
            }
            el = parent;
        }

        return null;
    };

    shouldCheckForUrlsData = (e) => {
        let shouldCheck = false;
        let clickedEl = this.closest(e.originalEvent.target, '.preformatted');

        if (!clickedEl) return false;

        for (let i = 0; i < clickedEl.childNodes.length; i++) {
            if (clickedEl.childNodes[i].className == 'ui-cell-data') {
                let clickedElData = clickedEl.childNodes[i].firstChild;

                if (clickedElData.attributes && clickedElData.attributes['data-trackingnumber'] && clickedElData.attributes['data-shipviaid']) {
                    shouldCheck = true;
                }
            }
        }

        return shouldCheck;
    }

    showInvoice = (lineItemId, invoiceNumber) => {
        const pdfUrl = `/ShoppingService/api/v1/lineitems/invoice/${lineItemId}/${invoiceNumber}`;
        axios.get(pdfUrl, {responseType: 'arraybuffer', headers: {'Content-Type': 'application/json', Accept: 'application/pdf'}}).then((x) => {
            const data = x.data;
            const file = new Blob([data], {type: 'application/pdf'});

            if (file.size === 0) {
                this.setState({showMissingInvoice: true});
                return;
            }

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file);
                return;
            }

            const fileURL = URL.createObjectURL(file);
            let link = document.createElement('a');
            link.type = 'hidden';
            link.href = fileURL;
            link.download = `invoice.pdf`;
            document.body.appendChild(link);
            link.click();

            setTimeout(function () {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(fileURL);
            }, 100);
        });
    }

    onTrackingNumberSubmitted = () => {
        const {onRefresh} = this.props;
        onRefresh();
        this.setState({showTrackingNumberDialog: false});
    }

    onTrackingNumberNumberClick(e) {
        e.originalEvent.stopPropagation();
        return false;
    }

    renderMissingInvoice = () => {
        return (
            <Popup show={true} hideButtons={true} onCancel={() => this.setState({showMissingInvoice: false})}>
                <div>This invoice is not currently available.</div>
            </Popup>
        );
    }

    checkToggle = () => {
        const {
            subLabel,
            user: {
                settings: {showTechNames, showOtherTechQuotes, showAllRepairs, showAllRecords, showOthersServiceEvents},
            },
            showRequesterToggle,
        } = this.props;
        const quotes = subLabel === 'Quotes';
        const exchngReturn = subLabel === 'Exchanges & Returns';
        const repairs = subLabel === 'Repairs';
        if (showRequesterToggle && ((showTechNames) || (showOthersServiceEvents) || (quotes && showOtherTechQuotes) || (exchngReturn && showAllRecords) || (repairs && showAllRepairs)))
            return true;
    }


    onShowNudgeMenu = (data) => {

        let currentRequesterName = data.requester + ' - Requester';

        let defaultRequester = {[data.requesterId] : currentRequesterName};

        this.setState({showNudgeMenu: true, selectedNudgeItem: data, selectedNudgeRecipients: defaultRequester});

        this.showNudgeMenu();
        
    }

    sendNudgeRecipients = async () => {

        const {selectedNudgeRecipients, selectedNudgeItem, maxNudges} = this.state;

        let type = selectedNudgeItem.rgaTypeId ? selectedNudgeItem.rgaTypeId : 1;
        let recipientIds = Object.keys(selectedNudgeRecipients);

        let showMaxReachedMessage = (selectedNudgeItem.nudgeCount + 1) >= maxNudges ? true : false;

        axios.post(`/ShoppingService/api/v1/orders/${selectedNudgeItem.lineItemId}/SendNudgeRequester/${type}`, recipientIds).then(() => {
            this.setState({showNudgeRequesterConfirmDialog: true, selectedLineItem: selectedNudgeItem, showNudgeMenu: false, maxNudgesSent: showMaxReachedMessage});
        });


    }

    showNudgeMenu = () => {

        const {nudgeRequesterList, selectedNudgeRecipients} = this.state;

        return (
            <Popup
                show={true}
                confirmText="Nudge Requesters"
                onConfirm={() => this.sendNudgeRecipients()}
                onCancel={() => this.setState({showNudgeMenu: false, selectedLineItem: {}})}
            >
                <h1>Select Requesters</h1>
                <TextFieldTags
                    listItems={nudgeRequesterList}
                    value={selectedNudgeRecipients}
                    placeHolder="Select Recipient(s)"
                    onChange={(newValue) => this.selectRequestersWithDefault({newValue})}
                    keyField="id"
                    valueField="value"
                    useMultiSelect={true}
                />
                <div style={{height: 96}} />
            </Popup>
        );
    }

    selectRequestersWithDefault = (value) => {
        const {selectedNudgeItem} = this.state;

        if (JSON.stringify(value.newValue) === '{}') {
            let currentRequesterName = selectedNudgeItem.requester + ' - Requester';
            let defaultRequester = {[selectedNudgeItem.requesterId] : currentRequesterName};
            
            this.setState({selectedNudgeRecipients: defaultRequester});
        }
        else if (!Object.values(value.newValue).includes(selectedNudgeItem.requesterId)) {
            const newReqList = {
                [selectedNudgeItem.requesterId] : selectedNudgeItem.requester + ' - Requester',
                ...value.newValue
            };
            this.setState({selectedNudgeRecipients: newReqList});
        }
    }

    render() {
        const {
            checkboxItems,
            showBuyingOptions,
            buyingOptions,
            buyingOptionsImage,
            showRejectDialog,
            showSubmitPoDialog,
            rejectItems,
            tabCounts,
            showCheckInDialog,
            showReturnDialog,
            selectedLineItem,
            showTrackingNumberDialog,
            showNudgeConfirmDialog,
            showNudgeRequesterConfirmDialog,
            showTrackingHistory,
            showMissingInvoice,
            renderGridMobile,
            showNudgeMenu,
            maxNudgesSent,
        } = this.state;

        const {
            cardAction,
            children,
            location: {pathname},
        } = this.props;

        let purchaseLines = [];
        if (cardAction === CardActionType.AwaitingPO && checkboxItems.length > 0) {
            purchaseLines = checkboxItems.map((x) => x.purchaseInfo);
        }

        return (
            <div className={`${pathname === '/orders/history' ? 'multi-grid-page-server' : 'multi-grid-page-server multi-grid-page--sticky'}`}>
                {this.renderHeader()}
                <div>{children}</div>

                <div className="grid-bottom">
                    <Default>{this.renderGrid()}</Default>
                    {(pathname === '/orders/history') || (!renderGridMobile && !tabCounts.length) ? <PhonePortrait>{this.renderGrid()}</PhonePortrait> : null}
                </div>

                {showBuyingOptions ? (
                    <BuyingOptionsDialog options={buyingOptions} imagePath={buyingOptionsImage} onSave={() => this.onChangeOption()} onCancel={() => this.setState({showBuyingOptions: false})} />
                ) : null}

                {showRejectDialog ? <RejectApprovalDialog items={rejectItems} onReject={() => this.onRejectItems()} onCancel={() => this.setState({showRejectDialog: false})} /> : null}

                {showSubmitPoDialog ? (
                    <SubmitPoForm
                        purchaseLines={purchaseLines}
                        onSubmit={() => this.onPoDialogSubmit()}
                        showPopup={true}
                        onCancel={() =>
                            this.setState({
                                showSubmitPoDialog: false,
                                checkboxItems: [],
                            })
                        }
                    />
                ) : null}

                {showCheckInDialog ? (
                    <Popup
                        confirmText="OK"
                        cancelText="Cancel"
                        show={true}
                        onConfirm={() => this.handleOnCheckInConfirm()}
                        onCancel={() =>
                            this.setState({
                                showCheckInDialog: false,
                                selectedLineItem: {},
                            })
                        }
                        className="asset-information-dialog"
                    >
                        <div className="asset-information-dialog">
                            <span>
                                <i className="fa fa-exclamation-triangle" aria-hidden="true" /> Please confirm you are acknowledging receipt of the part(s).
                            </span>
                        </div>
                    </Popup>
                ) : null}

                {showReturnDialog ? (
                    <div className="return-order-details">
                        <ReturnOrderForm orderLine={selectedLineItem} onCancel={() => this.setState({showReturnDialog: false})} />
                    </div>
                ) : null}

                {showTrackingNumberDialog ? (
                    <TrackingNumberEntryDialog rga={selectedLineItem} onCancel={() => this.setState({showTrackingNumberDialog: false})} onSave={() => this.onTrackingNumberSubmitted()} />
                ) : null}
                {showNudgeConfirmDialog ? (
                    <Popup
                        show={true}
                        hideButtons={true}
                        onCancel={() =>
                            this.setState({
                                showNudgeConfirmDialog: false,
                                selectedLineItem: {},
                            })
                        }
                        className="asset-information-dialog"
                    >
                        <div className="asset-information-dialog">
                            <h2>Nudge Approver for Order #{selectedLineItem.lineItemId}</h2>
                            <span className="blueText">Email reminder sent successfully!</span>
                            <br />
                            <br />
                            <span>
                                Your approval &quot;nudge&quot; for Order #{selectedLineItem.lineItemId} was sent to {selectedLineItem.nextApprovalGroup}
                            </span>
                        </div>
                    </Popup>
                ) : null}

                {showNudgeRequesterConfirmDialog &&
                    <Popup
                        show={true}
                        hideButtons={true}
                        onCancel={() => this.setState({showNudgeRequesterConfirmDialog: false, selectedLineItem: {}})}
                        className="asset-information-dialog" >
                        <div className="asset-information-dialog">
                            <h2>Nudge Requester for Order #{selectedLineItem.lineItemId}</h2>
                            <span className="blueText">Email reminder sent successfully!</span><br /><br />
                        </div>
                    </Popup>
                }

                {maxNudgesSent && 
                    <Popup
                        show={true}
                        hideButtons={true}
                        onCancel={() => this.setState({showNudgeRequesterConfirmDialog: false, selectedLineItem: {}, maxNudgesSent: false})}
                        className="asset-information-dialog" >
                        <div className="asset-information-dialog">
                            <h2>Maximum Nudges Reached for Order #{selectedLineItem.lineItemId}</h2>
                        </div>
                    </Popup>
                }

                {showTrackingHistory ? (
                    <TrackingPanel show={true} status={selectedLineItem.status} lineItemId={selectedLineItem.lineItemId} onClose={() => this.setState({showTrackingHistory: null})} />
                ) : null}

                {showMissingInvoice ? this.renderMissingInvoice() : null}

                {showNudgeMenu ? this.showNudgeMenu() : null}
            </div>
        );
    }

    renderGrid = () => {
        const {
            tabCounts,
            currentTab,
            renderGridMobile,
        } = this.state;

        const {
            data,
            cardAction,
            loadingData,
            gridKeys,
            subLabel,
        } = this.props;

        let tab = tabCounts.filter((x) => x.id === currentTab)[0];
        let showEmptyMessage = (tab || {}).count === 0;
        let gridItems = this.getGridItems();
        let gridKey = gridKeys.filter((x) => x.id === currentTab)[0];

        if (!gridKey && data.length === 0) {
            showEmptyMessage = true;
            gridKey = {tabDisplay: subLabel};
        }
        if (gridItems && gridItems.length > 0) {
            for (let i = 0; i < gridItems.length; i++) {
                let curr = gridItems[i];

                const urlsData = getTrackingNumbersUrlsData(curr.trackingNumbers, curr.lineItemId);
                curr.urlsData = urlsData;
            }
        }

        return (
            <div style={{width: renderGridMobile ? '100%' : '', marginTop: '30px'}}>
                {loadingData ? (
                    <div className="loading-data">
                        <div className="loader" />
                    </div>
                ) : null}
                <PhoneLandscape>
                    <div className="card-view-wrapper">{gridItems.map((x) => this.renderCard(x))}</div>
                </PhoneLandscape>
                {cardAction === CardActionType.AwaitingPO && !showEmptyMessage ? (
                    <div style={{float: 'right', paddingTop: '25px'}}>
                        <SubmitPoForm purchaseLines={checkboxItems.map((x) => x.purchaseInfo)} onSubmit={() => this.onPoSubmit()} showPopup={false} />
                    </div>
                ) : null}
                <Lg>
                    <div className="card-view-wrapper">{gridItems.map((x) => this.renderCard(x))}</div>
                </Lg>
            </div>
        );
    }

    renderSearchBar = () => {
        const {defaultSearchTerm, location: {pathname}} = this.props;
        const {showSearchBox} = this.state;
        let showToggle = this.checkToggle();

        const searchBoxClasses = {
            'form-control': true,
            'form-control hidden-search-box': true,
            'is-visible': showSearchBox,
        };

        return (<div className="grid-toolbar">
            {pathname === '/orders/history' ? <Lg>
                <div className="hidden-search-wrapper">
                    <input
                        type="search"
                        className={classNames(searchBoxClasses)}
                        onChange={(e) => this.onCardViewSearch(e)}
                        autoComplete="grid-search"
                        value={defaultSearchTerm}
                    />
                    <i className="glyphicon glyphicon-search" />
                </div>
            </Lg> :
                <div className={`hidden-search-wrapper ${!showToggle ? 'hidden-search-wrapper--no-margin' : ''}`}>
                    <input
                        type="search"
                        className={classNames(searchBoxClasses)}
                        onChange={(e) => this.onCardViewSearch(e)}
                        autoComplete="grid-search"
                        value={defaultSearchTerm}
                    />
                    <i className="glyphicon glyphicon-search" />
                </div>}
        </div>);
    }

    renderHeader = () => {
        const {showSearch, label, subLabel, headerLink, setOrdersPredefinedFilters, location: {pathname}} = this.props;
        const {onOpsV2} = this.state;

        return (<div className={`grid-header ${pathname === '/orders/history' ? 'grid-header--title' : 'grid-header--sticky'}`}>
            <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                {onOpsV2 ? null :
                    <>
                        <span className="grid-label ps-hidden-xs ps-hidden-sm ps-hidden-md">{label}</span>
                        <span className="grid-sub-label">{subLabel}</span>
                    </>
                }
                {headerLink ? (
                    headerLink.url === '/orders/history' ? (
                        <NavLink className="grid-header-link" to={headerLink.url} onClick={() => setOrdersPredefinedFilters([])}>
                            {headerLink.text}
                        </NavLink>
                    ) : (
                        <NavLink className="grid-header-link" to={headerLink.url}>
                            {headerLink.text}
                        </NavLink>
                    )
                ) : null}
            </div>
            {showSearch && this.renderSearchBar()}
        </div>);
    }
}
