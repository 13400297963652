import React from 'react';
import styled from 'styled-components';
import {Popup} from '@partssourceinc/react-ui-core';

const Container = styled.div`
background-color

`;

const Description = styled.div`
    font-size: 20px;
`;

const List = styled.ul`
    padding-left: 15px;
    margin-top: 16px;
    font-size: 20px;
    line-height: 30px;

    li {
        padding-left: 8px;
        margin-bottom: 10px;
    }
`;

export function FormularyChangeConfirmation({changes, onHide, showCustomerMessage}) {  
    if (!changes || changes.length === 0) {
        changes = ['No changes were made to current rule.  No Account Management action will be taken.'];
        showCustomerMessage = false;
    }
    return (
        <Container>
            <Popup confirmText="Done"
                hideCancel={true}
                show={true}
                className="formulary-change-dialog"
                onConfirm={onHide}
                onCancel={onHide}               
            >
                <p className="title">Rule Change(s) Submitted for Approval</p>
                {showCustomerMessage && <Description>You've submitted the following change(s):</Description>}
                
                <List>
                    {changes.map((c, i) => <li key={`change-${i}`} dangerouslySetInnerHTML={{__html: c}} />)}
                </List>        

                {showCustomerMessage && <Description> Our Account Management team will notify you when your change(s) are approved.</Description>}      
            </Popup> 
        </Container>
    );
}
