import React from 'react';
import styled from 'styled-components';
import {Popup} from '@partssourceinc/react-ui-core';

const Container = styled.div`
    @media(max-width: 549px){
        height: calc(100vh - 200px);
        overflow: auto;
        padding-top: 24px;
    }
`;

const Title = styled.div`
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 300;
`;

const SectionWrapper = styled.div`
    margin-bottom: 32px;
`;

const SectionHeader = styled.div`
    img {
        width: 30px;
        position: relative;
        top: -3px;
    };

    span {
        margin-left: 8px;
        font-size: 20px;
    }
`;

const SectionLabel = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
    margin-left: 38px;
    margin-top: 16px;
`;

const Description = styled.div`
    font-size: 16px;
    margin-left: 38px;
`;

export function FormularyEvidenceMetricModal({onClose}) {  
    
    return (
        <Popup 
            className="formulary-modal"
            confirmText="Done"           
            show={true} 
            hideButtons={false}
            hideCancel={true} 
            onConfirm={onClose}
            onCancel={onClose}>
            <Container>
                <Title>Metric Definitions</Title>
                <SectionWrapper>
                    <SectionHeader>
                        <img src="/images/formulary/icn_cost.png" />
                        <span>Cost</span>
                    </SectionHeader>
                    <SectionLabel>Price</SectionLabel>
                    <Description>Unit price of each item</Description>
                </SectionWrapper>
                <SectionWrapper>
                    <SectionHeader>
                        <img src="/images/formulary/icn_quality.png" />
                        <span>Quality</span>
                    </SectionHeader>
                    <SectionLabel>DOA</SectionLabel>
                    <Description>Dead on Arrival Rate is the # of defected units divided by the # of units shipped in the same time period</Description>
                    <SectionLabel>QRR</SectionLabel>
                    <Description>Quality Return Rate is the percentage of orders that were returned due to a quality issue</Description>
                    <SectionLabel>Warranty Return %</SectionLabel>
                    <Description>Percentage of orders that have had warranty returns</Description>
                </SectionWrapper>
                <SectionWrapper>
                    <SectionHeader>
                        <img src="/images/formulary/icn_delivery.png" />
                        <span>Delivery</span>
                    </SectionHeader>
                    <SectionLabel>Avg. Days to Ship</SectionLabel>
                    <Description>Average # of days it takes the supplier to ship the product(s) after order is placed</Description>
                </SectionWrapper>
            </Container>
        </Popup>
    );
}
