import axios from 'axios';
import React, {useState} from 'react';
import styled from 'styled-components';
import {RadioButton, Dropdown} from '@partssourceinc/react-ui-core';
import {FormularyValueDefinitionsModal} from '../';

const Container = styled.div`
    text-align: center;
    margin-bottom: 20px;
    margin-top: 33px;
`;

const Section25 = styled.div`
    width: 50%;
    display: inline-block;
    line-height: initial;    
    vertical-align: top;
    border-right: 1px solid rgb(220,220,220);

    &.no-border {
        border-right: none;
    }

    @media (max-width: 529px) {
        width: 100%;

        &.no-border {
           margin-top: 16px;
        }
    }
`;

const Section50 = styled.div`
    width: calc(50% - 15px);
    display: inline-block;
    line-height: initial;    
    vertical-align: top;
    padding: 0 20px;
    background-color: rgb(241, 241, 241);
    padding: 24px 0;

    &.left {
        margin-right: 15px;
        padding-bottom: 75px;

        @media (max-width: 1130px) { 
            margin-right: 0;
            padding-bottom: 12px;
            margin-bottom: 24px;
        }
    }
    &.right {
        margin-left: 15px;

        @media (max-width: 1130px) { 
            margin-left: 0;
            padding-bottom: 12px;
            margin-bottom: 24px;
        }
    }

    @media (max-width: 1130px) {
        width: 100%;
    }
`;

const NumberValue = styled.div`
    font-size: 45px;
    font-weight: 400;
    letter-spacing: 0;
    display: inline-block;
`;

const NumberDesc = styled.div`
    color: rgb(119, 119, 119);
    font-size: 14px;
    font-weight: normal;
    height: 40px;
    letter-spacing: 0px;
    display: inline-block;
    margin-left: 8px;
    text-align: left;
    display: block;
    text-align: center;
    margin-top: 5px;    

    &.status-label {
        @media(max-width: 529px) {
            width: 50px;
            display: inline-block;
            text-align: left;
        }
    }

    @media(max-width: 529px) {
        &.status-label {
            width: 50px;
            display: inline-block;
            text-align: left;
        }

        &.total-savings {
            display: inline-block;
            vertical-align: middle;
        }
    }

   
`;

const MetricWrapper = styled.div`
    display: inline-block;
    width: 50%;
    vertical-align: top;    

    @media(max-width: 529px) {
        &.proforma-savings {
            width: 100%;
        }

        &.proposed-savings {
            width: 100%;
        }
    }
`;

const RadioButtonRow = styled.div`
    display: block;
    margin-top: 22px;
    margin-bottom: -12px;

    .radiobutton{
        display: inline-block;

        label {
            min-width: initial;
        }
    }

    @media(max-width: 529px) {
        display: none;
    }
`;

const ValueDefinitions = styled.div`
    color: rgb(119, 119, 119);
    margin-top: 8px;
    text-align: center;

    .icon {
        border: 1px solid;
        border-radius: 50%;
        padding: 0px 5px;
        margin-right: 5px;
        font-size: 12px;
        font-weight: 600;
    }

    .definition-link {
        text-decoration: underline;
        cursor: pointer;
    }

    @media(max-width: 529px) {
        display: none;
    }
`;

const MobileValueDefinitions = styled.div`
    color: rgb(119, 119, 119);
    margin-top: 8px;
    margin-bottom: 24px;
    text-align: center;
    display none;

    .icon {
        border: 1px solid;
        border-radius: 50%;
        padding: 0px 5px;
        margin-right: 5px;
        font-size: 12px;
        font-weight: 600;
    }

    .definition-link {
        text-decoration: underline;
        cursor: pointer;
    }

    @media(max-width: 529px) {
        display: block;
    }
`;

const TimeFrameSelect = styled(Dropdown)`
    display: none;
    min-width: 80%;
    text-align: center;    
    margin-bottom: 15px;
    
    @media (max-width: 529px) {
        display: inline-block;
    }
`;

export const RadioButtonFilter = {
    Annual: 'Annual',
    Quarter: 'Quater',
    Month: 'Month',
    EndOfYear: 'Through End of Year',
};

export function ProFormaSavingsBanner({onChange, metrics}) {    
    const {proFormaSavings, proposedFormaSavings, actualSavings, activeRules, inActiveRules} = metrics;

    const [dateFilter, setDateFilter] = useState(RadioButtonFilter.Annual);
    const [showMetricDefinitionModal, setShowMetricDefinitionModal] = useState(false);

    const options = [
        {value: 'Annual', text: 'Annual'},
        {value: 'Quater', text: 'Quater'},
        {value: 'Month', text: 'Month'},
        {value: 'Through End of Year', text: 'Through End of Year'},
    ];

    const onDateFilterChange = (fltr) => {
        setDateFilter(fltr);
        onChange(fltr);
    };

    const onSelectListChange = (e) => {
        setDateFilter(e.target.value);
        onChange(e.target.value);
    };

    const onShowMetricDefinitionModal = () => {
        setShowMetricDefinitionModal(true);
        axios.post(`/FormularyService/api/v1/log/99/Visual Formulary`, [{name: 'VF Summary Ribbon Modal', value: 'clicked'}]);        
    }

    return (
        <Container>
            <Section50 className="left">  
                <Section25>
                    <MetricWrapper title="Formulary Rules turned ON for your Health Systems">
                        <NumberValue>{activeRules}</NumberValue>
                        <NumberDesc className="status-label">Active Rules</NumberDesc>
                    </MetricWrapper>
                    <MetricWrapper title="Formulary Rules turned OFF for your Health Systems.">
                        <NumberValue>{inActiveRules}</NumberValue>
                        <NumberDesc className="status-label">Inactive Rules</NumberDesc>
                    </MetricWrapper>                
                </Section25>
                <Section25 className="no-border" title="Savings from Formulary Rules turned ON for your Health Systems.">
                    <NumberValue>{`$${Math.ceil(actualSavings || 0).toLocaleString()}`}</NumberValue>
                    <NumberDesc className="total-savings">Actual YTD Savings</NumberDesc>
                </Section25>
                <MobileValueDefinitions onClick={() => setShowMetricDefinitionModal(true)}>
                    <span className="icon">i</span>
                    <span className="definition-link">Value & Data Definitions</span>
                </MobileValueDefinitions>
            </Section50>
            <Section50 className="right">   
                <TimeFrameSelect          
                    label="Timeframe"              
                    onChange={onSelectListChange}
                    options={options}
                    selectedValue={dateFilter}
                    valueField="value" 
                    textField="text"
                    required={true} 
                    suppressBlankOption={true}
                    className="select-list" />              
                <MetricWrapper className="proforma-savings" title="Potential Savings from turning ALL Company Formulary Rules ON.">
                    <NumberValue>{`$${Math.ceil(proFormaSavings || 0).toLocaleString()}`}</NumberValue>
                    <NumberDesc>Pro forma Savings (Toggle 'On' to Calculate)</NumberDesc>
                </MetricWrapper>
                <MetricWrapper className="proposed-savings" title="Potential Savings from all  Company Formulary Rules  + Expert Recommendations that are turned OFF.">
                    <NumberValue>{`$${Math.ceil(proposedFormaSavings || 0).toLocaleString()}`}</NumberValue>
                    <NumberDesc>Total Proposed Pro forma</NumberDesc>
                </MetricWrapper>                                                        
                <RadioButtonRow>
                    <RadioButton id="annual" name="rdbtnDate" label="Annual" value={RadioButtonFilter.Annual} checked={dateFilter === RadioButtonFilter.Annual} className="radiobutton" onChange={() => onDateFilterChange(RadioButtonFilter.Annual)} />
                    <RadioButton id="quarter" name="rdbtnDate" label="Quarter" value={RadioButtonFilter.Quarter} checked={dateFilter === RadioButtonFilter.Quarter} className="radiobutton" onChange={() => onDateFilterChange(RadioButtonFilter.Quarter)} />
                    <RadioButton id="month" name="rdbtnDate" label="Month" value={RadioButtonFilter.Month} checked={dateFilter === RadioButtonFilter.Month} className="radiobutton" onChange={() => onDateFilterChange(RadioButtonFilter.Month)} />
                    <RadioButton id="endOfYear" name="rdbtnDate" label="Through End of Year" value={RadioButtonFilter.EndOfYear} checked={dateFilter === RadioButtonFilter.EndOfYear} className="radiobutton" onChange={() => onDateFilterChange(RadioButtonFilter.EndOfYear)} />
                </RadioButtonRow>
            </Section50>   
            <ValueDefinitions onClick={onShowMetricDefinitionModal}>
                <span className="icon">i</span>
                <span className="definition-link">Value & Data Definitions</span>
            </ValueDefinitions>
            {showMetricDefinitionModal && <FormularyValueDefinitionsModal onClose={() => setShowMetricDefinitionModal(false)} />}
        </Container>
    );
}

