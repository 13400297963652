import { CardActionType } from 'components/MultiGrid';
import moment from 'moment';

export default [
    {
        title: 'Quotes',
        statsKey: 'total_quotes_data',
        gridName: 'quotesColumns',
        gridKeys: [
            {
                id: 'awaitingQuote',
                fieldName: 'groupName',
                value: 'Requested',
                tabDisplay: 'Awaiting Quote',
                cardAction: CardActionType.Quote,
            },
            {
                id: 'quoted',
                fieldName: 'groupName',
                value: 'eQuoted',
                tabDisplay: 'Quoted',
                cardAction: CardActionType.Quote,
            },
        ],
        categories: [
            {
                label: 'New',
                statsKey: 'new_quotes_data',
                description: 'Quotes that PartsSource has provided in the last 48 hours',
            },
            {
                label: 'Expiring',
                statsKey: 'expiring_quotes_data',
                description: 'Quotes that PartsSource has provided but will expire in the next 48 hours if not purchased',
            },
            {
                label: 'Awaiting Quote',
                statsKey: 'awaiting_quote_data',
                description: 'Quotes that PartsSource has not yet responded to',
            },
            {
                label: 'Critical Hard Down',
                statsKey: 'critical_hard_down_quotes_data',
                color: 'red',
                description: 'Quotes that the requester has indicated is for a Critical Hard Down situation',
            },
        ],
        gridColumnsMapping: [
            {
                fromColumn: 'groupName',
                toColumn: 'quoteStatusDesc'
            },
            {
                fromColumn: 'created',
                toColumn: 'dateCreated'
            },
            {
                fromColumn: 'priority',
                toColumn: 'priorityDesc'
            }
        ],
    },
    {
        title: 'Approvals',
        statsKey: 'approval_data',
        gridName: 'approvalColumns',
        hideWithoutSetting: 'approver',
        cardAction: CardActionType.AwaitingApproval,
        categories: [
            {
                label: 'Past Cut Off',
                statsKey: 'approval_past_cutoff_data',
                color: 'red',
                description: 'Items with overnight shipping that missed the daily shipping cutoff due to still needing Approval',
            },
            {
                label: 'Approaching Cut Off',
                statsKey: 'approval_close_to_cutoff_data',
                color: 'orange',
                description: 'Items with overnight shipping that require approval and are within 2.5 hours of the shipping cutoff for the day',
            },
            {
                label: 'Aging Approval',
                statsKey: 'approval_aging_data',
                color: 'orange',
                description: 'Items that have been waiting for approval for more than 2 business days',
            },
            {
                label: 'Critical Hard Down',
                statsKey: 'approval_critical_down_data',
                color: 'red',
                description: 'Items awaiting approval that the requester has indicated is for a Critical Hard Down situation',
            },
        ],
        gridColumnsMapping: [
            {
                fromColumn: 'carrierPriority',
                toColumn: 'carrier'
            },
            {
                fromColumn: 'created',
                toColumn: 'dateCreated'
            },
            {
                fromColumn: 'price',
                toColumn: 'unitPrice'
            },
            {
                fromColumn: 'condition',
                toColumn: 'conditionType'
            },
        ],
    },
    {
        title: 'Awaiting PO',
        statsKey: 'awaiting_po_data',
        gridName: 'awaitingPoColumns',
        hideWithoutSetting: 'showAwaitingPoMenu',
        cardAction: CardActionType.AwaitingPO,
        categories: [
            {
                label: 'Past Cut Off',
                statsKey: 'awaiting_po_past_cutoff_data',
                color: 'red',
                description: 'Items with priority shipping that missed the daily shipping cutoff due to still needing the PO',
            },
            {
                label: 'Approaching Cut Off',
                statsKey: 'awaiting_po_close_to_cutoff_data',
                color: 'orange',
                description: 'Orders with priority shipping that are awaiting PO and within 2 hours of the shipping cutoff time',
            },
            {
                label: 'Aging PO',
                statsKey: 'awaiting_po_aging_data',
                description: 'Items that have been waiting for a PO for more than 2 business days',
            },
            {
                label: 'Critical Hard Down',
                statsKey: 'awaiting_po_hard_down_data',
                color: 'red',
                description: 'Items awaiting PO that the requester has indicated is for a Critical Hard Down situation',
            },
        ],
    },
    {
        title: 'Open Orders',
        statsKey: 'orders_data',
        gridName: 'myOrdersColumns',
        gridKeys: [
            {
                id: 'awaitingApproval',
                fieldName: 'isApproval',
                value: true,
                tabDisplay: 'Awaiting Approval',
                cardAction: CardActionType.AwaitingApprovalReadOnly,
            },
            {
                id: 'submittedForPunchout',
                fieldName: 'isPunchOut',
                value: true,
                tabDisplay: 'Submitted for Punchout',
                height: '530',
            },
            {
                id: 'awaitingPO',
                fieldName: 'isHoldForPo',
                value: true,
                tabDisplay: 'Awaiting PO',
                height: '540',
            },
            {
                id: 'ordered',
                fieldName: 'statusId',
                value: '7,25',
                tabDisplay: 'Ordered',
                cardAction: CardActionType.OrderHistory,
            },
            {
                id: 'shipped',
                fieldName: 'inTransit',
                value: true,
                tabDisplay: 'Shipped',
                height: '540',
            },
            {
                id: 'delivered',
                fieldName: 'isDelivered',
                value: true,
                tabDisplay: 'Delivered',
                cardAction: CardActionType.Delivered,
                height: '520',
            },
        ],
        gridColumnsMapping: [
            {
                fromColumn: 'groupName',
                toColumn: 'status'
            },
            {
                fromColumn: 'carrierPriority',
                toColumn: 'carrier'
            },
            {
                fromColumn: 'created',
                toColumn: 'dateCreated'
            },
            {
                fromColumn: 'equipmentSerialNumber',
                toColumn: 'serialNumber'
            },
            {
                fromColumn: 'modelNumber',
                toColumn: 'modelNumber'
            },
            {
                fromColumn: 'condition',
                toColumn: 'conditionType'
            },
            {
                fromColumn: 'priority',
                toColumn: 'priorityDesc'
            },
            {
                fromColumn: 'price',
                toColumn: 'unitPrice'
            },
            {
                fromColumn: 'poNumber',
                toColumn: 'orderPoNumber'
            },
            {
                fromColumn: 'description',
                toColumn: 'mainDescription'
            },
        ],
        categories: [
            {
                label: 'Ordered',
                statsKey: 'orders_ordered_data',
                description: 'Total open orders awaiting shipment',
            },
            {
                label: 'Shipped',
                statsKey: 'orders_shipped_data',
                description: 'Total orders that are currently en route to your facility',
            },
            {
                label: 'Delivered',
                statsKey: 'orders_delivered_data',
                description: 'Total orders received by your facility in the last 2 business days',
            },
            {
                label: 'Critical Hard Down',
                statsKey: 'orders_harddown_data',
                color: 'red',
                description: 'Total orders that are associated with a critical hard down situation',
            },
        ],
    },
    {
        title: 'Exchanges & Returns',
        statsKey: 'exchanges_returns_all_data',
        gridName: 'rgaColumns',
        gridKeys: [
            {
                id: 'exchanges',
                fieldName: 'rgaTypeId',
                value: '1',
                tabDisplay: 'Exchanges',
                cardAction: CardActionType.Exchange,
            },
            {
                id: 'returns',
                fieldName: 'rgaTypeId',
                value: '2,4',
                tabDisplay: 'Returns',
                cardAction: CardActionType.Return,
            },
        ],
        categories: [
            {
                label: 'Returns Due Soon',
                statsKey: 'returns_due_soon_data',
                color: 'orange',
                description: 'Returns that have been initiated and are within 2 business days of being past due for proper return credit',
            },
            {
                label: 'Returns Past Due',
                statsKey: 'returns_past_due_data',
                color: 'red',
                description: 'Returns that have been initiated and are past due for proper return credit. This could affect receiving return credit',
            },
            {
                label: 'Exchanges Due Soon',
                statsKey: 'exchanges_due_soon_data',
                color: 'orange',
                description: 'Exchanges that are within 2 business days of being past due for proper credit. Failure to ship the exchange in time could result in an Add Bill',
            },
            {
                label: 'Exchanges Past Due',
                statsKey: 'exchanges_past_due_data',
                color: 'red',
                description: 'Exchanges that are past due for proper credit. These items could result in an Add Bill',
            },
        ],
        gridColumnsMapping: [
            {
                fromColumn: 'dueDate',
                toColumn: 'rgaEtaDate'
            },
            {
                fromColumn: 'dateEntered',
                toColumn: 'rgaDateEntered'
            },
            {
                fromColumn: 'description',
                toColumn: 'mainDescription'
            },
            {
                fromColumn: 'quantity',
                toColumn: 'rgaQuantity'
            },
        ]
    },
    {
        title: 'Depot Repairs',
        statsKey: 'repairs_all_data',
        gridName: 'repairsColumns',
        gridKeys: [
            {
                id: 'initiated',
                fieldName: 'repairStatus',
                value: 'Initiated',
                tabDisplay: 'Initiated',
                cardAction: CardActionType.Repair,
                height: '560',
            },
            {
                id: 'shippedOutbound',
                fieldName: 'repairStatus',
                value: 'ShippedOutbound',
                tabDisplay: 'Shipped Outbound',
                cardAction: CardActionType.Repair,
                height: '560',
            },
            {
                id: 'evaluating',
                fieldName: 'repairStatus',
                value: 'Evaluating',
                tabDisplay: 'Evaluating',
                cardAction: CardActionType.Repair,
                height: '560',
            },
            {
                id: 'quoted',
                fieldName: 'repairStatus',
                value: 'Quoted',
                tabDisplay: 'Quoted',
                cardAction: CardActionType.Repair,
                height: '560',
            },
            {
                id: 'repairing',
                fieldName: 'repairStatus',
                value: 'Repairing',
                tabDisplay: 'Repairing',
                cardAction: CardActionType.Repair,
                height: '560',
            },
            {
                id: 'shippedInbound',
                fieldName: 'repairStatus',
                value: 'ShippedInbound',
                tabDisplay: 'Shipped Inbound',
                cardAction: CardActionType.Repair,
                height: '560',
            },
            {
                id: 'delivered',
                fieldName: 'repairStatus',
                value: 'Delivered',
                tabDisplay: 'Delivered',
                cardAction: CardActionType.Repair,
                height: '560',
            },
        ],
        gridColumnsMapping: [
            {
                fromColumn: 'description',
                toColumn: 'mainDescription'
            },
            {
                fromColumn: 'groupName',
                toColumn: 'repairStatusDesc'
            },
            {
                fromColumn: 'priority',
                toColumn: 'priorityDesc'
            },
            {
                fromColumn: 'created',
                toColumn: 'dateCreated'
            },
        ],
        categories: [
            {
                label: 'Initiated',
                statsKey: 'repairs_initiated_data',
                description: 'Depot repairs that have been purchased and our repair partner is awaiting the part',
            },
            {
                label: 'Repairing',
                statsKey: 'repairs_shipped_repairing_data',
                description: 'Total Depot Repairs that are in progress',
            },
            {
                label: 'Shipped Inbound',
                statsKey: 'repairs_shipped_in_bound_data',
                description: 'Total Depot Repairs that are currently in transit back to your facility',
            },
            {
                label: 'Delivered',
                statsKey: 'repairs_delivered_data',
                description: 'Total Depot Repairs that have arrived at your facility in the last 2 business days',
            },
        ],
    },
    {
        title: 'Loaners',
        statsKey: 'loaners_all_data',
        gridName: 'loanerColumns',
        gridKeys: [
            {
                id: 'requested',
                fieldName: 'groupName',
                value: 'Requested',
                tabDisplay: 'Requested',
                height: '560',
            },
            {
                id: 'quoted',
                fieldName: 'groupName',
                value: 'Quoted',
                tabDisplay: 'Quoted',
                height: '560',
                cardAction: CardActionType.LoanerQuoted
            },
            {
                id: 'shippedToYou',
                fieldName: 'groupName',
                value: 'ShippedInbound',
                tabDisplay: 'Shipped Inbound',
                height: '560',
            },
            {
                id: 'onLoan',
                fieldName: 'groupName',
                value: 'OnLoan',
                tabDisplay: 'On Loan',
                height: '560',
                cardAction: CardActionType.LoanerReturn
            },
            {
                id: 'shippedToVendor',
                fieldName: 'groupName',
                value: 'ShippedOutbound',
                tabDisplay: 'Shipped Outbound',
                height: '560',
            },
            {
                id: 'returned',
                fieldName: 'groupName',
                value: 'Returned',
                tabDisplay: 'Returned',
                height: '560',
            },
        ],
        categories: [
            {
                label: 'Initiated',
                statsKey: 'loaners_initiated_data',
                description: 'Total Loaners items on requested status',
            },
            {
                label: 'Shipped Inbound',
                statsKey: 'loaners_shipped_in_bound_data',
                description: 'Total Loaners items on shipped inbound status',
            },
            {
                label: 'On Loan',
                statsKey: 'loaners_on_loan_data',
                description: 'Total Loaners items on loan status',
            },
            {
                label: 'Shipped Outbound',
                statsKey: 'loaners_shipped_out_bound_data',
                description: 'Total Loaners items on shipped outbound status',
            },
        ],
        gridColumnsMapping: [
            {
                fromColumn: 'altStatus',
                toColumn: 'status'
            },
            {
                fromColumn: 'groupName',
                toColumn: 'status'
            },
            {
                fromColumn: 'created',
                toColumn: 'dateCreated'
            },
            {
                fromColumn: 'equipmentSerialNumber',
                toColumn: 'serialNumber'
            },
            {
                fromColumn: 'priority',
                toColumn: 'priorityDesc'
            },
            {
                fromColumn: 'repairLineItemId',
                toColumn: 'associatedLineItemId',
            }
        ]
    },
    {
        title: 'On-Site Service',
        statsKey: 'services_all_data',
        gridName: 'onSiteServiceColumns',
        gridKeys: [
            {
                id: 'initiated',
                fieldName: 'repairStatus',
                value: 'Initiated',
                tabDisplay: 'Initiated',
                cardAction: CardActionType.ServiceEvent,
                height: '560',
            },
            {
                id: 'shippedOutbound',
                fieldName: 'repairStatus',
                value: 'ShippedOutbound',
                tabDisplay: 'Shipped Outbound',
                cardAction: CardActionType.ServiceEvent,
                height: '560',
            },
            {
                id: 'evaluating',
                fieldName: 'repairStatus',
                value: 'Evaluating',
                tabDisplay: 'Evaluating',
                cardAction: CardActionType.ServiceEvent,
                height: '560',
            },
            {
                id: 'quoted',
                fieldName: 'repairStatus',
                value: 'Quoted',
                tabDisplay: 'Quoted',
                cardAction: CardActionType.ServiceEvent,
                height: '560',
            },
            {
                id: 'repairing',
                fieldName: 'repairStatus',
                value: 'Repairing',
                tabDisplay: 'Repairing',
                cardAction: CardActionType.ServiceEvent,
                height: '560',
            },
            {
                id: 'shippedInbound',
                fieldName: 'repairStatus',
                value: 'ShippedInbound',
                tabDisplay: 'Shipped Inbound',
                cardAction: CardActionType.ServiceEvent,
                height: '560',
            },
            {
                id: 'delivered',
                fieldName: 'repairStatus',
                value: 'Delivered',
                tabDisplay: 'Delivered',
                cardAction: CardActionType.ServiceEvent,
                height: '560',
            },
        ],
        categories: [
            {
                label: 'Initiated',
                statsKey: 'services_initiated_data',
                description: 'Depot service repairs that have been purchased and our repair partner is awaiting the part',
            },
            {
                label: 'Quoted',
                statsKey: 'services_quoted_data',
                color: 'red',
                description: 'Total Service Repair items on Quoted status',
            },
            {
                label: 'Open',
                statsKey: 'services_open_data',
                description: 'Total Depot Repairs that are currently in transit back to your facility',
            },
            {
                label: 'Critical Hard Down',
                statsKey: 'services_critical_hard_down_data',
                color: 'red',
                description: 'Total Service Repairs items that the requester has indicated are for a Critical Hard Down situation',
            },
        ],
        gridColumnsMapping: [
            {
                fromColumn: 'serviceInfo.serviceType',
                toColumn: 'serviceType'
            },
            {
                fromColumn: 'created',
                toColumn: 'dateCreated'
            },
            {
                fromColumn: 'equipmentSerialNumber',
                toColumn: 'serialNumber'
            },
            {
                fromColumn: 'priority',
                toColumn: 'priorityDesc'
            },
            {
                fromColumn: 'description',
                toColumn: 'mainDescription'
            }
        ]
    },
];
