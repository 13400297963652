import React from 'react';
import styled from 'styled-components';
import {Popup} from '@partssourceinc/react-ui-core';
import {getPartCondition, getPurchaseChoice, getLineItemWarranty} from 'productUtility';

const Detail = styled.div`
    margin-bottom: 5px;
`;

const Label = styled.span`    
    font-size: 16px;
    font-weight: 400;
`;

const Value = styled.span`
    font-size: 16px;
    font-weight: 600;
    margin-left: 5px;
`;

export function FormularyEvidenceItemDetailModal({onClose, data, oemNumber, manufacturer}) {  
    
    const partDescription = manufacturer ? `${data.partDescription} by ${manufacturer}` : data.partDescription;
    return (
        <Popup 
            className="formulary-modal"
            confirmText="Done"             
            show={true} 
            hideButtons={false}
            hideCancel={true} 
            onConfirm={onClose}
            onCancel={onClose}>
            <Detail>
                <Label>Item:</Label>
                <Value>{partDescription}</Value>
            </Detail>
            <Detail>
                <Label>OEM #:</Label>
                <Value>{oemNumber}</Value>
            </Detail>
            <Detail>
                <Label>Condition:</Label>
                <Value>{getPartCondition(data.condition)} {getPurchaseChoice(data.purchaseChoice)} Purchase</Value>
            </Detail>
            <Detail>
                <Label>Warranty:</Label>
                <Value>{getLineItemWarranty(data.lineItemWarranty)}</Value>
            </Detail>
        </Popup>
    );
}
