import React, {useState} from 'react';
import styled from 'styled-components';
import {Popup, Button} from '@partssourceinc/react-ui-core';
import {FormularyFilterDefinitionsModal, FormularyFacet} from '../';

const Container = styled.div`
    margin-left: -20px;
    margin-right: -20px;
`;

const Header = styled.div`
    padding-top: 15px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgb(151, 151, 151);
    position: relative;
    padding-left: 20px;
    padding-right: 20px;

    .reset-filters {
        float: right;
        font-weight: 400;                
        cursor: pointer;
        color: rgb(119,119,119);
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14px;
    }
`;

const Title = styled.div`    
    font-size: 24px;
    font-weight: 300;
    display: inline-block;
`;

const MetricDefinitions = styled.div`
    color: rgb(119,119,119);
    position: absolute;
    cursor: pointer;
    display: inline-block;
    left: 80px;
    top: 17px;

    .icon {
        border: 1px solid;
        border-radius: 50%;
        padding: 0px 5px;
        margin-right: 5px;
        font-size: 12px;
        font-weight: 600;
    }

    .definition-link {
        text-decoration: underline;
        cursor: pointer;
        font-weight: 400;
    }    
`;

const BodyWrapper = styled.div`
    height: calc(100vh - 240px);
    overflow: auto;    
`;

const Footer = styled.div`
    border-top: 1px solid rgb(119,119,119);
    padding-top: 24px;
    padding-bottom: 4px;
    padding-left: 20px;
    padding-right: 20px;

    button {
        width: 100%;
        padding: 30px 20px;
        font-size: 16px;
    }
`;

const ShowAllFilters = styled.div`
    text-decoration: underline;
    margin-top: 20px;
    color: rgb(119, 119, 119);
    cursor: pointer;
    padding-left: 20px;
`;

export function FormularyFacetsModal({facets, onChange, onClose, onConfirm, count, onClearFilters}) {  
    const [showAllFilters, setShowAllFilters] = useState(false);
    const [showFilterDefinitionsModal, setShowFilterDefinitionsModal] = useState(false);
        
    let validFacets = facets && facets.length > 0 ? facets.filter(f => f.results.length > 0) : [];
    let displayFacets = showAllFilters ? validFacets : _.take(validFacets, 5);

    return (
        <Popup 
            className="formulary-facet-modal"
            confirmText="Apply Filters"             
            show={true} 
            hideButtons={true}
            hideCancel={true}
            onConfirm={onConfirm}
            onCancel={onClose}>
            <Container>
                <Header>
                    <Title>Filter</Title> 
                    <MetricDefinitions onClick={() => setShowFilterDefinitionsModal(true)}>
                        <span className="icon">i</span>
                        <span className="definition-link">Definitions</span>                        
                    </MetricDefinitions>               
                    <span className="reset-filters" onClick={onClearFilters}>Clear Filters</span>
                </Header>
                <BodyWrapper>
                    {displayFacets.map((f, i) => <FormularyFacet facet={f} key={`form-facet-${i}`} onChange={onChange} isMobile={true} />)}  
                    {!showAllFilters && validFacets.length > 5 && <ShowAllFilters onClick={() => setShowAllFilters(true)}>Show All Filters</ShowAllFilters>}
                </BodyWrapper>
                <Footer>
                    <Button onClick={onClose}>{`Show Results (${count})`}</Button>
                </Footer>
            </Container>            
            {showFilterDefinitionsModal && <FormularyFilterDefinitionsModal onClose={() => setShowFilterDefinitionsModal(false)} /> }    
        </Popup>
    );
}
