import axios from 'axios';
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import {Button, Popup} from '@partssourceinc/react-ui-core';
import * as CartStore from 'stores/Cart';
import * as UserStore from 'stores/User';

@withRouter
@connect((state) => ({cart: state.cart, user: state.user}), CartStore.actionCreators)
export default class ApprovalPurchaseButton extends Component {
    static propTypes = {
        lineItemIds: PropTypes.array.isRequired,
        showLink: PropTypes.bool,
        buttonText: PropTypes.string,
        isDisabled: PropTypes.bool,
        cart: CartStore.StateShape,
        user: UserStore.StateShape,
        ...CartStore.ActionShape,
        ...ReactRouterPropTypes,
    };
  
    constructor(props) {
        super(props);
  
        this.state = {
            preparingCheckout: false,
            showMissingItemMsg: false,
        }
    }
  
    proceedToCheckout() {        
        this.setState({preparingCheckout: true});
        let {history, lineItemIds, onClick, proceedToCheckout} = this.props;
        
        if (onClick) {
            onClick();
        }
                
        proceedToCheckout(lineItemIds).then(proceedResult => {
            if (proceedResult.type === 'PROCEED_TO_CHECKOUT_RESP') {
                history.push({
                    pathname: '/checkout',
                    state: {
                        hideForApprovals: true,
                    },
                });
            } else {
                this.setState({showMissingItemMsg: true, preparingCheckout: false});
                return;
            }
        });
    }
  
    render () {
        const {preparingCheckout, showMissingItemMsg} = this.state;
        const {showLink, buttonText, isDisabled} = this.props;
        return (
            <React.Fragment>
                {
                    showLink ? <span onClick={() => !isDisabled ? this.proceedToCheckout() : ''} className={isDisabled || preparingCheckout ? 'disabled' : ''}>{buttonText || 'Purchase'}</span>
                        : (<Button secondary={true} onClick={() => !isDisabled ? this.proceedToCheckout() : ''} disabled={isDisabled} loading={preparingCheckout}>{buttonText || 'Purchase'}</Button>)
                }
                {showMissingItemMsg &&
                <Popup show={true} hideButtons={true} disableClose={true} onCancel={() => { }}>
                    <div>One or more of your items are no longer waiting for approval.  Please refresh and try again.</div>
                </Popup>
                }
            </React.Fragment>
        );
        
    }
}
