import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import {ProductImage} from '@partssourceinc/react-ui-core';
import {theme} from '@partssourceinc/react-ui-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';

const Card = styled.div`
    :not(:last-child) {
        margin-bottom: 10px;
    }
    
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid #cccccc;
    padding: 16px;
    display: flex;
    background: white;
`;

const CardLeftColumn = styled.div`
`;

const CardRightColumn = styled.div`
    margin-left: 16px;
`;

const Description = styled.div`
    font-size: 16px;
    font-weight: 700;
    padding-top: 4px;
`;

const Facility = styled.div`
    font-size: 16px;
    padding-top: 4px;
    font-weight: 600;
`;

const TimeStamp = styled.div`
    font-size: 12px;
    line-height: 12px;
    padding-top: 4px;
    color: #777777;
`;

const Category = styled.div`
    font-size: 12px;
    line-height: 12px;
    padding-top: 4px;
    color: #777777;
`;

const Neutral = styled.div`
    font-size: 16px;
`;

const Positive = styled.span`
    color: #17AB78;
    font-size: 16px;
    display: flex;
    align-items: center;
    
    span {
        margin-left: 5px;
    }

    img {
        width: 18px;
        height: 18px;
    }
`;

const Warning = styled.div`
    color: ${theme.red};
    font-size: 16px;
    display: flex;
    align-items: center;

    span {
        margin-left: 5px;
    }
`;

const ResolvedTitleBox = styled.div`
    border-radius: 3px;
    border: 1px solid rgb(220, 220, 220);
    padding: 3px 9px;
`;

const ResolvedLine = styled.div`
    color: rgb(0, 0, 0);
    font-size: 12px;
    font-weight: bold;
    height: 17px;
    letter-spacing: 0px;
    line-height: 18px;
`;

const ResolvedTitle = styled.div`
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 22px;
`;

const VerticalLign = styled.div`
    background: #cccccc;
    height: 27px;
    width: 1px;
    margin: 4px 7px;
`;

const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4px;
    margin-right: 8px;

    img {
        width: 18px;
        height: 18px;
        color: #17AB78;
    }
`;

const ExclamationIcon = styled(FontAwesomeIcon)`
    color: #ff0000;
    width: 18px;
    height: 18px;
`;

const UpdatesPanelCard = ({ev, onItemSelected}) => {
    const dispatch = useDispatch();

    const getIconContainer = () => {
        return (<IconContainer>
            <img src={`/images/icn_checkmark.png`} />
            <VerticalLign />
            <ExclamationIcon icon={faExclamationCircle} />
        </IconContainer>)
    }

    const getTitle = (is_resolution, resolution_id, event_code, event_message) => {
        if (is_resolution) {
            return (
                <div style={{marginBottom: '8px'}}>
                    <ResolvedTitleBox>
                        <ResolvedLine>RESOLVED</ResolvedLine>
                        <ResolvedTitle>{event_message}</ResolvedTitle>
                    </ResolvedTitleBox>
                </div>
            );
        } else {
            if (resolution_id) {
                return (
                    <div>
                        <ResolvedTitleBox>
                            <ResolvedTitle>{event_message}</ResolvedTitle>
                        </ResolvedTitleBox>
                    </div>
                );
            } else {
                switch (event_code) {
                    case 1: 
                        return (
                            <Warning>
                                <ExclamationIcon icon={faExclamationCircle} />
                                <span>{event_message}</span>
                            </Warning>
                        );
                    case 2: 
                        return (
                            <Positive>
                                <img src={`/images/icn_checkmark.png`} />
                                <span>{event_message}</span>
                            </Positive>);
                    default:
                        return (
                            <Neutral>
                                <span>{event_message}</span>
                            </Neutral>
                        );
                }
            }
        }
    };

    const getCategoryName = (event_type) => {
        switch (event_type) {
            case 1: 
                return 'Shipping Update';
            case 2: 
                return 'Customer Action Needed';
            case 3: 
                return 'Backorder Update';
            case 4: 
                return 'Service / Depot Repairs';
        }
    };

    const selectItem = () => {
        if (onItemSelected) {
            onItemSelected(ev.line_item_id);
        }
    };

    return (
        <Card onClick={selectItem}>
            <CardLeftColumn>
                <ProductImage
                    url={`${process.env.REACT_APP_ORION_API}/CatalogService/api/v1/updatesPanelImage/${ev.line_item_id}`}
                    width={64} />
            </CardLeftColumn>
            <CardRightColumn>
                <div style={{display: 'flex'}}>
                    {ev.resolution_id && getIconContainer()}
                    <div>
                        {ev.resolution_id && getTitle(true, ev.resolution_id, ev.resolution_event_code, ev.resolution_event_message)}
                        {getTitle(false, ev.resolution_id, ev.code, ev.event_message)}
                    </div>
                </div>
                <Description>{ev.description.length > 70 ? ev.description.substring(0, 67) + '...' : ev.description}</Description>
                <Facility hasResolutionId={!!ev.resolution_id}>{ev.company_name}</Facility>
                <TimeStamp hasResolutionId={!!ev.resolution_id}>{moment(ev.timestamp, moment.defaultFormat).format('MM/DD/YYYY @ hh:mm A')}</TimeStamp>    
                <Category hasResolutionId={!!ev.resolution_id}>{getCategoryName(ev.event_type)}</Category>
            </CardRightColumn>
        </Card>
    );
};

UpdatesPanelCard.propTypes = {
    ev: PropTypes.shape({
        line_item_id: PropTypes.string.isRequired,
        event_id: PropTypes.number.isRequired,
        event_message: PropTypes.string.isRequired,
        code: PropTypes.number.isRequired,
        company_id: PropTypes.number.isRequired,
        company_name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        timestamp: PropTypes.string.isRequired,
        resolution_id: PropTypes.number,
        resolution_event_message: PropTypes.string,
        resolution_event_code: PropTypes.string,
        part_number_stripped: PropTypes.string.isRequired,
    }).isRequired,
    
}

export default UpdatesPanelCard;
