import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';
import _ from 'lodash';
import {Popup, TextFieldTags, SwitchCheckbox,} from '@partssourceinc/react-ui-core';
import {toggleAutoRefresh, setShowUpdatesPanel} from 'stores/Dashboard';
import {setDashboardViewSettings} from 'stores/User';
import {loadOpsDashboardCategories, loadL1Categories} from 'stores/System';
import {logEvent} from 'utility';

import './filters.less';

const ClearButton = styled.button`
    {
        background: none;
        border: none;
        padding: 0 !important;
        text-decoration: underline;
        color: #8b8b8b;
    }
`;

const FiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    height: 87px;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.25);
    margin: -20px auto 0px;
    padding-left: 0px;

    
    @media (max-width: 820px) {
        display: none;
    }
`;

const DashboardCompanyLogo = styled.img`
`;

const DashboardCompanyName = styled.span`
    display: inline-flex;
`;

const FilterContainer = styled.span`
    margin-left: 15px;
    vertical-align: top;
    text-align: center;
    width: auto;
    color: rgb(158,158,158);

    @media (max-width: 820px) {
        display: none;
    }

    .material-icons {
        font-family: Material Icons;
        font-size: 14px;
        width: 14px;
    }
`;

const Filter = styled.span`
    display: inline-flex;
    cursor: pointer;
    font-weight: bold;
    color: black;
`;

const AutoRefreshFilter = styled.span`
    vertical-align: top;
    text-align: center;
    margin-right: 10px;
    margin-right: 0px;
    display: inline-flex;
    float: right;

    @media (max-width: 1150px) {
        display: none !important;
    }
`;

const LogoContainer = styled.span`
    display: flex;
    height: 35px;

    img {
        max-width: 100%;
    }

    @media (max-width: 850px) {
        display: none !important;
    }
`;

const UpdatesPanelDisplayContainer = styled.div`
    flex-direction: column;
    text-align: center;
    margin-right: 20px;
    display: flex;
    
    @media (max-width: 1150px) {
        display: none !important;
    }
`;

const UpdatesPanelFilterButton = styled.img`
    height: 28px;
    cursor: pointer;
`;

const RightFilters = styled.div`
    display: flex;
`;

const LeftFilters = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;

    @media (max-width: 850px) {
        flex-grow: 0;
    }
`;

const Filters = () => {
    const dispatch = useDispatch();
    const companyLogo = useSelector(state => state.user.companyLogo);
    const displayProLogo = useSelector(state => state.user.settings.displayProLogo);
    const rootCompanyName = useSelector(state => state.user.info.rootCompanyName);
    const autoRefresh = useSelector(state => state.dashboard.autoRefresh);

    const selectedCategories = useSelector(state => state.user.dashboardViewSettings.selectedCategories);
    const selectedModalities = useSelector(state => state.user.dashboardViewSettings.selectedModalities);
    const selectedFacilities = useSelector(state => state.user.dashboardViewSettings.selectedFacilities);
    const selectedRequesters = useSelector(state => state.user.dashboardViewSettings.selectedRequesters);

    const [tempSelectedRequesters, setTempSelectedRequesters] = useState({...selectedRequesters});
    const [tempSelectedFacilities, setTempSelectedFacilities] = useState({...selectedFacilities});
    const [tempSelectedModalities, setTempSelectedModalities] = useState({...selectedModalities});
    const [tempSelectedCategories, setTempSelectedCategories] = useState({...selectedCategories});

    const userFacilities = useSelector(state => state.user.facilities);
    const modalitiesList = useSelector(state => state.system.l1categories);
    const categoriesList = useSelector(state => state.system.opsDashboardCategories);

    const [requesters, setRequesters] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [modalities, setModalities] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showFilterDialog, setShowFilterDialog] = useState(null);

    const filterView = {
        Facilities: 'Facilities',
        Categories: 'Categories',
        Modality: 'Modality',
        Requesters: 'Requesters',
    }

    useEffect(() => {
        setTempSelectedCategories(selectedCategories);
        setTempSelectedFacilities(selectedFacilities);
        setTempSelectedModalities(selectedModalities);
        setTempSelectedRequesters(selectedRequesters);
    }, [selectedCategories, selectedFacilities, selectedModalities, selectedRequesters]);

    useEffect(() => {
        axios
            .get('/ShoppingService/api/v1/company/contacts/')
            .then(x => setRequesters(x.data.map(r => ({id: r.contactId, name: r.fullName}))));

        setFacilities(userFacilities.map(f => ({id: f.facilityId, name: f.facilityName})));

        if (categoriesList && categoriesList.length !== 0) {
            const cats = categoriesList.map(m => ({id: m.name.toUpperCase(), name: m.name.toUpperCase()}));
            cats.push({id: -2, name: 'OTHER'})
            setCategories(cats);
        }

        if (modalitiesList && modalitiesList.length !== 0) {
            let mods = modalitiesList.map(m => ({id: m.path, name: m.name}));
            if (!modalitiesList.find(m => m.path === '-2')) mods.push({id: '-2', name: 'Uncategorized Items'});
            setModalities(mods);
        }
    }, []);

    useEffect(() => {
        if (!modalitiesList || modalitiesList.length === 0) {
            dispatch(loadL1Categories());
        } else {
            let mods = modalitiesList.map(m => ({id: m.path, name: m.name}));
            if (!modalitiesList.find(m => m.path === '-2')) mods.push({id: '-2', name: 'Uncategorized Items'});
            setModalities(mods);
        }
    }, [modalitiesList]);

    useEffect(() => {
        if (!categoriesList || categoriesList.length === 0) {
            dispatch(loadOpsDashboardCategories());
        } else {
            const cats = categoriesList.map(m => ({id: m.name.toUpperCase(), name: m.name.toUpperCase()}));
            cats.push({id: -2, name: 'OTHER'})
            setCategories(cats);
        }
    }, [categoriesList]);

    const saveFilters = (filterType) => {
        let filters = {
            selectedRequesters: Object.keys(tempSelectedRequesters).map(key => ({id: key, name: tempSelectedRequesters[key]})),
            selectedModalities: Object.keys(tempSelectedModalities).map(key => ({id: key, name: tempSelectedModalities[key]})),
            selectedFacilities: Object.keys(tempSelectedFacilities).map(key => ({id: key, name: tempSelectedFacilities[key]})),
            selectedCategories: Object.keys(tempSelectedCategories).map(key => ({id: key, name: tempSelectedCategories[key]})),
        }

        if (filterType) {
            setShowFilterDialog(null);
            logEvent('DASHBOARD', {
                'Filters': filterType,
                'Selection': JSON.stringify(filters),
            });
        }

        dispatch(setDashboardViewSettings(filters));
    }

    const updateCategoriesDialog = (newValue) => {
        if (Object.keys(newValue).length === 0) {
            setTempSelectedCategories({'-1': 'All Categories'});
        } else {
            setTempSelectedCategories(_.omit(newValue, ['-1']));
        }
    }

    const updateModalitiesDialog = (newValue) => {
        console.log('newValue', newValue)
        if (Object.keys(newValue).length === 0) {
            setTempSelectedModalities({'-1': 'All Modalities'});
        } else {
            setTempSelectedModalities(_.omit(newValue, ['-1']));
        }
    }

    const updateFacilitiesDialog = (newValue) => {
        const oldFacilities = facilities;
        let facs = [...oldFacilities];

        const allFacilities = {id: -1, name: 'All Facilities'};
        if (Object.keys(newValue).length === 0) {
            setTempSelectedFacilities({'-1': 'All Facilities'});
            facs.push(allFacilities);
        } else {
            setTempSelectedFacilities(_.omit(newValue, ['-1']));
            facs = _.dropWhile(facilities, allFacilities);
        }
        setFacilities(facs);
    }

    const updateRequesterDialog = (newValue) => {
        const oldRequesters = requesters;
        let reqs = [...oldRequesters];

        const allRequesters = {id: -1, name: 'All Requesters'};
        if (Object.keys(newValue).length === 0) {
            setTempSelectedRequesters({'-1': 'All Requesters'});
            reqs.push(allRequesters);
        } else {
            setTempSelectedRequesters(_.omit(newValue, ['-1']));
            reqs = _.dropWhile(requesters, allRequesters);
        }

        setRequesters(reqs);
    }

    const clearFilters = () => {
        setTempSelectedFacilities({'-1': 'All Facilities'});
        setTempSelectedCategories({'-1': 'All Categories'});
        setTempSelectedModalities({'-1': 'All Modalities'});
        setTempSelectedRequesters({'-1': 'All Requesters'});

        let filters = {
            selectedRequesters: [{id: '-1', name: 'All Requesters'}],
            selectedModalities: [{id: '-1', name: 'All Modalities'}],
            selectedFacilities: [{id: '-1', name: 'All Facilities'}],
            selectedCategories: [{id: '-1', name: 'All Categories'}],
        }

        dispatch(setDashboardViewSettings(filters));
    }

    const halndleToggleAutoRefresh = () => {
        dispatch(toggleAutoRefresh());
    }

    const openUpdatesPanelMenu = () => {
        dispatch(setShowUpdatesPanel(true));
    }

    return (
        <FiltersContainer className="col-md-12">
            <LeftFilters>
                <UpdatesPanelDisplayContainer>
                    <div><UpdatesPanelFilterButton onClick={openUpdatesPanelMenu} src={require('/images/dashboard/icn_updates.png')} /></div>
                    <div><span style={{fontWeight: 'bold', fontSize: '8px', color: 'grey'}}>Updates</span></div>
                </UpdatesPanelDisplayContainer>
                <LogoContainer>
                    {companyLogo ?
                        <DashboardCompanyLogo
                            src={`data:image/jpeg;base64,${companyLogo}`}
                            alt="" />
                        : <DashboardCompanyName>
                            {rootCompanyName}
                        </DashboardCompanyName>}
                    {displayProLogo && <div className="pro-label">
                        <span>Pro</span>
                    </div>}
                </LogoContainer>
                <FilterContainer>
                Category:&nbsp;
                    <Filter onClick={() => setShowFilterDialog(filterView.Categories)}>
                        {selectedCategories['-1'] ? 'All' : 'Some'} &nbsp;
                        <span className="material-icons">create</span>
                    </Filter>
                </FilterContainer>
                <FilterContainer>
                    Modality:&nbsp;
                    <Filter onClick={() => setShowFilterDialog(filterView.Modality)}>
                        {selectedModalities['-1'] ? 'All' : 'Some'} &nbsp;
                        <span className="material-icons">create</span>
                    </Filter>
                </FilterContainer>
                {facilities.length > 0 && <FilterContainer>
                    Facility:&nbsp;
                    <Filter onClick={() => setShowFilterDialog(filterView.Facilities)}>
                        {selectedFacilities['-1'] ? 'All' : 'Some'} &nbsp;
                        <span className="material-icons">create</span>
                    </Filter>
                </FilterContainer>}
                {requesters.length > 0 && <FilterContainer>
                    Requester:&nbsp;
                    <Filter onClick={() => setShowFilterDialog(filterView.Requesters)}>
                        {selectedRequesters['-1'] ? 'All' : 'Some'}&nbsp;
                        <span className="material-icons">create</span>
                    </Filter>
                </FilterContainer>}
                <FilterContainer>
                    <ClearButton
                        onClick={() => clearFilters()}
                    >
                        Clear Filters
                    </ClearButton>
                </FilterContainer>
            </LeftFilters>
            <RightFilters>  
                <AutoRefreshFilter>
                    <SwitchCheckbox
                        label="Auto Refresh"
                        value={autoRefresh}
                        onChange={halndleToggleAutoRefresh}
                        className="auto-refresh-toggle"
                    />
                </AutoRefreshFilter>
            </RightFilters>
            {showFilterDialog === filterView.Categories && <Popup
                show={true}
                confirmText="Apply Filters"
                onConfirm={() => saveFilters(filterView.Categories)}
                onCancel={() => setShowFilterDialog(null)}
            >
                <h1>Filter by Category</h1>
                <TextFieldTags
                    listItems={categories}
                    value={tempSelectedCategories}
                    placeHolder="Select Categories"
                    onChange={updateCategoriesDialog}
                    keyField="id"
                    valueField="name"
                    useMultiSelect={true}
                />
                <div style={{height: 96}} />
            </Popup>}
            {showFilterDialog === filterView.Modality && <Popup
                show={true}
                confirmText="Apply Filters"
                onConfirm={() => saveFilters(filterView.Modality)}
                onCancel={() => setShowFilterDialog(null)}
            >
                <h1>Filter by Modality</h1>
                <TextFieldTags
                    listItems={modalities}
                    value={tempSelectedModalities}
                    placeHolder="Select Modalities"
                    onChange={updateModalitiesDialog}
                    keyField="id"
                    valueField="name"
                    useMultiSelect={true}
                />
                <div style={{height: 96}} />
            </Popup>}
            {showFilterDialog === filterView.Facilities && <Popup
                show={true}
                confirmText="Apply Filters"
                onConfirm={() => saveFilters(filterView.Facilities)}
                onCancel={() => setShowFilterDialog(null)}
            >
                <h1>Filter by Facility</h1>
                <TextFieldTags
                    listItems={facilities}
                    value={tempSelectedFacilities}
                    placeHolder="Select Facilities"
                    onChange={updateFacilitiesDialog}
                    keyField="id"
                    valueField="name"
                    useMultiSelect={true}
                />
                <div style={{height: 96}} />
            </Popup>}
            {showFilterDialog === filterView.Requesters && <Popup
                show={true}
                confirmText="Apply Filters"
                onConfirm={() => saveFilters(filterView.Requesters)}
                onCancel={() => setShowFilterDialog(null)}
            >
                <h1>Filter by Requester</h1>
                <TextFieldTags
                    listItems={requesters}
                    value={tempSelectedRequesters}
                    placeHolder="Select Requester(s)"
                    onChange={updateRequesterDialog}
                    keyField="id"
                    valueField="name"
                    useMultiSelect={true}
                />
                <div style={{height: 96}} />
            </Popup>}
            
        </FiltersContainer>
    );
};

Filters.propTypes = {
}

export default Filters;
