import React, { useState } from 'react';
import { ProductImage, QuantitySelector } from '@partssourceinc/react-ui-core';
import {formatMoney} from 'utility';
import 'less/initiateReturnHeader.less';

const InitiateReturnModalHeader = ({ partImage, unitPrice, quantity, uom, description, partNumber, refNum, detailUrl, handleQuantityChange }) => {    
    const quantityOptions = [ ...Array(quantity > 10 ? 10 : quantity).keys() ].map( i => ++i);
    const [selectedQuantity, setSelectedQuantity] = useState(1);

    const handleQuantityUpdate = ( val ) => {
        setSelectedQuantity(val);
        handleQuantityChange(val);
    }

    return (
        <div className="initiate-return-header">
            <div className="initiate-return-header_image">
                <ProductImage
                    url={partImage}
                    style={{width: '100px'}}
                />
            </div>

            <div className="initiate-return-header_description">
                <a href={detailUrl}>{description.substring(0, 150)}</a>
                <div>
                    <span>
                        <span>Item #: </span>
                        {partNumber}
                    </span>
                </div>
                <div>
                    <span>
                        <span>Reference: </span>
                        {refNum}
                    </span>
                </div>
            </div>

            <div className="initiate-return-header_price">
                <span className="lbl-bold">
                    {formatMoney(unitPrice)}{' '}
                </span>
                {uom && <span className="lbl-subtle"> /{uom}</span>}
            </div>

            <div className={quantity == 1 ? "initiate-return-header_quantity initiate-return-header_minimal-quantity" : "initiate-return-header_quantity"}>
                <span>Qty: </span>
                {quantity > 1 
                    ? <div><QuantitySelector id="qty" 
                        name="qty" 
                        options={quantityOptions}
                        selectedValue={selectedQuantity} 
                        isEditable={true}
                        maxQty={10}
                        onChange={e => handleQuantityUpdate(e.target.value) } />
                        {selectedQuantity > quantity && <span className='error'>Exceeds order quantity</span>}
                        </div>
                    : <span className="lbl-bold"> {quantity}</span>}
            </div>
        </div>
    )
}

export default InitiateReturnModalHeader;