import React, {useState} from 'react';
import * as PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const TooltipContainer = styled.div`
    z-index: 1;
`;

const TooltipItem = styled.span`
    margin-top: 2px !important;
    padding: 4px;
    color: black;
    position: absolute;
    margin-top: -10px;
    ${props => props.direction === 'right' && css`
        right: 45px;
    `}
    ${props => props.direction === 'left' && css`
        left: 45px;
    `}
    top: 21px;
    font-size: 12px;
    width: 206px;
    top: 27px;
    font-weight: 400;
    border: 1px solid #ccc;
    background: rgb(222 217 217);
    border-radius: 4px;
    z-index: 2;
`;

const KeyActionsToolTip = (props) => {
    const {children} = props;
    const [showTooltip, setShowTooltip] = useState(false);
    const {tooltipText, direction} = props;
    return (
        <TooltipContainer
            onMouseOver={() => {
                setShowTooltip(true)
            }} 
            onMouseOut={() => {
                setShowTooltip(false)
            }}
        >
            {children}
            {
                showTooltip && <TooltipItem direction={direction} className="keyactions_tooltip">{tooltipText}</TooltipItem>
            }
        </TooltipContainer>
    );
};

KeyActionsToolTip.propTypes = {
    tooltipText: PropTypes.string,
    value: PropTypes.string,
}

export default KeyActionsToolTip;
