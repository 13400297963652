import axios from 'axios';
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {Component} from 'react'

import {TextField, Button, Popup} from '@partssourceinc/react-ui-core';
import {formatMoney} from 'utility';

import 'less/submitPoForm.less';

export default class SubmitPoForm extends Component {
    static propTypes = {
        purchaseLines: PropTypes.array,
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
        showPopup: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            poNumber: '',
            isSaving: false,
        }
    }

    handlePoNumberChange(event) {
        this.setState({poNumber: event.target.value.trim()})
    }

     onSubmit = async () => {
         const {poNumber} = this.state;
         const {purchaseLines} = this.props;
         this.setState({isSaving: true});

         let lineItemArrays = purchaseLines.map(p => p.lineItemIds);
         let mergedLineItemIds = [].concat.apply([], lineItemArrays);

         let request = {
             poNumber: poNumber,
             references: mergedLineItemIds,
         };
         try {
             const response = await axios.post('/ShoppingService/api/v1/orders/submitPo', request);
             if (response.data) {
                 this.showPoConfirmation(mergedLineItemIds);
                 this.setState({isSaving: false});

             }
         } catch {

         }
     }

     showPoConfirmation(lineItemIds) {
         const {onSubmit} = this.props;
         axios.post('/ShoppingService/api/v1.0/orders/poConfirmation', lineItemIds, {responseType: 'arraybuffer', headers: {'Content-Type': 'application/json', 'Accept': 'application/pdf'}}).then(x => {
             const data = x.data;
             const file = new Blob([data], {type: 'application/pdf'});

             if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                 this.setState({poNumber: '', isSaving: false});
                 window.navigator.msSaveOrOpenBlob(file);
                 this.setState({poNumber: '', isSaving: false});
                 onSubmit();
                 return;
             }

             const fileURL = URL.createObjectURL(file);
             let link = document.createElement('a');
             link.type = 'hidden';
             link.href = fileURL;
             link.download = `${new Date().toTimeString().replace(/[\W_]+/g, '').substr(0,6)}.pdf`;
             document.body.appendChild(link);
             link.click();

             setTimeout(function () {
                 document.body.removeChild(link);
                 window.URL.revokeObjectURL(fileURL);
             }, 100);

             this.setState({poNumber: '', isSaving: false});
             onSubmit();
         });
     }

     onCancel() {
         const {onCancel} = this.props;
         onCancel();
     }

     render () {
         const {purchaseLines, showPopup} = this.props;
         const fees = _.sumBy(purchaseLines, 'fees');
         const price = _.sumBy(purchaseLines, x => {
             return x.price * x.quantity
         });
         const shipping = _.sumBy(purchaseLines, 'shipping');
         const tax = _.sumBy(purchaseLines, 'tax');
         const shipInsurance = _.sumBy(purchaseLines, 'shippingInsurance');
         const total = fees + price + shipping + shipInsurance + tax;

         const {poNumber, isSaving} = this.state;

         return (<div>
             {!showPopup ?
                 <React.Fragment>
                     <div className="submit-po-form">
                         <div className="submit-po-title">Summary</div>
                         <div className="price-item">Subtotal:<span>{formatMoney(price)}</span></div>
                         {fees > 0 && <div className="price-item">Fees:<span>{formatMoney(fees)}</span></div>}
                         <div className="price-item">Shipping/Processing:<span>{formatMoney(shipping)}</span></div>
                         <div className="price-item">Shipping Insurance:<span>{formatMoney(shipInsurance)}</span></div>
                         <div className="price-item">Tax:<span>{formatMoney(tax)}</span></div>
                         <div className="price-item-total">Total:<span>{formatMoney(total)}</span></div>
                         <div className="po-line">
                             <TextField id="poNumber" label="PO #" placeholder="PO #" text={poNumber}
                                 onChange={::this.handlePoNumberChange} />
                         </div>
                         {poNumber && purchaseLines.length > 0 ?
                             <Button loading={isSaving} onClick={this.onSubmit}>Place Purchase</Button> :
                             <Button disabled={true}>Place Purchase</Button>}
                     </div>
                 </React.Fragment> :
                 <React.Fragment>
                     <Popup confirmText="Place Purchase"
                         cancelText="Cancel"
                         show={true}
                         onConfirm={this.onSubmit}
                         onCancel={::this.onCancel}
                         className="submit-po-dialog"
                         loading={isSaving}
                         disableConfirm={!poNumber}>
                         <div className="submit-po-form">
                             <div className="submit-po-title">Summary</div>
                             <div className="price-item">Subtotal:<span>{formatMoney(price)}</span></div>
                             {fees > 0 && <div className="price-item">Fees:<span>{formatMoney(fees)}</span></div>}
                             <div className="price-item">Shipping/Processing:<span>{formatMoney(shipping)}</span></div>
                             <div className="price-item">Shipping Insurance:<span>{formatMoney(shipInsurance)}</span></div>
                             <div className="price-item">Tax:<span>{formatMoney(tax)}</span></div>
                             <div className="price-item-total">Total:<span>{formatMoney(total)}</span></div>
                             <div className="po-line">
                                 <TextField id="poNumberDialog" label="PO #" placeholder="PO #" text={poNumber}
                                     onChange={::this.handlePoNumberChange} />
                             </div>
                         </div>
                     </Popup>
                 </React.Fragment>
             }
         </div>)
     }
}
