import React from 'react';
import styled from 'styled-components';
import {Popup} from '@partssourceinc/react-ui-core';

const Container = styled.div`
    @media(max-width: 549px){
        height: calc(100vh - 200px);
        overflow: auto;
        padding-top: 24px;
    }
`;

const Title = styled.div`
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 300;
`;

const SectionWrapper = styled.div`
    margin-bottom: 32px;
`;

const SectionHeader = styled.div`
    img {
        width: 30px;
        position: relative;
        top: -3px;
    };

    span {
        margin-left: 8px;
        font-size: 20px;
    }
`

const SectionLabel = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
    margin-left: 38px;
    margin-top: 16px;
`;

const Description = styled.div`
    font-size: 16px;
    margin-left: 38px;
`;

export function FormularyValueDefinitionsModal({onClose}) {  
    
    return (
        <Popup 
            className="formulary-modal"
            confirmText="Done"             
            show={true} 
            hideButtons={false}
            hideCancel={true} 
            onConfirm={onClose}
            onCancel={onClose}>
            <Container>
                <Title>Value & Data Definitions</Title>
                <SectionWrapper>
                    <SectionHeader>
                        <img src="/images/formulary/icn_active.png" />
                        <span>Active</span>
                    </SectionHeader>
                    <SectionLabel>Active Rules</SectionLabel>
                    <Description>Active Formulary Rules (toggled ON) for your Health Systems</Description>
                    <SectionLabel>Inactive Rules</SectionLabel>
                    <Description>Inactive Formulary Rules (toggled OFF) for your Health Systems</Description>
                </SectionWrapper>
                <SectionWrapper>
                    <SectionHeader>
                        <img src="/images/formulary/icn_cost.png" />
                        <span>Savings/Pro forma</span>
                    </SectionHeader>
                    <SectionLabel>Actual YTD Savings</SectionLabel>
                    <Description>Actual YTD Savings from Active Formulary Rules at the time of purchase</Description>
                    <SectionLabel>Pro forma Savings</SectionLabel>
                    <Description>Potential Savings from turning ALL Company Formulary Rules ON</Description>
                    <SectionLabel>Total Proposed Pro forma</SectionLabel>
                    <Description>Potential Savings from all Company Formulary Rules + Expert Recommendations that are turned OFF</Description>
                </SectionWrapper>
                <SectionWrapper>
                    <SectionHeader>
                        <img src="/images/formulary/icn_timeframe-filter.png" />
                        <span>Timeframe Filter</span>
                    </SectionHeader>
                    <SectionLabel>Annual</SectionLabel>
                    <Description>365 Days</Description>
                    <SectionLabel>Quarter</SectionLabel>
                    <Description>90 Days</Description>
                    <SectionLabel>Month</SectionLabel>
                    <Description>30 Days</Description>
                    <SectionLabel>Thru End of Year</SectionLabel>
                    <Description>Current day to December 31</Description>
                </SectionWrapper>
            </Container>
        </Popup>
    );
}
