import DashboardCards from 'data/DashboardCards';
import {mapValues} from 'lodash';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import {getCookie, logEvent} from 'utility';

const Tense = {
    PAST: 0,
    PRESENT: 1,
    FUTURE: 2,
};

function phraseFactory(noun, prepositionalPhrase) {
    return count => `${count === 1 ? noun : `${noun}s`} ${prepositionalPhrase}`;
}

const NO_ORDERS = 'no_orders';

const HumanReadableMetrics = {
    [NO_ORDERS]: {
        [Tense.PAST]: 'No items',
        [Tense.PRESENT]: 'No items',
        [Tense.FUTURE]: 'No items',
    },
    'orders_delivered_count': {
        [Tense.PAST]: phraseFactory('Item', 'Delivered'),
        [Tense.PRESENT]: phraseFactory('Item', 'Out for Delivery'),
        [Tense.FUTURE]: phraseFactory('Item', 'Pending Delivery'),
    },
    'repairs_delivered_count': {
        [Tense.PAST]: phraseFactory('Repair', 'Delivered'),
        [Tense.PRESENT]: phraseFactory('Repair','Out for Delivery'),
        [Tense.FUTURE]: phraseFactory('Repair','Pending Delivery'),
    },
    'exchanges_due_rolling_count': {
        [Tense.PAST]: phraseFactory('Exchange', 'Not Shipped'),
        [Tense.PRESENT]: phraseFactory('Exchange', 'Due'),
        [Tense.FUTURE]: phraseFactory('Exchange', 'Due'),
    },
    'returns_due_rolling_count': {
        [Tense.PAST]: phraseFactory('Return', 'Not Shipped'),
        [Tense.PRESENT]: phraseFactory('Return', 'Due'),
        [Tense.FUTURE]: phraseFactory('Return', 'Due'),
    },
    'orders_closed_count': {
        [Tense.PAST]: phraseFactory('Item', 'Ordered'),
        [Tense.PRESENT]: phraseFactory('Item', 'Ordered'),
        // You cannot make an order in the future - we leave this tense out intentionally
    },
};

const CardCategories = {
    'orders_delivered_count': 'Open Orders',
    'orders_delivered_line_item_ids': 'Open Orders',
    'repairs_delivered_count': 'Repairs',
    'repairs_delivered_line_item_ids': 'Repairs',
    'exchanges_due_rolling_count': 'Exchanges & Returns',
    'exchanges_due_rolling_line_item_ids': 'Exchanges & Returns',
    'returns_due_rolling_count': 'Exchanges & Returns',
    'returns_due_rolling_line_item_ids': 'Exchanges & Returns',
    'orders_closed_count': 'Open Orders',
    'orders_closed_line_item_ids': 'Open Orders',
};

function shouldRender(metric, tense, count) {
    return HumanReadableMetrics[metric] && HumanReadableMetrics[metric][tense] && count > 0;
}

function toArray(packetValue) {
    return typeof(packetValue) === 'string' ? packetValue.split(',').filter(x => !!x) : [];
}

export default function CalendarCarouselCard(props) {
    const {selectedRequesters, selectedFacilities} = useSelector(state => state.user.dashboardViewSettings);
    const {date, metrics = [], setLineItems} = props;

    const now = moment();
    let tense;
    if (date.isBefore(now, 'day')) tense = Tense.PAST;
    else if (date.isSame(now, 'day')) tense = Tense.PRESENT;
    else /* if (date.isAfter(now, 'day')) */ tense = Tense.FUTURE;

    async function onCardClick() {
        logEvent('DASHBOARD', {
            id_ins: getCookie('id_ins'),
            'Type': 'Date Click',
            'Date': moment(date).format('MM/DD/YYYY'),
        });

        const lineItems = Object.entries(metrics)
            .filter(([metric, lineItems]) => /_line_item_ids$/.test(metric) && !!lineItems && !!CardCategories[metric])
            .flatMap(([metric, lineItems]) => {
                const {gridKeys, cardAction} = DashboardCards.find(c => c.title === CardCategories[metric]);
                const rgaTypeIds = toArray(metrics[metric.replace('_line_item_ids', '_rga_type_ids')]);
                return lineItems
                    .split(',')
                    .filter(x => !!x)
                    .map((id, i) => ({id, cardAction, gridKeys, rgaTypeId: rgaTypeIds[i]}));
            });
        await setLineItems(lineItems);
    }

    const filteredData = mapValues(metrics, (_, metric) => {
        const lineItemIds = toArray(metrics[metric.replace('_count', '_line_item_ids')]);
        const requestorIds = toArray(metrics[metric.replace('_count', '_requestor_ids')]);
        const companyIds = toArray(metrics[metric.replace('_count', '_company_ids')]);

        return lineItemIds
            .filter((id, i) =>
                (selectedRequesters['-1'] || selectedRequesters[requestorIds[i]])
                && (selectedFacilities['-1'] || selectedFacilities[companyIds[i]]))
            .length;
    })

    return (
        <div className={location.pathname === '/command-center/' ? 'carousel-card' : 'carousel-card dashboard'} onClick={onCardClick}>
            <div className="date">
                <span className="day-of-week">{date.format('dddd')}</span>
                <span className="month-day">{date.format('MMMM D')}</span>
            </div>
            <div className="stats">
                {Object.entries(filteredData).map(([metric, count]) => {
                    async function onMetricClick(e) {
                        e.stopPropagation();
                        const {gridKeys, cardAction} = DashboardCards.find(c => c.title === CardCategories[metric]);
                        const rgaTypeIds = toArray(metrics[metric.replace('_count', '_rga_type_ids')]);
                        const lineItems = toArray(metrics[metric.replace('_count', '_line_item_ids')])
                            .map((id, i) => ({id, gridKeys, cardAction, rgaTypeId: rgaTypeIds[i]}));
                        await setLineItems(lineItems);
                    }

                    return shouldRender(metric, tense, count) && (
                        <div className="stat-row" key={metric} onClick={onMetricClick}>
                            <span className="count">{count}</span>
                            <span className="description">{HumanReadableMetrics[metric][tense](count)}</span>
                        </div>
                    )
                })}
                {Object.entries(filteredData).every(([metric, count]) => !shouldRender(metric, tense, count)) && (
                    <div className="no-stats" onClick={() => setLineItems([], [], '')}>
                        {HumanReadableMetrics[NO_ORDERS][tense]}
                    </div>
                )}
            </div>
        </div>
    );
}

CalendarCarouselCard.propTypes = {
    date: PropTypes.instanceOf(moment).isRequired,
    metrics: PropTypes.object.isRequired,
    setLineItems: PropTypes.func,
};
