import axios from 'axios';
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {TextField, Popup} from '@partssourceinc/react-ui-core';
import {ProductImage} from '@partssourceinc/react-ui-core';
import {formatMoney} from 'utility';
import styled from 'styled-components';

import 'less/rejectApprovalDialog.less';

export default class BuyingOptionsDialog extends Component {
    static propTypes = {
        items: PropTypes.array.isRequired,
        onReject: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        imagePath: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            showErrors: false,
            rejectReason: '',
        }
    }

    onReject() {
        const {rejectReason} = this.state;
        const {items, onReject} = this.props;

        if (!rejectReason) {
            this.setState({showErrors: true});
            return;
        }

        this.setState({saving: true});
        let request = {
            lineItemIds: items.map(x => x.lineItemId),
            reason: rejectReason,
            facilityId: items[0].facilityId,
            formularyLineItemIds: items.filter(x => (x.formularyFields || []).length > 0).map(x => x.lineItemId),
        };
        axios.post('/ShoppingService/api/v1/approval/reject', request).then(onReject);
    }

    renderItem(item) {
        let o = item.options.find(x => x.isSelected);

        return (<div className="reject-option-item">
            <div className="product-image"><ProductImage style={{width: '120px'}} url={item.imagePath} altText={item.imageAlt} /></div>
            <div className="pInfo">
                <div className="description">{o.description}</div>
                <div className="info">
                    Part Number#:<span>{o.partNumber}</span>
                </div>
            </div>
            <div className="product-price">{formatMoney(o.price)}<span>/{o.uom}</span></div>
            <div className="product-qty">Qty:<span>{item.quantity}</span></div>
        </div>)
    }

    render () {
        const {saving, rejectReason, showErrors} = this.state;
        const {items, onCancel} = this.props;

        return (<Popup confirmText="Reject"
            cancelText="Cancel"
            show={true}
            onConfirm={::this.onReject}
            onCancel={onCancel}
            className="reject-approval-dialog"
            loading={saving}>
            <div>
                <div className="reject-title">Reject</div>
                {items.map((o, i) => this.renderItem(o))}
                <div className="reject-reason">
                    <TextField multiLine={true} rows={5}
                        label="Reason for Rejection"
                        placeholder="Reason for Rejection"
                        showErrorMessage={showErrors && !rejectReason}
                        required={true}
                        onChange={(e) => this.setState({rejectReason: e.target.value})}
                    />
                </div>
            </div>
        </Popup>)
    }
}
