import axios from 'axios';
import moment from 'moment';
import {Calendar} from 'primereact/components/calendar/Calendar';
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {TextField,Popup, Dropdown} from '@partssourceinc/react-ui-core';
import Datepicker from './Datepicker';
import 'less/trackingnumberentrydialog.less';

export default class TrackingNumberEntryDialog extends Component {
    static propTypes = {
        lineItemId: PropTypes.number.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        rga: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            showErrors: false,
            shipDate: '',
            carrier: '',
            trackingNumber: '',
            carrierOptions: [
                {value: 1, text: 'FedEx'},
                {value: 2, text: 'UPS'},
            ],
            invalidShipDate: false,
        }
    }

    onSave() {
        const {shipDate, trackingNumber, carrier} = this.state;
        const {rga, onSave} = this.props;

        if (!shipDate || !trackingNumber || !carrier || shipDate === 'Invalid date') {
            this.setState({showErrors: true});
            return;
        }

        this.setState({saving: true});

        const CUSTOMER_TO_VENDOR_SHIP_LEG = true
        let request = {
            rgaId: rga.rgaId,
            lineItemId: rga.lineItemId,
            carrier: carrier,
            trackingNumber: trackingNumber,
            shipDate: shipDate,
            directShipment: CUSTOMER_TO_VENDOR_SHIP_LEG,
        };

        axios.post('/ShoppingService/api/v1/repairs/addTracking', request).then(() => {
            onSave();
        });
    }

    onShipChange(e) {
        let formattedDate;
        if (e.value) {
            formattedDate = moment(e.value).format('L');
            this.setState({shipDate: formattedDate});
        } else {
            formattedDate = moment(e.originalEvent.target.value).format('L');
            this.setState({shipDate: formattedDate});
        }
    }

    render () {
        const {saving, showErrors, carrier, trackingNumber, carrierOptions, shipDate} = this.state;
        const {onCancel} = this.props;
        const invalidShipDate = !shipDate || shipDate === 'Invalid date';
        return (<Popup confirmText="Save"
            cancelText="Cancel"
            show={true}
            onConfirm={::this.onSave}
            onCancel={onCancel}
            className="tracking-number-dialog"
            loading={saving}>
            <div>
                <h1>Enter Tracking Number</h1>
                <div className={showErrors && invalidShipDate ? 'ship-date-wrapper error' : 'ship-date-wrapper'}>
                    <i className="fa fa-calendar" aria-hidden="true" />
                    <Calendar placeholder="Ship Date" onChange={::this.onShipChange} showIcon={false} />
                </div>
                <div className="shipping-method-wrapper">
                    <Dropdown id="carrier" name="carrier" label={'Ship Method'} showErrorMessage={showErrors && !carrier}
                        options={carrierOptions} selectedValue={carrier}
                        onChange={(e) => this.setState({carrier: e.target.value})} tabIndex={0} />
                </div>
                <div className="tracking-number-wrapper">
                    <TextField multiLine={true}
                        label="Tracking Number"
                        placeholder="Tracking Number"
                        showErrorMessage={showErrors && !trackingNumber}
                        onChange={(e) => this.setState({trackingNumber: e.target.value})} />
                </div>
            </div>
        </Popup>)
    }
}