import moment from 'moment';
import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import {logEvent, getTrackingNumbersUrlsData, getPODLetter} from 'utility';
import $ from 'jquery';

import * as NetworkStore from 'stores/Network';
import * as UserStore from 'stores/User';

import 'less/trackingpanel.less';

@connect((state) => ({user: state.user, network: state.network, sessionStorageAvailable: state.network.sessionStorageAvailable}), UserStore.actionCreators)
export default class TrackingPanel extends React.Component {
    static propTypes = {
        show: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        lineItemId: PropTypes.number.isRequired,
        hasPODLetter: PropTypes.bool,
        ...UserStore.ActionShape,
        user: UserStore.StateShape,
        network: NetworkStore.StateShape,
    };

    static defaultProps = {
        show: false,
        status: '',
        lineItemId: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            events: [],
            trackDetailResult: [],
            loaded: false,
        };
    }

    componentWillMount = () => {
        const {history, location, network: {isLoggedIn}, sessionStorageAvailable} = this.props;

        if (!isLoggedIn) {
            if (sessionStorageAvailable)
                sessionStorage.prevUrl = location.pathname;
            history.push('/login');
        } else {
            this.loadLineItemEvents();
        }
    }

    componentDidMount = () => {
        const {show} = this.props;
        if (show) {
            this.openTrackingPanel();
        }
    }

    componentWillUnmount = () => {
        this.closeTrackingPanel();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.show) {
            this.openTrackingPanel();
        } else {
            this.closeTrackingPanel();
        }
    }

    openTrackingPanel = () => {
        $(document.body).addClass('modal-open');
    }

    closeTrackingPanel = () => {
        $(document.body).removeClass('modal-open');
    }

    onCloseTrackingPanel = () => {
        const {onClose} = this.props;
        this.closeTrackingPanel();
        onClose();
    }

    loadLineItemEvents = () => {
        const {lineItemId, getLineItemEvents} = this.props;

        getLineItemEvents(lineItemId).then(({response: {data}}) => {
            this.setState({ 
                events: data ? data.history || [] : [],
                trackDetailResult: data ? data.trackDetailResult || [] : [],
                loaded: true,
            });
        });
    }

    renderEvent = (event) => {
        return (<li className={`StepProgress-item ${event.isNext ? 'is-next' : 'is-done'} ${event.current ? 'current' : ''}`}>
            <span>{moment(event.timeStamp).format('MMMM Do YYYY, h:mm a')}</span>
            <span className="status">{event.description}</span>
            {event.location && <span>{event.location}</span>}
        </li>)
    }

    render() {
        const {show, lineItemId, hasPODLetter, user: {settings}} = this.props;
        let {events, loaded, trackDetailResult} = this.state;

        if (!loaded) {
            return null;
        }

        let trackingNumber = events && events.length && events.find(e => e.eventType == 'Tracking' && e.trackingNumber !== null);
        let recentEvent = events && events.length && events[0];

        if (trackingNumber && (!recentEvent.description ||
            !events.some(o => o.description.toLowerCase().includes('check back for eta')) ||
            !events.some(o => o.description.toLowerCase().includes('delivered')))) {
            events.unshift({ timeStamp: new Date(), description: 'Check back for ETA', isNext: true });
        } else if (trackingNumber) {
            recentEvent.current = true;
        }

        let currentStatus = null;
        if (trackingNumber && trackingNumber.description.toLowerCase().includes('delivered')) {
            currentStatus = trackingNumber.description;
        } else {
            currentStatus = recentEvent ? recentEvent.description : null;
        }
        let urlsData = [];

        if (trackDetailResult) {
            urlsData = getTrackingNumbersUrlsData(trackDetailResult.trackingNumbers, lineItemId);
        }

        return show && <div id="tracking-panel" className="tracking-panel open">
            <div className="overlay" onClick={this.onCloseTrackingPanel} />
            <div className="content">
                <div className="close" onClick={this.onCloseTrackingPanel}>X</div>
                <div className="tracking-panel-header">{`Ref #${lineItemId} Tracking Details`}</div>
                {urlsData && urlsData.length > 0 && <div className="tracking">Tracking #
                    {urlsData && urlsData.map((urlData, index) => {
                        return <a target="_blank" rel="noreferrer noopener" href={`${urlData.url}`}>{urlData.number} </a>
                    })}
                </div>}
                {((urlsData == null) || (urlsData && urlsData.length < 1)) && <div className="tracking">Tracking number unavailable</div>}
                {settings.proofOfDeliveryLinks && currentStatus.toLowerCase().includes('delivered') && hasPODLetter ? <div className="download-pod" onClick={() => getPODLetter(lineItemId, this.props.network.tokenInfo, "TrackingPanel" )}><i className="fa fa-file-text-o" style={{paddingRight: '5px'}} />Proof of Delivery</div> : null}
                <div className="status-lbl">STATUS:</div>
                <div className="status-desc">{currentStatus}</div>
                <div className="wrapper">
                    <ul className="StepProgress">
                        {events.map(this.renderEvent)}
                    </ul>
                </div>
            </div>
        </div>;
    }
}
