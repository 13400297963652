import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Checkbox} from '@partssourceinc/react-ui-core';
import _ from 'lodash';

const Container = styled.div`
margin-bottom: 24px;

.custom-checkbox-container {
    label {
        line-height: 20px;
        margin-top: 5px;
        font-size: 14px;
  
        div:last-child{
          display: none;
        }
        span:first-child {
          margin-left: 0px !important;
          padding-left: 0px !important;
        }
        span:nth-child(3) {
          width: 14px;
          height: 14px;
  
          &:after {
            height: 8px;
            left: 3px;
            top: 1px;
            width: 5px;
            border-width: 0 2px 2px 0;
          }
        }
        > span:first-child {
            position: relative;
            top: -4px;
            font-weight: 600;
        
            .lbl-subtle{
              color: @grey3;
              font-weight: 700;
            }
          }
    }
}

.lbl-info{
  padding-left: 20px;
  display: inherit;
  font-size: 12px;
  font-weight: 400;
}
`
const FacetName = styled.div`
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 700;
    margin-bottom :10px;
`;

const SearchWithin = styled.div`
    .form-control{
    margin-bottom: 15px;
    font-size: 14px;
    padding-right: 30px;
    border: 1px solid #000;
    height: 39px;

    &:focus {
        border: 1px solid #000000;
        box-shadow: none;
      }
    }

    .fa {
        position: relative;
        float: right;
        top: -41px;
        right: 10px;
        color: #949494;
        cursor: pointer;
      }
`;

const ShowMore = styled.div`
  color: #777;
  font-size: 12px;
  margin-top: 2px;
  margin-left: 20px;
`;

const MobileContainer = styled.div`
  height: 50px; 
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom:  1px solid rgb(151, 151, 151);

  &.open {
    height: auto;
    padding-bottom: 24px;
    background-color: #f1f1f1;
  }
`;

const MobileHeader = styled.div`  
  padding-top: 16px;
  padding-bottom: 16px;  
  cursor: pointer;

    &::after {
      content: "\\F054";
      font-family: FontAwesome;
      font-weight: 400;
      font-style: normal;
      margin-right: 5px;
      font-size: 10px;
      color: #949494;
      float: right;
      margin-top: -17px;
    }

    &.open {
      &:: after {
        content: "\\F078";
      }
    }
`;

const MobileFacetName = styled.div`
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 700;    
`;

const MobileFacet = styled.div`
  border-top: 1px solid #ccc;
  padding-bottom: 15px;
`;

const MobileShowMore = styled.div`
  color: #777;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 45px;
`;

export function FormularyFacet({facet, onChange, isMobile}) {    

    const {results} = facet;
    const [displayResults, setDisplayResults] = useState(facet.results);
    const [searchFilter, setSearchFilter] = useState('');
    const [displayLimit, setDisplayLimit] = useState(facet.displayLimit || 10);
    const [isOpen, setIsOpen] = useState(false);

    const countClass = facet.hideCount ? 'hide' : '';

    const onInputChange = (e) => {
        setSearchFilter(e.target.value.toLowerCase());
    }

    useEffect(() => {
        setDisplayResults(results.filter(f => _.includes(f.range.toLowerCase(), searchFilter)));        
    }, [searchFilter, facet.results]);

    const onFacetCheckChange = (checkArgs, f, item) => {
        onChange({isSelected: checkArgs.checked, facetName: f.name, range: item.range});
    }

    return (
        <React.Fragment>
            {isMobile ? 
                <MobileContainer className={isOpen ? 'open' : ''}>
                    <MobileHeader className={isOpen ? 'open' : ''} onClick={() => setIsOpen(!isOpen)}>
                        <MobileFacetName>{facet.name}</MobileFacetName>
                    </MobileHeader>                      
                    {facet.showFilterBox && <SearchWithin>
                        <input className="form-control" placeholder={`Search ${facet.name}`} value={searchFilter} onChange={onInputChange} />
                        {searchFilter && <i className="fa fa-times-circle" aria-hidden="true" onClick={() => setSearchFilter('')} />}
                    </SearchWithin>}
                    {_.slice(displayResults, 0, displayLimit)
                        .map((f, i) => (<MobileFacet key={i}><Checkbox key={`chk-${facet.range}_${i}`}
                            label={`${f.range}<span class="lbl lbl-subtle ${countClass}">&nbsp;&nbsp;(${f.count})</span>`}
                            onChange={(args) => onFacetCheckChange(args, facet, f)}
                            className={'custom-checkbox-container'} checked={f.isSelected} />
                        </MobileFacet>))}
                    {displayResults.length > displayLimit && <MobileFacet><MobileShowMore onClick={() => setDisplayLimit(displayLimit + 10)}>{`Show more (${displayResults.length - displayLimit})`}</MobileShowMore></MobileFacet>}                                 
                </MobileContainer> :
                <Container>
                    <FacetName>{facet.name}</FacetName>
                    {facet.showFilterBox && <SearchWithin>
                        <input className="form-control" placeholder={`Search ${facet.name}`} value={searchFilter} onChange={onInputChange} />
                        {searchFilter && <i className="fa fa-times-circle" aria-hidden="true" onClick={() => setSearchFilter('')} />}
                    </SearchWithin>}
                    {_.slice(displayResults, 0, displayLimit)
                        .map((f, i) => (<div key={i}><Checkbox key={`chk-${facet.range}_${i}`}
                            label={`${f.range}<span class="lbl lbl-subtle ${countClass}">&nbsp;&nbsp;(${f.count})</span>`}
                            onChange={(args) => onFacetCheckChange(args, facet, f)}
                            className={'custom-checkbox-container'} checked={f.isSelected} />
                        </div>))}
                    {displayResults.length > displayLimit && <ShowMore onClick={() => setDisplayLimit(displayLimit + 10)}>{`Show more (${displayResults.length - displayLimit})`}</ShowMore>}
                </Container>    
            }
        </React.Fragment>
        
    );
}

