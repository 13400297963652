import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
   background-color: rgb(208, 237, 252);
   font-weight: 600;
   font-size: 16px;
   padding: 16px;

   .submit {
    color: #777;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 20px;
    float: right;
    cursor: pointer;

    &:hover {
        color: black;
    }
   }

   .reset {
    color: #777;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    float: right;
    cursor: pointer;

    &:hover {
        color: black;
    }
   }

   @media(max-width: 1149px) {
       margin-bottom: 24px;
   }
`;

export function FormularyPendingChanges({count, onReset, onSave}) {  
    if (count === 0) return null;

    return (
        <Container>
            <span>{`(${count}) rule change${count > 1 ? 's' : ''}`}</span>
            <span className="reset" onClick={onReset}>Cancel/Reset</span>
            <span className="submit" onClick={onSave}>Submit</span>            
        </Container>
    );
}
