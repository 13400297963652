import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as PropTypes from 'prop-types';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import {theme} from '@partssourceinc/react-ui-core';
import _ from 'lodash';

import * as DashboardStore from 'stores/Dashboard';
import {classNames, slugify} from 'utility';

import './dashboard-panel.less';

const CardContainer = styled.div`
    max-width: 12.5%;
    padding: 0;
    flex: 1 1 100%;

    @media (min-width: ${theme.screenSMMin}) {
        max-width: 50%;
        flex: 1 1 auto;
        min-width: 0;
    }

    @media (min-width: ${theme.screenXLMin}) {
        max-width: 16.6%;
    }

    @media (max-width: 550px) {
        max-width: 100%;
    }
`;

@withRouter
@connect(state => ({dashboard: state.dashboard, user: state.user}), DashboardStore.actionCreators)
export default class DashboardPanel extends React.Component {
    static propTypes = {
        allData: PropTypes.arrayOf(PropTypes.shape({
            LINE_ITEM_ID: PropTypes.number,
        })).isRequired,
        title: PropTypes.string.isRequired,
        categories: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            orders: PropTypes.arrayOf(PropTypes.shape({
                LINE_ITEM_ID: PropTypes.number,
            })),
            color: PropTypes.string,
            url: PropTypes.string,
            description: PropTypes.string.isRequired,
        })).isRequired,
        selectedLabel: PropTypes.string.isRequired,
        dashboard: DashboardStore.StateShape,
        ...ReactRouterPropTypes,
        ...DashboardStore.ActionShape,
    };
    
    constructor(props) {
        super(props);
        
        const {categories} = props;
        this.state = _.fromPairs(_.range(categories.length).map(i => [`hoveringLabel${i}`, false]));
        this.state.active = false;
    }

    selectContainer(orders, title, i) {
        const {onClick} = this.props;
        this.setState({active: true})
        onClick(orders, title, i);
    }

    render() {
        const {allData, title, categories, dashboard, user: {settings}, onClick, selectedLabel} = this.props;
        const state = this.state;
        const loading = dashboard.stats.host ? true : false;
        const setState = this.setState.bind(this);
        sessionStorage.prevGrid = location.pathname.toLowerCase() === '/command-center' ? 'Command Center' : 'Dashboard';

        return (
            <CardContainer>
                <div 
                    className={loading ? classNames({
                        'dashboard-stats-container-line': true,
                        'active': state.active && selectedLabel === `stat-label-${slugify(title)}`,
                    }) : 'dashboard-stats-container-line skeleton'}
                    onClick={() => this.selectContainer(allData, `stat-label-${slugify(title)}`)}
                >
                    <span className={loading ? 'dashboard-stats-container-line-first' : 'dashboard-stats-container-line-first skeleton'}>{allData.length}</span>
                    <span className={loading ? 'dashboard-stats-container-line-second' : 'dashboard-stats-container-line-second skeleton'}>{title}</span>
                </div>
                {categories.map(({label, orders, color, hideWithoutSetting, description}, i) => {
                    const className = loading ? classNames({
                        'dashboard-stats-container-whiteline-second': true,
                        'critical': color === 'red',
                        'almost-critical': color === 'orange',
                    }) : 'dashboard-stats-container-whiteline-second skeleton';

                    const textClassName = classNames({
                        'text': true,
                        'active': state.active && selectedLabel === `stat-label-${slugify(title)}-${i}`,
                    });
                    
                    function onMouseOver() {
                        setTimeout(function() {
                            if (!document.querySelector(`#stat-label-${slugify(title)}-${i}:hover`)) {
                                return;
                            }
        
                            setState({[`hoveringLabel${i}`]: true});
                        }, 600);
                    }
                    
                    function onMouseLeave() {
                        setTimeout(function() {
                            if (document.querySelector(`#stat-label-${slugify(title)}-${i}:hover`)) {
                                return;
                            }
        
                            setState({[`hoveringLabel${i}`]: false});
                        }, 600);
                    }

                    return (!hideWithoutSetting || settings[hideWithoutSetting]) && (
                        <div
                            className={loading ? 'dashboard-stats-container-whiteline' : 'dashboard-stats-container-whiteline skeleton'}
                            key={i}
                            id={`stat-label-${slugify(title)}-${i}`}
                            onMouseOver={onMouseOver}
                            onMouseLeave={onMouseLeave}
                            onClick={() => {
                                this.selectContainer(orders, `stat-label-${slugify(title)}-${i}`, i)
                            }}
                        >
                            <div className={textClassName}>
                                <span className={loading ? 'dashboard-stats-container-whiteline-first' : 'dashboard-stats-container-whiteline-first skeleton'}>{label}</span>
                                <span className={className}>{orders && orders.length || 0}</span>
                            </div>
                            <div
                                className={classNames({
                                    tooltip: true,
                                    active: state[`hoveringLabel${i}`],
                                })}
                            >
                                {description}
                            </div>
                        </div>
                    );
                })}
            </CardContainer>
        );
    }
}
