import React, {useState, useEffect} from 'react';
import {Popup, TextField, Checkbox} from '@partssourceinc/react-ui-core';

const LoanerAcceptanceDialog = ({onCancel, onConfirm}) => {

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <React.Fragment>
            <Popup
                confirmText="Accept Loaner Unit"
                cancelText="Cancel"
                className="loaner-modal"
                disableConfirm={!isChecked}
                show={true}
                hideButtons={false}
                onCancel={onCancel}
                onConfirm={onConfirm}
            >
                <h1 className="title">Loaner Unit Acceptance</h1>
                <div style={{'fontWeight': '600' }}>Replacement value </div>
                <div style={{'marginBottom': '15px' }}>I acknowledge and accept that I will be charged up to the full replacement value in the case of damage or loss to the loaner unit.</div>

                <div style={{'fontWeight': '600' }}>30 Days Warranty </div>
                <div style={{'marginBottom': '15px' }}>I acknowledge and accept the 30 day warranty provided with the loaner unit.</div>

                <div style={{'fontWeight': '600' }}>Minimum Charge </div>
                <div style={{'marginBottom': '15px' }}>I acknowledge and accept that I will be charged an evaluation fee if I do not repair my unit with PartsSource.</div>

                <div style={{'fontWeight': '600' }}>Loaner Return </div>
                <div style={{'marginBottom': '15px' }}>I acknowledge and accept that I will return loaner unit within 1 business day of receipt of repaired device or repair quote refusal.</div>

                <div>
                <Checkbox label="I have read and agree to these conditions for accepting a loaner unit" checked={isChecked} 
                    onChange={() => setIsChecked(true)} />
                </div>
            </Popup>
        </React.Fragment>

    );

}

export default LoanerAcceptanceDialog;
