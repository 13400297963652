import * as React from 'react';
import _ from 'lodash';
import { ProductImage, QuantitySelector } from '@partssourceinc/react-ui-core';
import {formatMoney} from 'utility';
import 'less/orderLineHeader.less';

const OrderLineHeader = ({item, updateReturnRequest,selectedValue, showErrors}) => {
    const {detailUrl, imagePath, title, unitPrice, uomCode} = item;

    return (
        <div className="order-line-header">
            <div className="item-wrapper" >
                <div className="order-line-image" onClick={() => history.push(detailUrl)}>
                    <ProductImage width={125} url={imagePath} />
                </div>
                <div className="order-line-details">
                    <div className="title" onClick={() => history.push(detailUrl)}>{title}</div>
                </div>

            </div>
            <div className="quantity-wrapper">
                <div><strong className="price">{ formatMoney(unitPrice)}</strong>{uomCode ? `/${uomCode}` : ''}</div>
                <QuantitySelector className={`return-quantity ${showErrors && 'not-valid-qty'}`} selectedValue={selectedValue} onChange={updateReturnRequest} errorMessage="Test 242423" required={true} />

            </div>
            {showErrors && <p style={{color: 'red',textAlign: 'right'}}>Maximum items you can return is {item.quantity} </p>}

        </div>
    )
}

export default OrderLineHeader;
