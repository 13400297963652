import { Popup, RadioButton, Dropdown } from '@partssourceinc/react-ui-core/components';
import React, { useState, useEffect } from 'react';
import ConfirmationModalHeader from './InitiateReturnModalHeader';
import axios from 'axios';
import 'less/initiateReturn.less';

const InitiateReturnModal = ({ item, onCancel, showConfirmReturnModal }) => { 
    const [boxOpened, setBoxOpened] = useState();
    const [boxReturnReasons, setBoxReturnReasons] = useState([]);
    const [selectedBoxReturnReason, setSelectedBoxReturnReason] = useState('');
    const [packagingReturnReasons, setPackagingReturnReasons] = useState([]);
    const [selectedPackagingReturnReason, setSelectedPackagingReturnReason] = useState('');    
    const [isWorking, setIsWorking] = useState();
    const [isWorkingReturnReasons, setIsWorkingReturnReasons] = useState([]);
    const [workingReturnReason, setWorkingReturnReason] = useState('');
    const [isNotWorkingReturnReasons, setIsNotWorkingReturnReasons] = useState([]);
    const [notWorkingReturnReason, setNotWorkingReturnReason] = useState('');
    const [packagingOpened, setPackagingOpened] = useState();
    const [returnMethod, setReturnMethod] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [returnMethods, setReturnMethods] = useState([]);
    const [selectedQuantity, setSelectedQuantity] = useState(1);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_EXP_RETURNS_API}/api/ReturnReason?pageIndex=0&pageSize=100`).then(res => {
            const isWorkingReasons = res.data.filter(r => r.returnReasonGroups.some(t => t.name === 'Is It Working - Yes')).map(r => ({value: r.id, text: r.description}));
            const isNotWorkingReasons = res.data.filter(r => r.returnReasonGroups.some(t => t.name === 'Is It Working - No')).map(r => ({value: r.id, text: r.description}));
            const boxReasons = res.data.filter(r => r.returnReasonGroups.some(t => t.name === 'Is Box Opened')).map(r => ({value: r.id, text: r.description}));
            const packagingReasons = res.data.filter(r => r.returnReasonGroups.some(t => t.name === 'Is Package Opened')).map(r => ({value: r.id, text: r.description}));

            setIsWorkingReturnReasons(isWorkingReasons);
            setBoxReturnReasons(boxReasons);
            setPackagingReturnReasons(packagingReasons);
            setIsNotWorkingReturnReasons(isNotWorkingReasons);
        })

        axios.get(`${process.env.REACT_APP_EXP_RETURNS_API}/api/ReturnMethod?active=true&pageIndex=0&pageSize=100`).then(res => {
            setReturnMethods(res.data);
        })
    }, [])

    const handleQuantityChange = ( val ) => {
        setSelectedQuantity(val);
        setIsValid(val <= item.quantity);
    }
 
    const handleOnReturnConfirm = () => {
        const req = {
            returnMethodId: returnMethod,
            claimedReturnReasonId: parseInt(selectedBoxReturnReason || selectedPackagingReturnReason || workingReturnReason || notWorkingReturnReason),
            isBoxOpened: boxOpened,
            isPackageOpened: packagingOpened,
            isItWorking: isWorking,
            returnQuantity: selectedQuantity,
            lineItemId: item.lineItemId,
            email: null,
            notes: null,
            fsrNumber: null,
            phoneNumber: null,
            PriceSold: item.unitPrice,
        }

        axios.post(`${process.env.REACT_APP_EXP_RETURNS_API}/api/Return`, req).then((res) => {
            showConfirmReturnModal(true);
        });
        onCancel();
    }

    const radioButtonSelected = ( index, value, setValue ) => {
        if (index === 'box' && value === false) {
            setSelectedBoxReturnReason('');
            setPackagingOpened(null);
            setSelectedPackagingReturnReason('');
            setNotWorkingReturnReason('');
            setWorkingReturnReason('');
            setIsWorking(null);
        }

        if (index === 'box' && value === true)
            setSelectedBoxReturnReason('');

        if (index === 'packaging' && value === true)
            setSelectedPackagingReturnReason('');

        if (index === 'packaging' && value === false) {
            setNotWorkingReturnReason('');
            setWorkingReturnReason('');
            setIsWorking(null);
        }

        if (index === 'isworking' && value === false)
            setWorkingReturnReason('');

        if (index === 'isworking' && value === true)
            setNotWorkingReturnReason('');

        setReturnMethod('');
        setValue(value);
    }

    const renderReturnMethods = () => {
        return (
            <div className='initiate-return_section'>
                <h2 className='initiate-return_section_title'>Select a Return Method</h2>
                {returnMethods.map(r => {
                    return  <RadioButton  
                        id={r.id}
                        checked={returnMethod === r.id} 
                        value={r.id}
                        label={r.name}
                        onChange={() => setReturnMethod(r.id)} 
                        name={r.id} />
                })}
            </div>
        )
    }

    const renderBooleanQuestionSet = ( index, title, value, setValue ) => {
        return (<div className='initiate-return_section'>
            <h2 className='initiate-return_section_title'>{title}</h2>
            <RadioButton  
                id={`${index}-option-yes`}
                checked={value} 
                value={true}
                label='Yes'
                onChange={() => radioButtonSelected(index, true, setValue)} 
                name={`${index}-option-yes`} />
            <RadioButton  
                id={`${index}-option-no`}
                checked={value === false} 
                value={false}
                label='No'
                onChange={() => radioButtonSelected(index, false, setValue)} 
                name={`${index}-option-no`} />
        </div>)
    }

    const renderDropdown = ( id, options, selectedValue, setValue ) => {
        return (<div className="row initiate-return_dropdown-row">
            <div className="col-md-6 col-xs-12">
                <Dropdown id={id} 
                    options={options} 
                    selectedValue={selectedValue}
                    onChange={e => setValue(e.target.value)}
                    name={id} 
                    label='Return Reason'                      
                    required={true}
                />
            </div>
        </div>)
    }
    
    return (
        <Popup
            confirmText='INITIATE RETURN'
            cancelText='Cancel'
            show={true}
            disabled={true}
            onConfirm={() => handleOnReturnConfirm()}
            onCancel = {() => onCancel()}
            onClose = {() => onCancel()}
            disableConfirm={!returnMethod || !isValid}
            className="initiate-return">
                <div>
                    <div className="initiate-return_title">
                        <h2>Item(s) added for return</h2>
                    </div>
                    <ConfirmationModalHeader 
                        partImageitem={item.imagePath}
                        unitPrice={item.unitPrice}
                        quantity={item.quantity}
                        uom={item.uomCode ? item.uomCode.toUpperCase() : ''}
                        description={item.description}
                        partNumber={item.partNumber}                       
                        refNum={item.lineItemId}
                        detailUrl={item.detailUrl} 
                        handleQuantityChange={handleQuantityChange} />
                    <h2 className='initiate-return_sub-title'>Please provide the following</h2>
                    {renderBooleanQuestionSet('box', 'Has the box been opened?', boxOpened, (val) => setBoxOpened(val))}
                    {boxOpened === false && 
                        renderDropdown('selectedBoxReason', boxReturnReasons, selectedBoxReturnReason, (val) => setSelectedBoxReturnReason(val))}
                    {boxOpened && 
                        renderBooleanQuestionSet('packaging', 'Has the packaging been opened?', packagingOpened, (val) => setPackagingOpened(val))}
                    {boxOpened && packagingOpened === false && 
                        renderDropdown('selectedPackagingReason', packagingReturnReasons, selectedPackagingReturnReason, (val) => setSelectedPackagingReturnReason(val)) }
                    {boxOpened && packagingOpened && 
                        renderBooleanQuestionSet('isworking', 'Is it working?', isWorking, (val) => setIsWorking(val))}
                    {boxOpened && packagingOpened && isWorking && 
                        renderDropdown('workingReturnReasons', isWorkingReturnReasons, workingReturnReason, (val) => setWorkingReturnReason(val))}
                    {boxOpened && packagingOpened && isWorking === false && 
                        renderDropdown('notWorkingReturnReasons', isNotWorkingReturnReasons, notWorkingReturnReason, (val) => setNotWorkingReturnReason(val))}
                    {(selectedBoxReturnReason !== '' || selectedPackagingReturnReason !== '' || workingReturnReason !== '' || notWorkingReturnReason !== '') && 
                        renderReturnMethods()}
                </div>
        </Popup>
    )
}

export default InitiateReturnModal;