import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import {Popup, TextFieldTags, Accordion} from '@partssourceinc/react-ui-core';
import {setDashboardViewSettings} from 'stores/User';
import {loadOpsDashboardCategories, loadL1Categories} from 'stores/System';
import {logEvent} from 'utility';
import './mobile-filters.less';

const MobileFilters = () => {
    const dispatch = useDispatch();
    const autoRefresh = useSelector(state => state.dashboard.autoRefresh);

    const selectedCategories = useSelector(state => state.user.dashboardViewSettings.selectedCategories);
    const selectedModalities = useSelector(state => state.user.dashboardViewSettings.selectedModalities);
    const selectedFacilities = useSelector(state => state.user.dashboardViewSettings.selectedFacilities);
    const selectedRequesters = useSelector(state => state.user.dashboardViewSettings.selectedRequesters);

    const [tempSelectedRequesters, setTempSelectedRequesters] = useState({...selectedRequesters});
    const [tempSelectedFacilities, setTempSelectedFacilities] = useState({...selectedFacilities});
    const [tempSelectedModalities, setTempSelectedModalities] = useState({...selectedModalities});
    const [tempSelectedCategories, setTempSelectedCategories] = useState({...selectedCategories});

    const userFacilities = useSelector(state => state.user.facilities);
    const modalitiesList = useSelector(state => state.system.l1categories);
    const categoriesList = useSelector(state => state.system.opsDashboardCategories);

    const [requesters, setRequesters] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [modalities, setModalities] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showFilterDialog, setShowFilterDialog] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState(0);

    const checkFilterSelectedValues = (filter) => {
        const f = Object.keys(filter).map(key => ({id: key, name: filter[key]}));
        return f.filter(i => i.id !== '-1').length > 0;
    }

    const updateFiltersCount = () => {
        let selectedFiltersCount = 0;
        
        if (checkFilterSelectedValues(selectedModalities)) selectedFiltersCount += 1;
        if (checkFilterSelectedValues(selectedFacilities)) selectedFiltersCount += 1;
        if (checkFilterSelectedValues(selectedRequesters)) selectedFiltersCount += 1;
        if (checkFilterSelectedValues(selectedCategories)) selectedFiltersCount += 1;
        
        setSelectedFilters(selectedFiltersCount);
    }

    useEffect(() => {
        setTempSelectedCategories(selectedCategories);
        setTempSelectedFacilities(selectedFacilities);
        setTempSelectedModalities(selectedModalities);
        setTempSelectedRequesters(selectedRequesters);
        updateFiltersCount();
    }, [selectedCategories, selectedFacilities, selectedModalities, selectedRequesters]);

    useEffect(() => {
        axios
            .get('/ShoppingService/api/v1/company/contacts/')
            .then(x => setRequesters(x.data.map(r => ({id: r.contactId, name: r.fullName}))));

        setFacilities(userFacilities.map(f => ({id: f.facilityId, name: f.facilityName})));

        if (categoriesList && categoriesList.length !== 0) {
            const cats = categoriesList.map(m => ({id: m.name.toUpperCase(), name: m.name.toUpperCase()}));
            cats.push({id: -2, name: 'OTHER'})
            setCategories(cats);
        }

        if (modalitiesList && modalitiesList.length !== 0) {
            let mods = modalitiesList.map(m => ({id: m.path, name: m.name}));
            if (!modalitiesList.find(m => m.path === '-2')) mods.push({id: '-2', name: 'Uncategorized Items'});
            setModalities(mods);
        }
    }, []);

    useEffect(() => {
        if (!modalitiesList || modalitiesList.length === 0) {
            dispatch(loadL1Categories());
        } else {
            let mods = modalitiesList.map(m => ({id: m.path, name: m.name}));
            if (!modalitiesList.find(m => m.path === '-2')) mods.push({id: '-2', name: 'Uncategorized Items'});
            setModalities(mods);
        }
    }, [modalitiesList]);

    useEffect(() => {
        if (!categoriesList || categoriesList.length === 0) {
            dispatch(loadOpsDashboardCategories());
        } else {
            const cats = categoriesList.map(m => ({id: m.name.toUpperCase(), name: m.name.toUpperCase()}));
            cats.push({id: -2, name: 'OTHER'})
            setCategories(cats);
        }
    }, [categoriesList]);

    const saveFilters = (filterType) => {
        let filters = {
            selectedRequesters: Object.keys(tempSelectedRequesters).map(key => ({id: key, name: tempSelectedRequesters[key]})),
            selectedModalities: Object.keys(tempSelectedModalities).map(key => ({id: key, name: tempSelectedModalities[key]})),
            selectedFacilities: Object.keys(tempSelectedFacilities).map(key => ({id: key, name: tempSelectedFacilities[key]})),
            selectedCategories: Object.keys(tempSelectedCategories).map(key => ({id: key, name: tempSelectedCategories[key]})),
        }

        if (filterType) {
            setShowFilterDialog(null);
            logEvent('DASHBOARD', {
                'Filters': filterType,
                'Selection': JSON.stringify(filters),
            });
        }

        updateFiltersCount();
        dispatch(setDashboardViewSettings(filters));
    }

    const updateCategoriesDialog = (newValue) => {
        if (Object.keys(newValue).length === 0) {
            setTempSelectedCategories({'-1': 'All Categories'});
        } else {
            setTempSelectedCategories(_.omit(newValue, ['-1']));
        }
    }

    const updateModalitiesDialog = (newValue) => {
        console.log('newValue', newValue)
        if (Object.keys(newValue).length === 0) {
            setTempSelectedModalities({'-1': 'All Modalities'});
        } else {
            setTempSelectedModalities(_.omit(newValue, ['-1']));
        }
    }

    const updateFacilitiesDialog = (newValue) => {
        const oldFacilities = facilities;
        let facs = [...oldFacilities];

        const allFacilities = {id: -1, name: 'All Facilities'};
        if (Object.keys(newValue).length === 0) {
            setTempSelectedFacilities({'-1': 'All Facilities'});
            facs.push(allFacilities);
        } else {
            setTempSelectedFacilities(_.omit(newValue, ['-1']));
            facs = _.dropWhile(facilities, allFacilities);
        }
        setFacilities(facs);
    }

    const updateRequesterDialog = (newValue) => {
        const oldRequesters = requesters;
        let reqs = [...oldRequesters];

        const allRequesters = {id: -1, name: 'All Requesters'};
        if (Object.keys(newValue).length === 0) {
            setTempSelectedRequesters({'-1': 'All Requesters'});
            reqs.push(allRequesters);
        } else {
            setTempSelectedRequesters(_.omit(newValue, ['-1']));
            reqs = _.dropWhile(requesters, allRequesters);
        }

        setRequesters(reqs);
    }

    return (
        <>
            <div className="setting-bar">
                <span className="filter-label" onClick={() => setShowFilterDialog(true)}>
                    Filter {selectedFilters > 0 && <label>{`(${selectedFilters})`}</label>}
                </span>
            </div>

            {showFilterDialog && <Popup
                show={true}
                confirmText="Apply Filters"
                className="mobile-filter-dialog"
                onConfirm={saveFilters}
                onCancel={() => setShowFilterDialog(false)}
            >
                <h1>Filter</h1>
                <Accordion title="Category">
                    <TextFieldTags
                        listItems={categories}
                        value={tempSelectedCategories}
                        placeHolder="Select Categories"
                        onChange={updateCategoriesDialog}
                        keyField="id"
                        valueField="name"
                        useMultiSelect={true}
                    />
                </Accordion>
                <Accordion title="Modality">
                    <TextFieldTags
                        listItems={modalities}
                        value={tempSelectedModalities}
                        placeHolder="Select Modalities"
                        onChange={updateModalitiesDialog}
                        keyField="id"
                        valueField="name"
                        useMultiSelect={true}
                    />
                </Accordion>
                <Accordion title="Facility">
                    <TextFieldTags
                        listItems={facilities}
                        value={tempSelectedFacilities}
                        placeHolder="Select Facilities"
                        onChange={updateFacilitiesDialog}
                        keyField="id"
                        valueField="name"
                        useMultiSelect={true}
                    />
                </Accordion>
                <Accordion title="Requester">
                    <TextFieldTags
                        listItems={requesters}
                        value={tempSelectedRequesters}
                        placeHolder="Select Requester(s)"
                        onChange={updateRequesterDialog}
                        keyField="id"
                        valueField="name"
                        useMultiSelect={true}
                    />
                </Accordion>
            </Popup>}
        </>
    );
};

MobileFilters.propTypes = {
}

export default MobileFilters;
