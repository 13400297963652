import axios from 'axios';
import React, {useState} from 'react';
import styled from 'styled-components';
import {RadioButton, Dropdown} from '@partssourceinc/react-ui-core';
import {FormularyMetricDefinitionModal} from '../';
import {formatMoney} from 'utility';

const Container = styled.div`
    background-color: rgb(241, 241, 241);
    text-align: center;
    padding: 24px 0;
    margin-top: 48px;
    margin-bottom: 32px;
`;

const PrecisionMetricWrapper = styled.div`
    display: inline-block;
    padding: 0 52px;
    position: relative;
    vertical-align: middle;

    img {
        height: 144px;
    }

    .score-desc {
        position: absolute;
        top: 56px;
        right: 94px;
        text-align: center;
        width: 110px;

        .score {
            font-size: 40px;
            font-weight: 600;
        }

        .desc {
            font-size: 16px;
            margin-top: 14px;
            font-weight: 600;
        }
    }

    .prec-label {
        width: 192px;
        font-size: 20px;
        line-height: 24px;
        margin-top: 8px;
    }
`;

const ProformaSavingsWrapper = styled.div`
    display: inline-block;
    vertical-align: middle;

    .badge {
        display: inline-block;
        background-color: rgb(41,161,15);
        border-radius: 8px;
        padding: 10px 16px;

        .amount {
            font-weight: 300;
            font-size: 45px;
        }
        .savings-wrapper {
            height: 57px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            width: 100px;
            margin-left: 8px;

            .savings {
                position: absolute;
                left: 1px;
                top: 18px;
                font-weight: 300;
                font-size: 14px;
            }
        }

        .select-list {
            display: inline-block;
            margin-bottom: 24px;
            min-width: 50%;
        }
    }

    .no-badge {                
        background-color: rgb(241,241,241);        
        margin-bottom: -10px;
        color: black;        

        .savings-wrapper {
            .savings {
                font-weight: 400;
            }
        }
    }

    @media (max-width: 849px) {
        display: block;        
        padding-bottom: 10px;

        .badge {
            min-width: 70%;
        }
    }
`;

const RadioButtonRow = styled.div`
    display: block;
    margin-top: 22px;
    margin-bottom: -12px;

    .radiobutton{
        display: inline-block;

        label {
            min-width: initial;
        }
    }

    @media (max-width: 849px) {
        display: none;                        
    }
`;

const ValueDefinitions = styled.div`
    color: rgb(119, 119, 119);
    margin-top: 8px;
    text-align: center;

    .icon {
        border: 1px solid;
        border-radius: 50%;
        padding: 0px 5px;
        margin-right: 5px;
        font-size: 12px;
        font-weight: 600;
    }

    .definition-link {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const TimeFrameSelect = styled(Dropdown)`
    display: none;
    min-width: 50%;
    text-align: center;    
    margin-bottom: 15px;
    
    @media (max-width: 849px) {
        display: inline-block;
    }
`;

export const RadioButtonFilter = {
    Annual: 'Annual',
    Quarter: 'Quater',
    Month: 'Month',
    EndOfYear: 'Through End of Year',
};

export function FormularyEvidenceBanner({rule, daysLeftInYearCalc}) {    
    const [dateFilter, setDateFilter] = useState(RadioButtonFilter.Annual);
    const [showMetricDefinitionModal, setShowMetricDefinitionModal] = useState(false);

    const options = [
        {value: 'Annual', text: 'Annual'},
        {value: 'Quater', text: 'Quater'},
        {value: 'Month', text: 'Month'},
        {value: 'Through End of Year', text: 'Through End of Year'},
    ];
    
    let percentage = 1;

    switch (dateFilter) {
        case RadioButtonFilter.Annual:
            percentage = 1;
            break;
        case RadioButtonFilter.Quarter:
            percentage = .25;
            break;
        case RadioButtonFilter.Month:
            percentage = .0823;
            break;
        case RadioButtonFilter.EndOfYear:
            percentage = daysLeftInYearCalc;
            break;
    }

    const proFormaSavings = rule.proFormaSavings * percentage;

    const onDateFilterChange = (fltr) => {
        setDateFilter(fltr);
        let logValue = fltr === 'Through End of Year' ? 'EOY' : fltr;
        logEvent('VF Evidence Timeframe Filter', logValue);
    }

    const getPrecisionImage = (ranking) => {
        switch (ranking) {
            case 1:
                return 'score-graphic_best.png';
            case 2:
                return 'score-graphic_very-good.png';
            case 3:
                return 'score-graphic_moderate.png';
            case 4:
                return 'score-graphic_fair.png';
            case 5:
                return 'score-graphic_poor.png';
            default:
                return '';
        }
    }

    const getPrecisionDesc = (ranking) => {
        switch (ranking) {
            case 1:
                return 'BEST';
            case 2:
                return 'VERY GOOD';
            case 3:
                return 'MODERATE';
            case 4:
                return 'FAIR';
            case 5:
                return 'POOR';
            default:
                return 'N/A'
        }
    }

    const onSelectListChange = (e) => {
        setDateFilter(e.target.value);
        let logValue = e.target.value === 'Through End of Year' ? 'EOY' : e.target.value;
        logEvent('VF Evidence Timeframe Filter', logValue);
    }

    const onShowMetricDefinitionModal = () => {
        setShowMetricDefinitionModal(true);
        logEvent('VF Formulary Score Modal', 'clicked');
    }

    const logEvent = (name, value) => {
        axios.post(`/FormularyService/api/v1/log/99/Visual Formulary`, [{name: name, value: value}]);
    }

    return (
        <Container>
            <React.Fragment>
                {rule.formularyScore && <PrecisionMetricWrapper>
                    <img src={`/images/formulary/${getPrecisionImage(rule.formularyScoreRanking)}`} />
                    <div className="score-desc">
                        <div className="score">{rule.formularyScore.toFixed(1)}</div>
                        <div className="desc">{getPrecisionDesc(rule.formularyScoreRanking)}</div>
                    </div>
                    <div className="prec-label">Precision Procurement Formulary Score</div>
                    <ValueDefinitions onClick={onShowMetricDefinitionModal}>
                        <span className="icon">i</span>
                        <span className="definition-link">What does this mean?</span>
                    </ValueDefinitions>
                </PrecisionMetricWrapper>}
                <ProformaSavingsWrapper>
                    <TimeFrameSelect          
                        label="Timeframe"              
                        onChange={onSelectListChange}
                        options={options}
                        selectedValue={dateFilter}
                        valueField="value" 
                        textField="text"
                        required={true} 
                        suppressBlankOption={true}
                        className="select-list" />
                    <div className={proFormaSavings > 0 ? 'badge' : 'badge no-badge'}>
                        <span className="amount">{formatMoney(Math.round(proFormaSavings), true)}</span>
                        <span className="savings-wrapper">
                            <span className="savings">Pro forma Savings</span>
                        </span>
                    </div>
                    <RadioButtonRow>
                        <RadioButton id="annual" name="rdbtnDate" label="Annual" value={RadioButtonFilter.Annual} checked={dateFilter === RadioButtonFilter.Annual} className="radiobutton" onChange={() => onDateFilterChange(RadioButtonFilter.Annual)} />
                        <RadioButton id="quarter" name="rdbtnDate" label="Quarter" value={RadioButtonFilter.Quarter} checked={dateFilter === RadioButtonFilter.Quarter} className="radiobutton" onChange={() => onDateFilterChange(RadioButtonFilter.Quarter)} />
                        <RadioButton id="month" name="rdbtnDate" label="Month" value={RadioButtonFilter.Month} checked={dateFilter === RadioButtonFilter.Month} className="radiobutton" onChange={() => onDateFilterChange(RadioButtonFilter.Month)} />
                        <RadioButton id="endOfYear" name="rdbtnDate" label="Through End of Year" value={RadioButtonFilter.EndOfYear} checked={dateFilter === RadioButtonFilter.EndOfYear} className="radiobutton" onChange={() => onDateFilterChange(RadioButtonFilter.EndOfYear)} />
                    </RadioButtonRow>
                </ProformaSavingsWrapper>
                {showMetricDefinitionModal && <FormularyMetricDefinitionModal onClose={() => setShowMetricDefinitionModal(false)} />}
            </React.Fragment>
        </Container>
    );
}

