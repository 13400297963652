import React, {useEffect, useRef} from 'react';
import Slider from 'react-slick';
import moment from 'moment';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';

import * as DashboardStore from 'stores/Dashboard';
import CalendarCarouselCard from 'components/dashboard/CalendarCarouselCard';

import './calendar-carousel.less'

const mapStateToProps = state => ({dashboard: state.dashboard});
const mapDispatchToProps = {...DashboardStore.actionCreators};

export function CalendarCarousel(props) {
    const {dashboard: {calendarView, selectedDate}, setLineItems, setSelectedDate} = props;
    const days = Object
        .keys(calendarView)
        .map(d => moment(d))
        .filter(d => !!calendarView[d.format('YYYY-MM-DD[T00:00:00.000]')]);
    
    const sliderRef = useRef();
    useEffect(() => {
        if (selectedDate === null && days.length > 0) {
            setSelectedDate(days.findIndex(m => m.isSame(moment(), 'day')));
        }
    }, [selectedDate, days]);
    
    useEffect(() => {
        if (sliderRef.current && selectedDate) {
            sliderRef.current.slickGoTo(selectedDate);
        }
    }, [selectedDate]);

    return days.length > 0 && (
        <div className="col-12 calendar-carousel-container">
            <div className="calendar-carousel">
                <Slider
                    ref={sliderRef}
                    afterUpdate={setSelectedDate}
                    arrows={true}
                    autoplay={false}
                    dots={false}
                    initialSlide={selectedDate}
                    centerMode={true}
                    focusOnSelect={true}
                    variableWidth={true}
                    infinite={false}
                    responsive={[
                        {
                            breakpoint: 820,
                            settings: {
                                arrows: false,
                            },
                        },
                    ]}
                >
                    {days.map((day) => (
                        <CalendarCarouselCard
                            key={day.format('YYYY-MM-DD[T00:00:00.000]')}
                            date={day}
                            metrics={calendarView[day.format('YYYY-MM-DD[T00:00:00.000]')]}
                            setLineItems={setLineItems}
                        />
                    ))}
                </Slider>
            </div>
        </div>
    )
}

CalendarCarousel.propTypes = {
    dashboard: DashboardStore.StateShape,
    setLineItems: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarCarousel);
