import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled, {css} from 'styled-components';
import moment from 'moment';
import {Checkbox, SwitchCheckbox} from '@partssourceinc/react-ui-core';

import Collapsible from './../Collapsible';
import UpdatesPanelCard from './UpdatesPanelCard';
import {setUpdatesPanelFilters} from './../../store/Dashboard';
import AutoScroll from 'components/AutoScroll';
import {PhoneLandscape, Desktop} from 'responsive';

const Loop = styled(SwitchCheckbox)`
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    height: 100%;
    margin-bottom: 0px;
    position: relative;
    padding-left: 8px;

    ${props => !props.$loop && css`
        > div {
            background: #cccccc;
        }
    `}
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
    align-items: center;
`;

const Title = styled.div`
    font-weight: normal;
    font-size: 20px;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const Toolbar = styled.div`
    position: relative;
    top: -5px;
    align-self: flex-end!important;
    float: right;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;

    ::after {
        content: "\f107";
        font-family: FontAwesome;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-decoration: none;
        position: absolute;
        right: 8px;
    }

    .filters-context-menu {
        -moz-box-shadow: -1px -1px 5px 3px #c7bfc7;
        -webkit-box-shadow: -1px -1px 5px 3px #c7bfc7;
        background-color: #fff;
        box-shadow: -1px -1px 5px 3px #c7bfc7;
        display: none;
        position: absolute;
        top: 18px;
        width: 250px;
        z-index: 1;
        right: 10px;
        height: 405px;

        .menu-title {
            font-size: 16px;
            font-weight: normal
            line-height: 35px;
            margin-left: 10px;
            cursor: pointer;
            text-decoration: none;
        }

        .filters-context-item {
          line-height: 40px;
          cursor: pointer;

          span {
            margin-left: 10px;
          }
        }

        .menu-divider {
            border-top: 1px solid #f1f1f1;
            margin: 10px 0;
        }
      }

      &:hover {
        .filters-context-menu {
          display: block;
        }
      }
`;

const ZeroEvent = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
`;

const UpdatesPanel = ({onUpdatePanelItemSelected}) => {
    const dispatch = useDispatch();
    const updatesPanelFilters = useSelector(state => state.dashboard.updatesPanelFilters);
    const updatesPanel = useSelector(state => state.dashboard.updatesPanel);
    const [days, setDays] = useState([moment(), moment().subtract(1, 'days'), moment().subtract(2, 'days')]);
    const [updates, setUpdates] = useState([]);
    const [filtersSelected, setFiltersSelected] = useState(0);
    const selectedCategories = useSelector(state => state.user.dashboardViewSettings.selectedCategories);
    const selectedModalities = useSelector(state => state.user.dashboardViewSettings.selectedModalities);
    const selectedFacilities = useSelector(state => state.user.dashboardViewSettings.selectedFacilities);
    const selectedRequesters = useSelector(state => state.user.dashboardViewSettings.selectedRequesters);
    const [loop, setLoop] = useState(false);

    useEffect(() => {
        setUpdates(updatesPanel);
    }, [updatesPanel]);

    useEffect(() => {
        let ds = [];
        if (updatesPanelFilters.timeframeToday)
            ds.push(moment());
        if (updatesPanelFilters.timeframeYesterday)
            ds.push(moment().subtract(1, 'days'));
        if (updatesPanelFilters.timeframeTwoDaysAgo)
            ds.push(moment().subtract(2, 'days'));

        setDays(ds);

        let filtersApplied = Object.keys(updatesPanelFilters).reduce((acc, curr) => {
            return acc + (updatesPanelFilters[curr] ? 1 : 0);
        }, 0);

        setFiltersSelected(filtersApplied);
    }, [updatesPanelFilters]);

    const updateFilter = (key, data) => {
        let filter = {
            ...updatesPanelFilters,
        };
        filter[key] = data.checked;
        dispatch(setUpdatesPanelFilters(filter));
    }

    const onItemSelected = (lineItemId) => {
        setLoop(false);
        if (onUpdatePanelItemSelected) {
            onUpdatePanelItemSelected(lineItemId);
        }
    }

    const renderDateSection = (day) => {
        let dayEvents = updates.filter(u => moment(u.timestamp, moment.defaultFormat).isSame(day, 'day') &&
            ((updatesPanelFilters.shippingUpdates && u.event_type === 1) ||
            (updatesPanelFilters.customerActionNeeded && u.event_type === 2) ||
            (updatesPanelFilters.backorderUpdate && u.event_type === 3) || 
            (updatesPanelFilters.serviceUpdate && u.event_type === 4))
            && (selectedCategories.length === 0 || selectedCategories['-1'] || selectedCategories[u.modality_name] || (selectedCategories['-2'] && !u.modality_name))
            && (selectedRequesters.length === 0 || selectedRequesters['-1'] || selectedRequesters[u.requestor_id])
            && (selectedFacilities.length === 0 || selectedFacilities['-1'] || selectedFacilities[u.company_id])
            && (selectedModalities.length === 0 || selectedModalities['-1'] || (selectedModalities['-2'] && !u.category_path) || Object.keys(selectedModalities).some(key => u.category_path && u.category_path.includes(key)))
        );
        return (
            <Collapsible open={true} title={day.format('MM/DD/YYYY')}>
                {dayEvents.length > 0 ? 
                    dayEvents.map((ev, i) => <UpdatesPanelCard key={`${day.format('MM/DD/YYYY')}_${i}`} ev={ev} onItemSelected={(lineItemId) => onItemSelected(lineItemId)} />) :
                    <ZeroEvent>No events for this day.</ZeroEvent>}
            </Collapsible>
        );
    };

    return (
        <>
            <Header>
                <Title>
                    <div style={{position: 'relative', marginRight: '8px'}}>Updates</div>

                    <Loop
                        $loop={loop}
                        label="Feed"
                        value={loop}
                        onChange={() => setLoop(!loop)}
                        className="auto-refresh-toggle ps-hidden-sm ps-hidden-xs ps-hidden-md"
                    />
                </Title>
                <Toolbar>Filter ({filtersSelected})
                    <div className="filters-context-menu">
                        <div className="menu-title" style={{marginTop: '10px'}}>Category</div>
                        <div className="menu-divider" />
                        <div className="filters-context-item">
                            <Checkbox id="shippingUpdate" checked={updatesPanelFilters.shippingUpdates} label="Shipping Update"
                                onChange={(data) => updateFilter('shippingUpdates', data)} />
                        </div>
                        <div className="filters-context-item">
                            <Checkbox id="customerActionNeeded" checked={updatesPanelFilters.customerActionNeeded} label="Customer Action Needed"
                                onChange={(data) => updateFilter('customerActionNeeded', data)} />
                        </div>
                        <div className="filters-context-item">
                            <Checkbox id="backorderUpdate" checked={updatesPanelFilters.backorderUpdate} label="Backorder Update"
                                onChange={(data) => updateFilter('backorderUpdate', data)} />
                        </div>
                        <div className="filters-context-item">
                            <Checkbox id="serviceUpdate" checked={updatesPanelFilters.serviceUpdate} label="On-Site Service / Depot Repairs"
                                onChange={(data) => updateFilter('serviceUpdate', data)} />
                        </div>
                        <div className="menu-divider" />
                        <div className="menu-title">Timeframe</div>
                        <div className="menu-divider" />
                        <div className="filters-context-item">
                            <Checkbox id="timeframeToday" checked={updatesPanelFilters.timeframeToday} label="Today"
                                onChange={(data) => updateFilter('timeframeToday', data)} />
                        </div>
                        <div className="filters-context-item">
                            <Checkbox id="timeframeYesterday" checked={updatesPanelFilters.timeframeYesterday} label="Yesterday"
                                onChange={(data) => updateFilter('timeframeYesterday', data)} />
                        </div>
                        <div className="filters-context-item">
                            <Checkbox id="timeframeTwoDaysAgo" checked={updatesPanelFilters.timeframeTwoDaysAgo} label="Two Days Ago"
                                onChange={(data) => updateFilter('timeframeTwoDaysAgo', data)} />
                        </div>
                    </div>
                </Toolbar>
            </Header>
            
            <PhoneLandscape>{days.map((day) => renderDateSection(day))}</PhoneLandscape>
            <Desktop><AutoScroll disabled={!loop}>{days.map((day) => renderDateSection(day))}</AutoScroll></Desktop>
        </>
    );
};

UpdatesPanel.propTypes = {
}

export default UpdatesPanel;
