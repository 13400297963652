import * as React from 'react';
import {useEffect, useRef} from 'react';
import styled from 'styled-components';

const ScrollSection = styled.div`
    ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    height: 100vh;
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
`;

const AutoScroll = (props) => {
    const {children, disabled} = props;
    let elem = useRef();
    const requestRef = useRef();
    let scrollPosition = 0;
    const scrollSpeed = 1;
    const timer = useRef(null);

    const getScrollPosition = () => {
        return (elem.current.pageYOffset || elem.current.scrollTop) - (elem.current.clientTop || 0);
    }

    const autoScroll = () => {
        if (!disabled) {
            scrollPosition = getScrollPosition();

            if (elem.current.scrollTop === (elem.current.scrollHeight - elem.current.offsetHeight)) {
                $(elem.current).animate({scrollTop: 0}, 300);
            } else {
                elem.current.scrollTop = scrollPosition + scrollSpeed;
            }
            
            timer.current = setTimeout(() => {
                requestRef.current = requestAnimationFrame(autoScroll);
            }, 30);
        }
    };

    useEffect(() => {
        if (disabled) {
            clearTimeout(timer.current);
            cancelAnimationFrame(requestRef.current);
        } else {
            requestRef.current = requestAnimationFrame(autoScroll);
        }

        return () => cancelAnimationFrame(requestRef.current);
    }, [disabled]);

    const onToggleScroll = (d) => {
        if (d) {
            clearTimeout(timer.current);
            cancelAnimationFrame(requestRef.current);
        } else {
            requestRef.current = requestAnimationFrame(autoScroll);
        }
    }

    return (<ScrollSection ref={elem} onMouseEnter={() => onToggleScroll(true)} onMouseLeave={() => onToggleScroll(false)}>
        {children}
    </ScrollSection>);
}

export default AutoScroll;

